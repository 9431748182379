import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
} from 'chart.js';

import Loader from '@/components/Loader';
import { LineChartProps } from '@/components/LineChart/LineChartTypes';
import {
  StyledWrapper,
  StyledLineChart,
  StyledEmptyMessage,
  StyledScrollWrapper,
} from '@/components/LineChart/LineChartStyled';

import { chartOptions } from '@/resources/constants';

import useUserInfo from '@/hooks/useUserInfo';
import useChartJs from '@/hooks/useChartJs';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MAX_DAYS = 14;

const LineChart = ({ isLoading, dates, isSecondGraphic, parameters }: LineChartProps) => {
  const { isMaster } = useUserInfo();
  const data = useChartJs(isSecondGraphic, parameters, dates);

  if (isLoading) {
    return (
      <StyledWrapper $isFetching>
        <Loader isStatic size={32} />
      </StyledWrapper>
    );
  }

  const showScroll =
    dates && !isMaster && !isLoading && dates.length > MAX_DAYS && Boolean(parameters.length);

  return (
    <StyledScrollWrapper>
      <StyledWrapper $showScroll={showScroll}>
        {data.datasets.length ? (
          <StyledLineChart options={chartOptions} data={data} />
        ) : (
          <StyledEmptyMessage />
        )}
      </StyledWrapper>
    </StyledScrollWrapper>
  );
};

export default LineChart;
