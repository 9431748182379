import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig, TableRowConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import Select from '@/components/Select';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import PageFilters from '@/components/PageFilters';
import Button from '@/components/Button';

import IconPlus from '@/icons/IconPlus';

import { excludedLabel } from '@/resources/constantsTableSettings';

import { getUserNickname } from '@/utils/getUserNickname';
import { formatNumber } from '@/utils/formatNumber';
import Formatter from '@/utils/Formatter';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommandId from '@/hooks/useUserCommandId';
import usePagination from '@/hooks/usePagination';
import useLocalizeOfferStatus from '@/hooks/useLocalizeOfferStatus';
import useHasKeitaro from '@/hooks/useHasKeitaro';
import useColumnVisibility from '@/hooks/useColumnVisibility';
import useArchiveHelpers from '@/hooks/useArchiveHelper';

import { TableWithSettings } from '@/types/table';
import { ApiReport, ApiReportType } from '@/services/reports/reports.types';
import { ApiOffer, ApiOfferStatus } from '@/services/offers/offers.types';
import {
  useGetOffersQuery,
  useUpdateOfferStatusByIdMutation,
  useSynchronizationOfferByIdMutation,
  useChangeOfferArchiveStatusMutation,
} from '@/services/offers/offers';
import ModalSettingTableColumns from '@/modals/ModalSettingTableColumns/ModalSettingTableColumns';
import ModalOfferReportUpdateHold from '@/modals/ModalOfferReportUpdateHold/ModalOfferReportUpdateHold';
import ModalOfferReport from '@/modals/ModalOfferReport/ModalOfferReport';
import ModalOfferFromTracker from '@/modals/ModalOfferFromTracker/ModalOfferFromTracker';
import { offerGeoOptions } from '@/modals/ModalOffer/resources';
import ModalOffer from '@/modals/ModalOffer/ModalOffer';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import { getOfferStatusOptions } from '@/filters/useOfferStatusFilter';
import useOfferInformationFilter, { OfferInformation } from '@/filters/useOfferInformationFilter';
import FilterArchive from '@/filters/FilterArchive';

const OffersPage = () => {
  const { t } = useTranslation(['common', 'offer']);

  const { localizeOfferStatus } = useLocalizeOfferStatus();
  const offerStatusOptions = getOfferStatusOptions(localizeOfferStatus);

  const { isMaster, userInfo } = useUserInfo();
  const { checkColumnVisibility } = useColumnVisibility(TableWithSettings.OFFERS);

  const [loadingOffer, setLoadingOffer] = useState<Record<string, string>>({});
  const { information, OperationCategoryFilter } = useOfferInformationFilter(isMaster);

  const commandId = useUserCommandId();

  const [changeArchiveStatus] = useChangeOfferArchiveStatusMutation();
  const { isArchived, prepareArchiveColumns } = useArchiveHelpers(changeArchiveStatus, (name) =>
    t('offer:confirmArchive', { name }),
  );
  const { offers, isLoading, setPage, setLimit, getPagination } = usePagination({
    cacheKey: 'offers',
    useQuery: useGetOffersQuery,
    queryArg: { commandId, isArchived },
  });

  const [synchronizationOffer, { isLoading: isSyncLoading }] =
    useSynchronizationOfferByIdMutation();
  const [updateOfferStatus] = useUpdateOfferStatusByIdMutation();

  const openCreateOfferModal = () => {
    ModalOffer.show();
  };

  const handleEditOfferModal = (offer: ApiOffer) => () => {
    ModalOffer.show(offer);
  };

  const openAddOfferFromTrackerModal = () => {
    ModalOfferFromTracker.show();
  };

  const openCreateReportModal = (offer: ApiOffer) => () => {
    ModalOfferReport.show(offer);
  };

  const openUpdateHoldModal = (report: ApiReport) => () => {
    ModalOfferReportUpdateHold.show(report);
  };

  const openSettingTableColumnsModal = () => {
    ModalSettingTableColumns.show({
      tableName: TableWithSettings.OFFERS,
    });
  };

  const handleOfferStatusChange = useCallback(
    (id: string) => (status: string) => {
      const typedStatus = status as ApiOfferStatus;

      if (typedStatus !== ApiOfferStatus.CLOSED) {
        updateOfferStatus({ id, status: typedStatus, commandId });
        return;
      }

      ModalConfirm.show({
        title: t('common:notifications.confirmActionOnPage'),
        subTitle: t('offer:confirmCloseOffer'),
        onContinue: () => updateOfferStatus({ id, status: typedStatus, commandId }),
      });
    },
    [commandId, t, updateOfferStatus],
  );
  const hasKeitaro = useHasKeitaro();
  const generateChildrenConfig = useCallback(
    (offer: ApiOffer) => {
      const typedInformation = information as OfferInformation;

      const isMastersReportsView = typedInformation === OfferInformation.REPORTS;
      const isMastersExpensesView = typedInformation === OfferInformation.MASTERS_EXPENSES;

      if (
        !information ||
        (isMaster && isMastersExpensesView) ||
        (isMastersReportsView && !offer.reports?.length) ||
        (isMastersExpensesView && !offer.pipes?.length)
      ) {
        return undefined;
      }

      if (isMastersExpensesView) {
        return offer.pipes.map((a) => ({
          id: a.id,
          data: [
            {
              label: t('common:roles.user'),
              value: getUserNickname(a.user),
            },
            {
              label: t('common:tableLabels.costs'),
              value: formatNumber(a.costs),
            },
            {
              label: t('common:tableLabels.pipe'),
              value: a.name,
            },
          ],
        }));
      }

      if (isMastersReportsView) {
        return offer.reports.map((a) => ({
          id: a.id,
          data: [
            {
              label: t('common:tableLabels.date'),
              value: Formatter.formatDate(a.date),
            },
            {
              label: t('common:tableLabels.reportType'),
              value:
                a.type === ApiReportType.CONFIRMED ? t('common:confirmed') : t('common:expected'),
            },
            {
              label: t('common:tableLabels.user'),
              value: getUserNickname(a.user),
            },
            {
              label: t('common:tableLabels.deposits'),
              value: a.deposits,
            },
            {
              label: t('common:tableLabels.registrations'),
              value: a.registrations,
            },
            {
              label: t('common:tableLabels.installations'),
              value: a.installations,
            },
            {
              label: t('common:tableLabels.hold'),
              value: a.hold + '%',
            },
            {
              label: t('common:tableLabels.rejected'),
              value: a.rejected + '%',
            },
            {
              label: '',
              value: (
                <TableRowActions
                  buttonsConfig={[
                    {
                      textForButton: t('common:buttonActions.correction'),
                      onClick: a.hold > 0 && openUpdateHoldModal(a),
                    },
                  ]}
                />
              ),
            },
          ],
        }));
      }
    },
    [information, isMaster, t],
  );

  const handleUpdateOfferFromTracker = useCallback(
    (id: string) => () => {
      setLoadingOffer((prevState) => {
        const newState = { ...prevState };
        newState[id] = id;
        return newState;
      });

      synchronizationOffer({ id, commandId }).finally(() =>
        setLoadingOffer((prevState) => {
          const newState = { ...prevState };
          delete newState[id];
          return newState;
        }),
      );
    },
    [synchronizationOffer, commandId],
  );

  const config: TableConfig = useMemo(() => {
    const list: ApiOffer[] = offers?.list || [];

    return list.map((a) => {
      const isClosed = a.status === ApiOfferStatus.CLOSED;

      const actionProps: TableRowActionsProps = {
        isComment: true,
        isIconEdit: true,
        callout: a.comment,
        isIconLoading: isSyncLoading && a.id in loadingOffer,
      };

      if (!isMaster) {
        actionProps.onEdit = !isClosed && handleEditOfferModal(a);
        if (hasKeitaro) {
          actionProps.onRefresh = Boolean(a.trackerId) && handleUpdateOfferFromTracker(a.id);
        }
        actionProps.buttonsConfig = [
          {
            textForButton: t('common:tableLabels.report'),
            onClick: openCreateReportModal(a),
            startIcon: <IconPlus />,
          },
        ];
      }

      prepareArchiveColumns(a, actionProps);

      const result: TableRowConfig = {
        id: a.id,
        childrenConfig: generateChildrenConfig(a),
        data: [
          {
            columndId: 'name',
            label: t('common:tableLabels.name'),
            value: a.name,
          },
          {
            columndId: 'partner',
            label: t('common:tableLabels.partner'),
            value: a.partner,
          },
          {
            columndId: 'platform',
            label: t('common:tableLabels.platform'),
            value: a.platform,
          },
          {
            columndId: 'geo',
            label: t('common:tableLabels.geo'),
            value: offerGeoOptions.find(({ value }) => value === a.geo)?.label || '',
          },
          {
            columndId: 'payment',
            label: t('common:tableLabels.payment'),
            value: `${a.paymentModel.toUpperCase()}, ${formatNumber(a.paymentAmount)}`,
          },
          {
            columndId: 'status',
            label: t('common:tableLabels.status'),
            value: (
              <Select
                label={t('common:tableLabels.status')}
                size='small'
                width='168px'
                value={a.status}
                disabled={isMaster || a.isArchived}
                options={offerStatusOptions}
                onSelect={handleOfferStatusChange(a.id)}
              />
            ),
          },
          {
            columndId: 'costs',
            label: t('common:tableLabels.costs'),
            value: formatNumber(a.costs),
          },
          {
            columndId: 'confirmedIncome',
            label: t('offer:category.confirmedIncome'),
            value: formatNumber(a.confirmedIncome),
          },
          {
            columndId: 'expectedIncome',
            label: t('offer:category.expectedIncome'),
            value: formatNumber(a.expectedIncome),
          },
          {
            columndId: 'confirmedRoi',
            label: t('offer:category.confirmedRoi'),
            value: a.confirmedRoi + '%',
          },
          {
            columndId: 'confirmedRomi',
            label: t('offer:category.confirmedRomi'),
            value: a.confirmedRomi + '%',
          },
          {
            columndId: 'expectedRoi',
            label: t('offer:category.expectedRoi'),
            value: a.expectedRoi + '%',
          },
          {
            columndId: 'expectedRomi',
            label: t('offer:category.expectedRomi'),
            value: a.expectedRomi + '%',
          },
          {
            columndId: 'rejected',
            label: t('common:tableLabels.rejected'),
            value: formatNumber(a.rejected),
          },
          {
            columndId: 'hold',
            label: t('common:tableLabels.hold'),
            value: formatNumber(a.hold),
          },
          {
            columndId: 'installations',
            label: t('common:tableLabels.installations'),
            value: a.expectedInstallations,
          },
          {
            columndId: 'confirmedDeposits',
            label: t('common:tableLabels.confirmedDeposits'),
            value: a.confirmedDeposits,
          },
          {
            columndId: 'expectedDeposits',
            label: t('common:tableLabels.expectedDeposits'),
            value: a.expectedDeposits,
          },
          {
            columndId: 'registrations',
            label: t('common:tableLabels.registrations'),
            value: a.expectedRegistrations,
          },
          // {
          //   label: 'Прибыль на конец месяца',
          //   value: formatNumber(a.monthProfit,)
          // },
          {
            columndId: 'expectedProfit',
            label: t('common:tableLabels.expectedProfit'),
            value: formatNumber(a.expectedProfit),
          },
          {
            columndId: 'confirmedProfit',
            label: t('common:tableLabels.confirmedProfit'),
            value: formatNumber(a.confirmedProfit),
          },
          {
            label: 'Actions',
            isHiddenLabel: true,
            value: <TableRowActions {...actionProps} />,
            width: '100px',
          },
        ],
      };

      result.data = result.data.filter(
        (a) => checkColumnVisibility(String(a.columndId)) || a.label === excludedLabel,
      );

      return result;
    });
  }, [
    checkColumnVisibility,
    generateChildrenConfig,
    handleOfferStatusChange,
    handleUpdateOfferFromTracker,
    prepareArchiveColumns,
    isMaster,
    isSyncLoading,
    loadingOffer,
    offerStatusOptions,
    offers?.list,
    hasKeitaro,
    t,
  ]);

  return (
    <>
      <PageTitle>{t('common:sidebar.offers')}</PageTitle>

      {!isMaster && (
        <PageHeaderContent onClick={openCreateOfferModal} label={t('common:tableLabels.offer')}>
          {userInfo?.company.tracker && (
            <Button
              size='large'
              variant='outlined'
              onClick={openAddOfferFromTrackerModal}
              startIcon={<IconPlus color='#2374EE' />}
            >
              {t('offer:addFromTracker')}
            </Button>
          )}
        </PageHeaderContent>
      )}

      <PageFilters>
        <OperationCategoryFilter />
        {/* todo: refactor markup */}
        <div style={{ display: 'flex', flex: 1 }}>
          <FilterArchive />
        </div>
        <Button
          size='small'
          startIcon={<SettingsIcon />}
          style={{ maxWidth: '180px' }}
          onClick={openSettingTableColumnsModal}
        >
          {t('common:configureColumns')}
        </Button>
      </PageFilters>

      <Table
        config={config}
        isLoading={isLoading}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        pagination={getPagination(offers?.meta)}
      />
    </>
  );
};

export default OffersPage;
