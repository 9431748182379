import { ThunkAction, Action } from '@reduxjs/toolkit';

import { TableWithSettings } from '@/types/table';
import { reducer } from '@/redux';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppThunkResponseType<ReturnType = void> = AppThunk<Promise<ReturnType | undefined>>;

export interface UserState {
  commandId: string;
  isOpenSidebar: boolean;

  language: LanguagesCode;
  tableSettings: Record<TableWithSettings, string[]>;
}

export type ValueOf<T> = T[keyof T];
export type LanguagesCode = 'en' | 'ru';

export enum Languages {
  ru = 'ru',
  en = 'en',
}

export type RootState = ReturnType<typeof reducer>;

export type DateType = Date | string;
export type Optional<T> = T | undefined;
