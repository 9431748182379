import styled from '@emotion/styled';

export const StyledPageFilters = styled('div')`
  height: 84px;
  background: #fff;

  display: flex;
  align-items: center;

  padding: 12px 20px;
  margin-bottom: 24px;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};

  && > * + * {
    margin-left: 12px;
  }

  && > * {
    flex: 1;
    display: flex;
  }
`;
