import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import Select from '@/components/Select';

import useFilterProps from '@/hooks/useFilterProps';
import { SelectValue } from '@/hooks/useControlSelectValue';

import { NewType } from '@/types/filters';
import useArchivedFilter, { ArchiveEnum } from '@/filters/useArchivedFilter';

const archiveStatuses = [ArchiveEnum.UNARCHIVED, ArchiveEnum.ALL, ArchiveEnum.ARCHIVED];

const FilterArchive = <Multiple extends boolean = false>(
  props: NewType<Multiple> & { queryKey?: string; commandId?: string },
) => {
  const { t } = useTranslation();

  const filterProps = useFilterProps(props);

  const { archived, setArchived } = useArchivedFilter();

  const handleValueSelect = useCallback(
    <Multiple extends boolean>(value: SelectValue<Multiple>) => {
      setArchived(value as SelectValue<false>, 'replaceIn', true);
    },
    [setArchived],
  );

  return (
    <Select
      {...filterProps}
      isClearable={false}
      isControlFromProp
      label={t('archive.label')}
      options={archiveStatuses.map((item) => ({ value: item, label: t(`archive.${item}`) }))}
      onSelect={handleValueSelect}
      // @ts-ignore
      value={archived} // если тут любой тип кроме undefined он ругается. В этой связке есть баг типизации
    />
  );
};

export default FilterArchive;
