import { invalidatesTags } from '@/services/utils';
import {
  ApiDashboardGetQuery,
  ApiDashboardGetResponse,
} from '@/services/dashboard/dashboard.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/dashboard';
const serviceTag = servicesTags.dashboard;

const dashboardApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getDashboardStatistics: build.query<ApiDashboardGetResponse, ApiDashboardGetQuery>({
      query: (params) => ({ url: `${baseUrl}/statistics`, params: params || undefined }),
      providesTags: invalidatesTags(serviceTag),
    }),
  }),
});

export const { useGetDashboardStatisticsQuery, useLazyGetDashboardStatisticsQuery } = dashboardApi;

export default dashboardApi;
