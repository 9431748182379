import { Suspense } from 'react';

import Loader from '@/components/Loader';

import ModalUser from '@/modals/ModalUser/ModalUser';
import ModalSettingTableColumns from '@/modals/ModalSettingTableColumns/ModalSettingTableColumns';
import ModalServiceReplenishment from '@/modals/ModalServiceReplenishment/ModalServiceReplenishment';
import ModalService from '@/modals/ModalService/ModalService';
import ModalPipeConsumablesList from '@/modals/ModalPipeConsumablesList/ModalPipeConsumablesList';
import ModalPipeConsumable from '@/modals/ModalPipeConsumable/ModalPipeConsumable';
import ModalPipe from '@/modals/ModalPipe/ModalPipe';
import ModalOfferReportUpdateHold from '@/modals/ModalOfferReportUpdateHold/ModalOfferReportUpdateHold';
import ModalOfferReport from '@/modals/ModalOfferReport/ModalOfferReport';
import ModalOfferFromTracker from '@/modals/ModalOfferFromTracker/ModalOfferFromTracker';
import ModalOffer from '@/modals/ModalOffer/ModalOffer';
import ModalInviteFinancier from '@/modals/ModalInviteFinancier/ModalInviteFinancier';
import ModalInvite from '@/modals/ModalInvite/ModalInvite';
import ModalExpenseOperation from '@/modals/ModalExpenseOperation/ModalExpenseOperation';
import ModalCorrectOperation from '@/modals/ModalCorrectOperation/ModalCorrectOperation';
import ModalConsumables from '@/modals/ModalConsumables/ModalConsumables';
import ModalConsumableReturn from '@/modals/ModalConsumableReturn/ModalConsumableReturn';
import ModalConsumableDistribution from '@/modals/ModalConsumableDistribution/ModalConsumableDistribution';
import ModalConsumableCategory from '@/modals/ModalConsumableCategory/ModalConsumableCategory';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import ModalCompany from '@/modals/ModalCompany/ModalCompany';
import ModalCommand from '@/modals/ModalCommand/ModalCommand';
import ModalCardReplenishment from '@/modals/ModalCardReplenishment/ModalCardReplenishment';
import ModalCardConsumption from '@/modals/ModalCardConsumption/ModalCardConsumption';
import ModalCardClose from '@/modals/ModalCardClose/ModalCardClose';
import ModalCard from '@/modals/ModalCard/ModalCard';

const AppModalsList = () => {
  return (
    <>
      <ModalConfirm />
      <ModalSettingTableColumns />

      <ModalUser />
      <ModalInvite />
      <ModalCommand />
      <ModalCompany />
      <ModalInviteFinancier />
      <ModalConsumableCategory />

      <ModalOffer />
      <ModalOfferReport />
      <ModalOfferFromTracker />
      <ModalOfferReportUpdateHold />

      <ModalPipe />
      <ModalPipeConsumable />
      <ModalPipeConsumablesList />

      <ModalCard />
      <ModalCardClose />
      <Suspense fallback={<Loader />}>
        <ModalCardConsumption />
      </Suspense>
      <ModalCardReplenishment />

      <ModalService />
      <ModalServiceReplenishment />

      <ModalConsumables />
      <ModalConsumableReturn />
      <ModalConsumableDistribution />

      <ModalExpenseOperation />
      <ModalCorrectOperation />
    </>
  );
};

export default AppModalsList;
