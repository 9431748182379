import themeVolgaVolga from 'vvdev-theme';
import { FunctionComponent } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import typography from '@/components/MaterialThemeProvider/config/typography';
import ungroupedComponents from '@/components/MaterialThemeProvider/config/components/ungroupedComponents';
import tableComponents from '@/components/MaterialThemeProvider/config/components/tableComponents';
import dialogComponents from '@/components/MaterialThemeProvider/config/components/dialogComponents';

export let theme = createTheme(themeVolgaVolga);

theme = createTheme(theme, {
  typography,
  components: {
    ...tableComponents,
    ...dialogComponents,
    ...ungroupedComponents,
  },
});

const MaterialThemeProvider: FunctionComponent = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MaterialThemeProvider;
