import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import i18n from '@/resources/i18next';
import {
  STORAGE_OFFERS_VISIBLE_COLUMNS,
  DEFAULT_OFFERS_VISIBLE_COLUMNS,
} from '@/resources/constantsTableSettings';
import {
  STORE_SIDEBAR_STATE,
  STORE_INTERFACE_LANGUAGE,
  DEFAULT_LANGUAGE,
} from '@/resources/constants';

import LocalStorage from '@/utils/LocalStorage';
import Api from '@/utils/Api';

import { TableWithSettings } from '@/types/table';
import { LanguagesCode, UserState } from '@/types';

const initialState: UserState = {
  commandId: LocalStorage.get<string>('commandId') || '',
  isOpenSidebar: LocalStorage.get(STORE_SIDEBAR_STATE) !== 'false',
  language: LocalStorage.get(STORE_INTERFACE_LANGUAGE) || DEFAULT_LANGUAGE,
  tableSettings: {
    offers: LocalStorage.get(STORAGE_OFFERS_VISIBLE_COLUMNS) || [...DEFAULT_OFFERS_VISIBLE_COLUMNS],
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetAll: (state) => {
      state.commandId = '';
      state.isOpenSidebar = true;
      state.tableSettings = {
        offers: [...DEFAULT_OFFERS_VISIBLE_COLUMNS],
      };
    },
    setLanguage: (state, action: PayloadAction<LanguagesCode>) => {
      state.language = action.payload;

      // NOTE
      // Если вызвать до redux, то обновляется не весь интерфейс
      i18n.changeLanguage(action.payload);

      Api.setLanguage(action.payload);
      LocalStorage.set(STORE_INTERFACE_LANGUAGE, action.payload);
    },
    setCommandId: (state, action: PayloadAction<string>) => {
      state.commandId = action.payload;
      LocalStorage.set('commandId', action.payload);
    },
    setSidebarState: (state, action: PayloadAction<boolean>) => {
      state.isOpenSidebar = action.payload;
      LocalStorage.set(STORE_SIDEBAR_STATE, String(action.payload));
    },
    setTableSettings: (
      state,
      action: PayloadAction<{ table: TableWithSettings; value: string[]; storageKey: string }>,
    ) => {
      const { table, storageKey, value } = action.payload;

      state.tableSettings[table] = value;
      LocalStorage.set(storageKey, value);
    },
  },
});

export default userSlice;
