import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { DialogActions, Stack, styled } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import { FileUploadTypes } from '@/resources/constants';

import convertDistributions, { PossibleFieldValues } from '@/utils/convertDistributions';
import FormRuler from '@/utils/FormRuler';

import useUserInfo from '@/hooks/useUserInfo';
import useUserConsumableCategories from '@/hooks/useUserConsumableCategories';
import useUserCommandId from '@/hooks/useUserCommandId';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import {
  ApiConsumable,
  ApiConsumablePatchBody,
  ApiConsumablePostBody,
} from '@/services/consumables/consumables.types';
import {
  useCreateConsumableMutation,
  useUpdateConsumableMutation,
} from '@/services/consumables/consumables';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalConsumablesValues,
  FormModalConsumablesData,
} from '@/modals/ModalConsumables/ModalConsumablesTypes';

const StyledFormElement = styled(FormElement)`
  margin: 24px 0 0 0 !important;
  width: auto;
`;

const StyledFormElementTwo = styled(FormElement)`
  margin: 24px 0 0 16px !important;
  width: auto;
`;

const ModalConsumables: StaticModalWrappedComponent<ApiConsumable> = (props) => {
  const { t } = useTranslation(['common', 'consumables']);

  const commandId = useUserCommandId();
  const { categories } = useUserConsumableCategories();
  const { isMaster, isOwner, isLeader } = useUserInfo();

  const methods = useForm<FormModalConsumablesData>({
    defaultValues: { ...props.data, distributions: [] },
  });
  const dateOfOperation = methods.watch(FormModalConsumablesValues.DATE);

  const [createConsumable, { isLoading: isCreating }] = useCreateConsumableMutation();
  const [updateConsumable, { isLoading: isUpdating }] = useUpdateConsumableMutation();

  const [createConsumableMethod] = useAwaitCallback(
    async ({ distributions, ...data }: FormModalConsumablesData) => {
      const canDistribute = isOwner || isLeader;

      const newDistributions = canDistribute
        ? convertDistributions(distributions, data.amount, methods as any as PossibleFieldValues, t)
        : [];

      if (newDistributions === 'error') return;

      const body: ApiConsumablePostBody = {
        date: data[FormModalConsumablesValues.DATE],
        name: data[FormModalConsumablesValues.NAME],
        store: data[FormModalConsumablesValues.STORE],
        comment: data[FormModalConsumablesValues.COMMENT],
        categoryId: data[FormModalConsumablesValues.CATEGORY],
        fileId: data[FormModalConsumablesValues.FILE_ID],
        receiptId: data[FormModalConsumablesValues.RECEIPT_ID],

        sum: data[FormModalConsumablesValues.SUM_WITH_CURRENCY].systemSum,
        currency: data[FormModalConsumablesValues.SUM_WITH_CURRENCY].currency,
        currencySum: Number(data[FormModalConsumablesValues.SUM_WITH_CURRENCY].currencySum),
        amount: Number(data[FormModalConsumablesValues.AMOUNT]),
      };

      const response = canDistribute
        ? await createConsumable({ ...body, commandId, distributions: newDistributions })
        : await createConsumable(body);

      return response;
    },
    [commandId, createConsumable, isLeader, isOwner, methods],
  );

  const [handleSubmit] = useAwaitCallback(async (data: FormModalConsumablesData) => {
    const updateBody: Omit<ApiConsumablePatchBody, 'id'> = {
      name: data[FormModalConsumablesValues.NAME],
      comment: data[FormModalConsumablesValues.COMMENT],
    };

    const response = props.data
      ? await updateConsumable({ ...updateBody, id: props.data.id })
      : await createConsumableMethod(data);

    if (response && 'data' in response) {
      props.onClose();
    }
  }, []);

  const isEdit = Boolean(props.data);
  const title = isEdit ? t('consumables:editConsumable') : t('consumables:addConsumable');
  const buttonText = isEdit ? t('common:buttonActions.edit') : t('common:buttonActions.add');

  return (
    <Modal {...props} title={title}>
      <Form onSubmit={handleSubmit} contextMethods={methods}>
        <FormElement
          name={FormModalConsumablesValues.NAME}
          component='input'
          label={t('common:tableLabels.name')}
          rules={FormRuler.requiredInput}
        />

        {!isEdit && (
          <>
            <FormElement
              name={FormModalConsumablesValues.CATEGORY}
              component='select'
              values={categories}
              label={t('common:tableLabels.category')}
              rules={FormRuler.required}
            />
            <FormElement
              component='input'
              label={t('common:tableLabels.store')}
              rules={FormRuler.requiredInput}
              name={FormModalConsumablesValues.STORE}
            />
            <FormElement
              isNumber
              component='input'
              label={t('common:formLabels.quantity')}
              rules={FormRuler.requiredNumberInput}
              name={FormModalConsumablesValues.AMOUNT}
            />
            <FormElement
              isDecimalNumber
              label={t('common:tableLabels.sum')}
              component='sumWithCurrency'
              dateOfOperation={dateOfOperation}
              rules={FormRuler.validateSumWithCurrency}
              name={FormModalConsumablesValues.SUM_WITH_CURRENCY}
            />
            <FormElement
              component='datepicker'
              label={t('common:formLabels.dateCreate')}
              maxDate={new Date().toISOString()}
              rules={FormRuler.required}
              name={FormModalConsumablesValues.DATE}
            />
          </>
        )}

        <FormElement
          component='input'
          label={t('common:tableLabels.comment')}
          name={FormModalConsumablesValues.COMMENT}
        />

        {!isEdit && (
          <>
            {!isMaster && (
              <FormElement
                name={FormModalConsumablesValues.DISTRIBUTIONS}
                component='pickerWithSelect'
                type='consumable'
              />
            )}

            <Stack direction='row'>
              <StyledFormElement
                label={t('consumables:addReceipt')}
                component='fileUpload'
                accept={FileUploadTypes.textOrImage}
                name={FormModalConsumablesValues.RECEIPT_ID}
              />
              <StyledFormElementTwo
                label={t('consumables:addFile')}
                component='fileUpload'
                accept={FileUploadTypes.text}
                name={FormModalConsumablesValues.FILE_ID}
              />
            </Stack>
          </>
        )}

        <DialogActions>
          <Button type='submit' variant='contained' isLoading={isCreating || isUpdating}>
            {buttonText}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiConsumable>(ModalConsumables);
