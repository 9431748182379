import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import QueryString from 'query-string';
import {
  Table as LibraryTable,
  TableRow as LibraryTableRow,
  TableHead,
  TableCell,
  TableBody,
  Checkbox,
  SortDirection,
  TableSortLabel,
  TableContainer,
  Paper,
} from '@mui/material';

import TableRow from '@/components/TableRow';
import TableFooter from '@/components/TableFooter';
import { TableProps } from '@/components/Table/TableTypes';
import Loader from '@/components/Loader';
import EmptyMessage from '@/components/EmptyMessage';

import useTableSelect from '@/hooks/useTableSelect';
import useTableExpand from '@/hooks/useTableExpand';

const Table = ({
  config,
  isLoading = false,
  isAutoWidth = false,
  withSelection = false,
  isStaticLoader = false,
  isCenterLoader = false,
  isCustomChildrenConfig = false,
  onDeleteSelected,
  onCreateButtonClick,
  onExpandRow,
  ...props
}: TableProps) => {
  const history = useHistory();

  const [orderBy, setOrderBy] = useState<string>('');
  const [order, setOrder] = useState<SortDirection>(false);
  const { selectedList, handleSelectRow, handleSelectAll } = useTableSelect(config);
  const { handleExpandRow } = useTableExpand(onExpandRow);

  const handleChangeSort = useCallback(
    (property: string) => () => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  useEffect(() => {
    const newUrl: QueryString.UrlObject = {
      url: history.location.pathname,
      query: {
        ...QueryString.parse(history.location.search),
        order,
        sort: orderBy,
      },
    };

    for (const key in newUrl.query) {
      if (Object.prototype.hasOwnProperty.call(newUrl.query, key)) {
        if (!newUrl.query[key]) delete newUrl.query[key];
      }
    }

    if (newUrl.query && !newUrl.query.order) delete newUrl.query.sort;

    history.replace(QueryString.stringifyUrl(newUrl), undefined);
  }, [history, order, orderBy]);

  const bodyRows = useMemo(() => {
    if (!config.length) return [];

    const hasChildrenConfig = config.some((a) => a.childrenConfig?.length);

    const result = config.map((rowConfig) => (
      <TableRow
        onRowExpand={handleExpandRow}
        isCustomChildrenConfig={isCustomChildrenConfig}
        key={rowConfig.id}
        config={rowConfig}
        withSelection={withSelection}
        onSelect={handleSelectRow(rowConfig.id)}
        hasIconButton={hasChildrenConfig && !rowConfig.childrenConfig}
        isChecked={selectedList.indexOf(rowConfig.id) !== -1}
      />
    ));

    return result;
  }, [
    config,
    handleExpandRow,
    handleSelectRow,
    isCustomChildrenConfig,
    selectedList,
    withSelection,
  ]);

  const headCells = useMemo(() => {
    if (!config.length) return [];

    const result = config[0].data.map((a) => {
      const title = !a.isHiddenLabel && a.label;

      if (!a.sortableValue) {
        return <TableCell key={a.label}>{title}</TableCell>;
      }

      const sortOptions = {
        active: orderBy === a.sortableValue,
        onClick: handleChangeSort(a.sortableValue),
        direction: (orderBy === a.sortableValue ? order : 'asc') || 'asc',
      };

      return (
        <TableCell key={a.label} sortDirection={orderBy === a.label ? order : false}>
          <TableSortLabel {...sortOptions}>{title}</TableSortLabel>
        </TableCell>
      );
    });

    if (withSelection) {
      result.unshift(
        <TableCell key={'head-checkbox'} padding='checkbox'>
          <Checkbox
            color='primary'
            onChange={handleSelectAll}
            checked={selectedList.length === config.length}
          />
        </TableCell>,
      );
    }

    return result;
  }, [
    config,
    withSelection,
    order,
    orderBy,
    selectedList.length,
    handleSelectAll,
    handleChangeSort,
  ]);

  const handleChangePage = useCallback(
    (page: string) => {
      if (!('pagination' in props)) return;

      if (String(page) !== String(props.pagination.page)) {
        props.onChangePage(String(page));
      }
    },
    [props],
  );

  const handleChangeLimit = useCallback(
    (limit: string) => {
      if (!('pagination' in props)) return;

      if (String(limit) !== String(props.pagination.limit)) {
        props.onChangePage(String(1));
        props.onChangeLimit(String(limit));
      }
    },
    [props],
  );

  const handleDeleteSelected = useMemo(() => {
    if (!onDeleteSelected) return undefined;
    return () => onDeleteSelected(selectedList);
  }, [selectedList, onDeleteSelected]);

  if (isLoading) {
    return <Loader isStatic={isStaticLoader} isCenter={isCenterLoader} />;
  }

  return !isLoading && bodyRows.length === 0 ? (
    <EmptyMessage />
  ) : (
    <>
      <TableContainer component={Paper} style={isAutoWidth ? { width: 'fit-content' } : undefined}>
        <LibraryTable style={isAutoWidth ? { width: 'auto' } : undefined}>
          <TableHead>
            <LibraryTableRow>{headCells}</LibraryTableRow>
          </TableHead>
          <TableBody>{bodyRows}</TableBody>
        </LibraryTable>
      </TableContainer>

      {'pagination' in props && (
        <TableFooter
          page={props.pagination.page}
          limit={props.pagination.limit}
          total={props.pagination.total}
          onDelete={handleDeleteSelected}
          onChangePage={handleChangePage}
          onChangeLimit={handleChangeLimit}
          isDisabled={selectedList.length === 0}
          pages={Math.max(
            Math.ceil(props.pagination.total / props.pagination.limit),
            props.pagination.page,
          )}
        />
      )}
    </>
  );
};

export default Table;
