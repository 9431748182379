import { SelectItem } from '@/types/form';

export type CommonTableOrder = 'ascend' | 'descend' | '';

export type LibraryTableOrder = 'asc' | 'desc' | '';

export enum TableWithSettings {
  OFFERS = 'offers',
}

export interface TableFilterProps {
  value?: string;
  withAll?: boolean;
  onSelect?: (item: string) => void;

  options: SelectItem[];
}
