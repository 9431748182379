import { useMemo } from 'react';
import { Stack } from '@mui/material';

import Typography from '@/components/Typography';
import { StatisticParametersProps } from '@/components/StatisticParameters/StatisticParametersTypes';
import {
  Circle,
  StyledTypography,
} from '@/components/StatisticParameters/StatisticParametersStyled';
import Loader from '@/components/Loader';

import useGetFormattedStatisticValue from '@/hooks/useGetFormattedStatisticValue';

const StatisticParameters = ({
  isShowEmptyMessage,
  emptyMessage,
  parameters,
}: StatisticParametersProps) => {
  const { getFormattedStatisticValue } = useGetFormattedStatisticValue();

  const config = useMemo(
    () =>
      parameters.map(({ name, count, color }) => {
        const formattedCount = getFormattedStatisticValue(name, count);

        return (
          <Stack key={name} spacing={1} direction='row' alignItems='center'>
            <Circle $color={color} />
            <StyledTypography variant='body2' color='text.secondary'>
              {name}:
            </StyledTypography>
            <StyledTypography variant='body2'>{formattedCount}</StyledTypography>
          </Stack>
        );
      }),

    [getFormattedStatisticValue, parameters],
  );

  if (isShowEmptyMessage) {
    return (
      <Typography variant='body2' color='text.secondary'>
        {emptyMessage}
      </Typography>
    );
  }

  return (
    <Stack direction='row' spacing={3}>
      {!parameters.length ? <Loader isStatic size={22} /> : config}
    </Stack>
  );
};

export default StatisticParameters;
