import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';

import Typography from '@/components/Typography';
import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import Popper from '@/components/Popper/Popper';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import PageFilters from '@/components/PageFilters';
import Button from '@/components/Button';

import { getUserNickname } from '@/utils/getUserNickname';
import { formatNumber } from '@/utils/formatNumber';
import filterBoolean from '@/utils/filterBoolean';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommandId from '@/hooks/useUserCommandId';
import useQueryParam from '@/hooks/useQueryParam';
import usePagination from '@/hooks/usePagination';
import useDownloadFile from '@/hooks/useDownloadFile';
import useArchiveHelpers from '@/hooks/useArchiveHelper';

import { ApiConsumableGetQuery, ApiConsumable } from '@/services/consumables/consumables.types';
import {
  useChangeConsumableArchiveStatusMutation,
  useDeleteConsumableByIdMutation,
  useGetConsumableQuery,
} from '@/services/consumables/consumables';
import ModalConsumables from '@/modals/ModalConsumables/ModalConsumables';
import { ModalConsumableReturnProps } from '@/modals/ModalConsumableReturn/ModalConsumableReturnTypes';
import ModalReturningConsumables from '@/modals/ModalConsumableReturn/ModalConsumableReturn';
import ModalDistributionConsumables from '@/modals/ModalConsumableDistribution/ModalConsumableDistribution';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import useMasterFilter from '@/filters/useMasterFilter';
import useCategoryConsumablesFilter from '@/filters/useCategoryConsumablesFilter';
import FilterMaster from '@/filters/FilterMaster';
import FilterArchive from '@/filters/FilterArchive';

const ConsumablesPage = () => {
  const { t } = useTranslation(['common', 'consumables']);

  const { isMaster, isHighLevelCommandUser } = useUserInfo();

  const { handleDownloadFile } = useDownloadFile();

  const [changeArchiveStatus] = useChangeConsumableArchiveStatusMutation();
  const { isArchived, prepareArchiveColumns } = useArchiveHelpers(changeArchiveStatus, (name) =>
    t('consumables:confirmArchive', { name }),
  );
  const commandId = useUserCommandId();
  const { masterId } = useMasterFilter();
  const { CategoryConsumablesFilter, categoryId } = useCategoryConsumablesFilter();

  const [deleteConsumable, { isLoading: isDeleting }] = useDeleteConsumableByIdMutation();
  const [deletingConsumable, setDeletingConsumable] = useState<Record<string, string>>({});

  const [hideUsed] = useQueryParam<string>('hideUsed', '');
  const isMasterInterface = Boolean(isMaster || masterId);

  const requestParameters: ApiConsumableGetQuery = useMemo(() => {
    return {
      categoryId,
      isArchived,
      commandId: commandId,
      masterId: isMaster ? undefined : masterId,
      hideUsed: isMaster ? undefined : String(hideUsed === 'true'),
    };
  }, [categoryId, isArchived, commandId, hideUsed, isMaster, masterId]);

  const {
    consumables: consumableWarehouse,
    isLoading,
    setPage,
    setLimit,
    getPagination,
  } = usePagination({
    cacheKey: 'consumables',
    useQuery: useGetConsumableQuery,
    queryArg: requestParameters,
  });

  const openModalReturningConsumables = (data: ModalConsumableReturnProps) => () => {
    ModalReturningConsumables.show(data);
  };

  const openModalDistributionConsumables = (data: ApiConsumable) => () => {
    ModalDistributionConsumables.show(data);
  };

  const openModalConsumables = () => {
    ModalConsumables.show();
  };

  const openEditModalConsumables = (data: ApiConsumable) => () => {
    ModalConsumables.show(data);
  };

  const handleDeleteConsumable = useCallback(
    (id: string) => () => {
      // TODO: вынести логику (повторяется)
      setDeletingConsumable((prevState) => {
        const newState = { ...prevState };
        newState[id] = id;
        return newState;
      });

      deleteConsumable(id).finally(() =>
        setDeletingConsumable((prevState) => {
          const newState = { ...prevState };
          delete newState[id];
          return newState;
        }),
      );
    },
    [deleteConsumable],
  );

  const openConfirmDeleteConsumableModal = useCallback(
    ({ name, id }: ApiConsumable) =>
      () => {
        ModalConfirm.show({
          onlyContinue: true,
          continueLabel: t('buttonActions.delete'),
          title: t('notifications.confirmActionOnPage'),
          subTitle: t('consumables:confirmDeleteConsumable', { value: name }),
          onContinue: handleDeleteConsumable(id),
        });
      },
    [handleDeleteConsumable, t],
  );

  const generateChildrenConfig = useCallback(
    ({ distributions, id, category, isArchived }: ApiConsumable) => {
      if (!distributions || isMaster || !distributions.length) return undefined;

      const columns = distributions.map((a) => ({
        id: a.user.id,
        data: [
          {
            label: t('roles.user'),
            value: getUserNickname(a.user),
          },
          {
            label: t('tableLabels.total'),
            value: a.amount,
          },
          {
            label: t('tableLabels.amountOfUsed'),
            value: a.amountOfUsed,
          },
          {
            label: '',
            value: (
              <Button
                disabled={isArchived}
                size='small'
                onClick={openModalReturningConsumables({
                  category,
                  distribution: a,
                  consumableId: id,
                })}
              >
                {t('formLabels.refund')}
              </Button>
            ),
          },
        ],
      }));

      return columns;
    },
    [isMaster, t],
  );

  const config: TableConfig = useMemo(() => {
    const list: ApiConsumable[] = consumableWarehouse?.list || [];

    return list.map((item) => {
      const actionProps: TableRowActionsProps = {
        isComment: true,
        isIconEdit: true,
        callout: item.comment,
        onEdit: openEditModalConsumables(item),
      };

      if (!isMasterInterface) {
        actionProps.buttonsConfig = [
          {
            textForButton: t('buttonActions.distribute'),
            onClick:
              item.amountAvailableForDistribution > 0 && openModalDistributionConsumables(item),
          },
        ];

        actionProps.isIconLoading = isDeleting && item.id in deletingConsumable;
      }
      if (isHighLevelCommandUser) {
        actionProps.onDelete = openConfirmDeleteConsumableModal(item);
      }

      prepareArchiveColumns(item, actionProps);

      return {
        id: item.id,
        childrenConfig: generateChildrenConfig(item),
        data: [
          {
            label: t('tableLabels.name'),
            value: item.name,
          },
          {
            label: t('tableLabels.category'),
            value: item.category.name,
          },
          {
            label: t('tableLabels.store'),
            value: item.store,
          },
          {
            label: t('tableLabels.price'),
            value: formatNumber(item.price),
          },
          {
            label: t('tableLabels.cost'),
            value: formatNumber(item.currencySum, item.currency?.title),
          },
          {
            label: `${t('tableLabels.cost')} ${t('in')} $`,
            value: formatNumber(item.sum),
          },
          {
            label: t('tableLabels.total'),
            value: item.amount,
          },
          {
            label: t('tableLabels.amountOfUsed'),
            value: item.amountOfUsed,
          },
          !isMasterInterface && {
            label: t('tableLabels.amountOfDistributed'),
            value: item.amountOfDistributed,
          },
          isMasterInterface && {
            label: t('tableLabels.amountOfAvailable'),
            value: item.amountOfAvailable,
          },
          {
            label: t('tableLabels.file'),
            value: !item.file ? (
              '-'
            ) : (
              <Popper text={item.file.originalName}>
                <Typography
                  color='#2374EE'
                  onClick={() => handleDownloadFile(item.file)}
                  variant='body2'
                >
                  {t('buttonActions.download')}
                </Typography>
              </Popper>
            ),
          },
          {
            label: 'Actions',
            isHiddenLabel: true,
            value: <TableRowActions {...actionProps} />,
            width: '220px',
          },
        ].filter(filterBoolean),
      };
    });
  }, [
    consumableWarehouse?.list,
    isMasterInterface,
    isHighLevelCommandUser,
    prepareArchiveColumns,
    generateChildrenConfig,
    t,
    isDeleting,
    deletingConsumable,
    openConfirmDeleteConsumableModal,
    handleDownloadFile,
  ]);

  return (
    <>
      <PageTitle>{t('sidebar.consumables')}</PageTitle>

      <PageHeaderContent
        label={t('tableLabels.consumables')}
        haveCheckbox={!isMaster}
        onClick={openModalConsumables}
      />

      <PageFilters>
        <CategoryConsumablesFilter disableAutofill />
        {!isMaster && <FilterMaster disableAutofill />}
        <FilterArchive />
      </PageFilters>

      <Table
        config={config}
        isLoading={isLoading}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        pagination={getPagination(consumableWarehouse?.meta)}
      />
    </>
  );
};

export default ConsumablesPage;
