import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import routes from '@/resources/routes';

import filterBoolean from '@/utils/filterBoolean';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCompanyInfo from '@/hooks/useUserCompanyInfo';

import { SidebarItemProps } from '@/types/sidebar';

const useSidebarRouting = (): SidebarItemProps[] => {
  const { t } = useTranslation();

  const { selfhost } = useUserCompanyInfo();
  const { isLeader, isOwner } = useUserInfo();

  // TODO вынести отдельно
  const isClosedLeadsPage = !selfhost?.leadsUrl;

  return useMemo(() => {
    const arr = [
      {
        name: t('sidebar.dashboard'),
        path: routes.index.link,
      },
      isLeader && {
        name: t('sidebar.employees'),
        path: routes.commandById.link(),
      },
      !isClosedLeadsPage && {
        name: t('sidebar.leads'),
        path: routes.leads.link,
      },
      {
        name: t('sidebar.offers'),
        path: routes.offers.link,
      },
      {
        name: t('sidebar.operations'),
        path: routes.operations.link,
      },
      {
        name: t('sidebar.pipes'),
        path: routes.pipes.link,
      },
      (isOwner || isLeader) && {
        name: t('sidebar.consumableCategories'),
        path: routes.consumableCategories.link,
      },
      {
        name: t('sidebar.consumables'),
        path: routes.consumables.link,
      },
      {
        name: t('sidebar.services'),
        path: routes.services.link,
      },
      {
        name: t('sidebar.cards'),
        path: routes.cards.link,
      },
    ].filter(filterBoolean);

    return arr;
  }, [isClosedLeadsPage, isLeader, isOwner, t]);
};

export default useSidebarRouting;
