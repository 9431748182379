export const STORAGE_OFFERS_VISIBLE_COLUMNS = 'STORAGE_OFFERS_VISIBLE_COLUMNS';

export const excludedLabel = 'Actions';

export const DEFAULT_OFFERS_VISIBLE_COLUMNS = [
  'name',
  'partner',
  'geo',
  'payment',
  'status',
  'costs',
  'expectedIncome',
  'expectedRoi',
  'expectedRomi',
  'expectedDeposits',
] as const;

export const offersColumns = [
  ...DEFAULT_OFFERS_VISIBLE_COLUMNS,
  'platform',
  'confirmedIncome',
  'confirmedRoi',
  'confirmedRomi',
  'rejected',
  'hold',
  'confirmedProfit',
  'expectedProfit',
  'installations',
  'confirmedDeposits',
  'registrations',
] as const;
