import {
  ApiHighLevelUserParams,
  ApiPaginationParams,
  ApiResponse,
  ApiResponseWithPagination,
  Void,
} from '@/types/api';
import { ApiUser } from '@/services/users/users.types';
import { ApiReport } from '@/services/reports/reports.types';
import { ApiPipe } from '@/services/pipes/pipes.types';
import { ArchiveEnum } from '@/filters/useArchivedFilter';

export enum ApiOfferStatus {
  CREATED = 'created',
  IN_WORK = 'inWork',
  PROBLEM = 'problem',
  SUSPENDED = 'suspended',
  CLOSED = 'closed',
}

export enum ApiOfferPaymentModel {
  CPL = 'сpl',
  CPA = 'cpa',
}

export enum ApiOfferPlatform {
  FACEBOOK = 'FB',
}

export type ApiOffer = {
  id: string;
  isArchived: boolean;
  users: ApiUser[];
  pipes: ApiPipe[];
  reports: ApiReport[];

  paymentAmount: number;
  paymentModel: ApiOfferPaymentModel;

  geo: string;
  name: string;
  partner: string;
  comment: string;
  trackerId: string;

  status: ApiOfferStatus;
  platform: ApiOfferPlatform;

  hold: number;
  costs: number;
  rejected: number;
  monthProfit: number;

  confirmedRoi: number;
  confirmedRomi: number;
  confirmedProfit: number;
  confirmedIncome: number;
  confirmedDeposits: number;
  confirmedInstallations: number;
  confirmedRegistrations: number;

  expectedRoi: number;
  expectedRomi: number;
  expectedProfit: number;
  expectedIncome: number;
  expectedDeposits: number;
  expectedInstallations: number;
  expectedRegistrations: number;

  employeeIdColumn: string;
};

// === READ ↴

export type ApiOffersGetQuery = Void<
  ApiPaginationParams & { userId?: string; commandId?: string; isArchived?: ArchiveEnum }
>;
export type ApiOffersGetResponse = ApiResponseWithPagination<ApiOffer>;

// === CREATE ↴

export type ApiOfferPostBody = Pick<
  ApiOffer,
  | 'geo'
  | 'name'
  | 'partner'
  | 'comment'
  | 'platform'
  | 'paymentModel'
  | 'paymentAmount'
  | 'employeeIdColumn'
> & {
  userIds: string[];
  commandId?: string;
};
export type ApiOfferPostResponse = ApiResponse<ApiOffer>;

export type ApiOfferByTrackerIdPostBody = ApiHighLevelUserParams & {
  trackerOfferId: string;
};

// === UPDATE ↴

export type ApiOfferByIdPatchBody = ApiOfferPostBody & { id: string };
export type ApiOfferByIdPatchResponse = ApiOfferPostResponse;

// === UPDATE STATUS ↴

export type ApiOfferStatusByIdPatchBody = ApiHighLevelUserParams & {
  status: ApiOfferStatus;
  id: string;
};
export type ApiOfferStatusByIdPatchResponse = ApiOfferPostResponse;

// === SYNCHRONIZATION ↴

export type ApiOfferSynchronizationByIdPatchBody = ApiHighLevelUserParams & {
  id: string;
};
export type ApiOfferSynchronizationByIdPatchResponse = ApiOfferPostResponse;

// === ADD ALL OFFERS FROM TRACKER ↴

export type ApiAddAllOffersFromTrackerPostBody = { commandId?: string };
export type ApiAddAllOffersFromTrackerPostResponse = { message: string };
