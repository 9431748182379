import { styled } from '@mui/material/styles';

import Button from '@/components/Button';

export const ContentWrapper = styled('div')`
  padding: 16px;
  width: fit-content;
  margin-bottom: 24px;

  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 16px;
  }

  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadiusSecond}px;
  border: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};
`;

export const InfoWrapper = styled('div')`
  margin-bottom: 8px;

  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  width: 160px;
`;

export const StyledText = styled('span')`
  color: ${({ theme }) => theme.palette.common.black};
`;
