import { Theme, styled, Drawer, CSSObject, css } from '@mui/material';

import SidebarItem from '@/components/SidebarItem';
import Select from '@/components/Select';

import IconArrowLeft from '@/icons/IconArrowLeft';

import transientProps from '@/utils/transientProps';

const drawerWidth = {
  collapsed: 88,
  uncollapsed: 300,
};

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  width: drawerWidth.uncollapsed,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  width: drawerWidth.collapsed,
});

export const StyledSidebar = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open: isOpen }) => ({
  whiteSpace: 'nowrap',

  '.MuiPaper-root': {
    overflow: 'auto',
  },

  ...(isOpen && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),

  ...(!isOpen && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const StyledIconArrow = styled(IconArrowLeft, transientProps)<{ $isOpen: boolean }>`
  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      transform: scale(-1, 1);
    `}
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const StyledSidebarItem = styled(SidebarItem)`
  margin: 0;
  padding: 0 24px;

  &:hover {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export const StyledWrapper = styled('div')`
  padding: 0 24px;
  margin-bottom: 20px;
`;

export const StyledUserInfoBlock = styled('div')`
  margin-bottom: 20px;
  padding: 20px 24px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;
