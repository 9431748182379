import { useMemo } from 'react';

import { FilterProps, NewType } from '@/types/filters';

const useFilterProps = <Multiple extends boolean>(
  props: NewType<Multiple>,
): FilterProps<Multiple> => {
  return useMemo(() => {
    const commonProps: FilterProps<Multiple> = {
      size: 'small',
      maxWidth: 240,
    };

    if (!props) {
      return commonProps;
    }

    return { ...commonProps, ...props };
  }, [props]);
};

export default useFilterProps;
