import { Link } from 'react-router-dom';
import { forwardRef, useMemo } from 'react';
import MuiButton from '@mui/material/Button';

import LoadingText from '@/components/LoadingText';
import { ButtonProps } from '@/components/Button/ButtonTypes';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      href,
      label,
      disabled,
      children,
      isLoading,
      type = 'button',
      size = 'medium',
      variant = 'contained',
      ...restProps
    }: ButtonProps,
    ref,
  ) => {
    const isDisabled = disabled || isLoading;
    const linkProps = useMemo(() => (href ? { to: href, component: Link } : {}), [href]);

    return (
      <MuiButton
        {...restProps}
        {...linkProps}
        ref={ref}
        href={href}
        type={type}
        size={size}
        variant={variant}
        disabled={isDisabled}
        startIcon={isLoading ? null : restProps.startIcon}
      >
        <LoadingText isLoading={isLoading} text={label || children} />
      </MuiButton>
    );
  },
);

export default Button;
