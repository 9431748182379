import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import Typography from '@/components/Typography';
import StatisticBlockItem from '@/components/StatisticBlockItem';
import StatisticBlock from '@/components/StatisticBlock';

import { formatNumber } from '@/utils/formatNumber';

import useUserCommandId from '@/hooks/useUserCommandId';
import useStatisticCategoriesOptions from '@/hooks/useStatisticCategoriesOptions';
import useLocalizeOperationCategory from '@/hooks/useLocalizeOperationCategory';

import { ApiOperationCategory } from '@/services/operations/operations.types';
import { useGetStatisticQuery } from '@/services/operations/operations';
import useFromToFilter from '@/filters/useFromToFilter';

const OperationsInfo = () => {
  const { t } = useTranslation();

  const commandId = useUserCommandId();
  const { to, from } = useFromToFilter();

  const { statisticCategoriesOptions } = useStatisticCategoriesOptions();
  const { localizeOperationCategory } = useLocalizeOperationCategory();

  const { data, isLoading } = useGetStatisticQuery({
    to,
    from,
    commandId,
  });

  const config = useMemo(() => {
    if (!data) {
      return (
        <Typography variant='body2' color='secondary'>
          {t('noDataAvailable')}
        </Typography>
      );
    }

    const filteredCategories = statisticCategoriesOptions.filter((category) =>
      Object.values(ApiOperationCategory).includes(category.value as ApiOperationCategory),
    );

    return [
      {
        value: ApiOperationCategory.ALL,
        label: localizeOperationCategory(ApiOperationCategory.ALL),
      },
      ...filteredCategories,
    ].map(({ label, value }) => {
      const key = value === ApiOperationCategory.ALL ? 'all' : value;
      const total = data[key as ApiOperationCategory] || 0;

      return <StatisticBlockItem key={String(label)} label={label} value={formatNumber(total)} />;
    });
  }, [data, localizeOperationCategory, statisticCategoriesOptions, t]);

  return (
    <StatisticBlock isLoading={isLoading} hasData={Boolean(data && Object.keys(data).length)}>
      {config}
    </StatisticBlock>
  );
};

export default OperationsInfo;
