import { useTranslation } from 'react-i18next';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { MouseEvent, useCallback, useMemo } from 'react';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Box,
  Chip,
} from '@mui/material';

import Typography from '@/components/Typography';

import IconDeleteChip from '@/icons/IconDeleteChip';

import useFormValidation from '@/hooks/useFormValidation';
import useControlSelectValue from '@/hooks/useControlSelectValue';

import { FormElementSelectProps } from '@/types/form';

const FormElementSelect = ({
  name,
  rules,
  label,
  style,
  values,
  disabled,
  extraItems,
  disableAutofill = false,
  emptyOptionMessage,
  description: propsDescription,
  ...props
}: FormElementSelectProps) => {
  const id = `select-${name}`;

  const { t } = useTranslation();

  const { control, setValue, watch } = useFormContext();
  const { fieldValidate } = useFormValidation();

  const value: string | string[] = watch(name);

  const items: FormElementSelectProps['values'] = useMemo(() => {
    const res = [...values];
    if (extraItems) {
      for (let i = 0; i < extraItems.length; i++) {
        if (!values.find((item) => item.value === extraItems[i].value)) {
          res.unshift(extraItems[i]);
        }
      }
    }
    return res;
  }, [extraItems, values]);
  const handleChangeValue = useCallback(
    (newValue: string | string[]) => {
      setValue(name, newValue);
    },
    [name, setValue],
  );

  const handleDeleteChip = useCallback(
    (index: number) => (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();

      const newValue = [...value];
      newValue.splice(index, 1);
      handleChangeValue(newValue);
    },
    [handleChangeValue, value],
  );

  const handleMouseDownChip = useCallback((e: MouseEvent<SVGElement>) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const renderChildren = useCallback(() => {
    if (!items.length) {
      return (
        <MenuItem disabled value={''}>
          {emptyOptionMessage || t('select.empty')}
        </MenuItem>
      );
    }

    return items.map((a) => (
      <MenuItem key={a.value} value={a.value} disabled={a.disabled}>
        <Box display='flex' alignItems={'center'}>
          <Typography variant='body1'>{a.label}</Typography>

          {a.suggestion && (
            <Typography sx={{ ml: 2 }} variant='body2' color='text.secondary'>
              ({a.suggestion})
            </Typography>
          )}
        </Box>
      </MenuItem>
    ));
  }, [emptyOptionMessage, t, items]);

  const renderChips = useCallback(() => {
    if (!value) return null;
    if (typeof value === 'string') return null;
    if (props.isMultiple && props.renderSelected !== 'chips') return null;

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, mt: 5 }}>
        {value.map((a, i) => (
          <Chip
            key={a}
            onDelete={handleDeleteChip(i)}
            label={values.find((item) => item.value === a)?.label}
            deleteIcon={<IconDeleteChip onMouseDown={handleMouseDownChip} />}
          />
        ))}
      </Box>
    );
  }, [handleDeleteChip, handleMouseDownChip, props, value, values]);

  const renderValue = useCallback(
    (value: string[] | string) => {
      if (typeof value === 'string') return value || '';
      if (!value.length) return '';

      const result = value.reduce((prev, next) => {
        const result = values.find((item) => item.value === next);

        if (result?.placeholderName) {
          return prev ? prev + ', ' + result.placeholderName : result.placeholderName;
        }

        if (props.isMultiple && props.renderSelected === 'placeholder') {
          if (result?.label) {
            return prev ? prev + ', ' + result.label : result.label;
          }
        }

        return prev;
      }, '');

      return result;
    },
    [props, values],
  );

  const render: ControllerProps['render'] = useCallback(
    ({ field, fieldState }) => {
      const { isMultiple } = props;

      const selectValue = field.value || (isMultiple ? [] : '');
      const { isError, description: validateDescription } = fieldValidate(fieldState.error);

      const description = validateDescription || propsDescription;

      return (
        // TODO: Нелогичность размеров, пофиксить, добавив новый размер в TS
        <FormControl size={isMultiple ? 'small' : 'medium'} fullWidth style={style}>
          <InputLabel error={isError} id={id}>
            {label}
          </InputLabel>

          <Select
            {...field}
            labelId={id}
            label={label}
            error={isError}
            value={selectValue}
            multiple={isMultiple}
            disabled={!isError && disabled}
            renderValue={isMultiple ? renderValue : undefined}
          >
            {renderChildren()}
          </Select>

          {!!description && (
            <FormHelperText error={isError} id={id}>
              {description}
            </FormHelperText>
          )}
        </FormControl>
      );
    },

    [
      disabled,
      fieldValidate,
      id,
      label,
      propsDescription,
      renderChildren,
      renderValue,
      style,
      props,
    ],
  );

  useControlSelectValue(
    Boolean(disableAutofill),
    values,
    value,
    handleChangeValue,
    props.isMultiple,
  );

  return (
    <>
      <Controller name={name} rules={rules} control={control} render={render} />
      {renderChips()}
    </>
  );
};

export default FormElementSelect;
