import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { DialogActions, styled, Typography } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';
import Accordion from '@/components/Accordion';

import FormRuler from '@/utils/FormRuler';

import useUserCommandId from '@/hooks/useUserCommandId';
import useLocalizePipeStatus from '@/hooks/useLocalizePipeStatus';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { ApiCard, ApiCardByIdTrafficPatchBody } from '@/services/cards/cards.types';
import { useWriteTrafficCostsCardByIdMutation } from '@/services/cards/cards';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalCardConsumptionData,
  FormModalCardConsumptionValues,
} from '@/modals/ModalCardConsumption/ModalCardConsumptionTypes';

const StyledAccordion = styled(Accordion)`
  margin-top: 16px;
`;

const ModalCardConsumption: StaticModalWrappedComponent<ApiCard> = (props) => {
  const { t } = useTranslation(['common', 'cards']);

  const { localizePipeStatus } = useLocalizePipeStatus();

  const methods = useForm<FormModalCardConsumptionData>();
  const dateOfOperation = methods.watch(FormModalCardConsumptionValues.DATE);

  const commandId = useUserCommandId();
  const [writeTrafficCostsCard, { isLoading }] = useWriteTrafficCostsCardByIdMutation();

  const pipesInfo = useMemo(() => {
    return props.data?.pipes.map((a) => ({
      label: a.name,
      value: localizePipeStatus(a.status),
    }));
  }, [localizePipeStatus, props.data?.pipes]);

  const [handleSubmit] = useAwaitCallback(
    async (data: FormModalCardConsumptionData) => {
      if (!props.data) {
        return;
      }

      const body: ApiCardByIdTrafficPatchBody = {
        commandId,
        id: props.data.id,
        date: data[FormModalCardConsumptionValues.DATE],
        sum: data[FormModalCardConsumptionValues.SUM_WITH_CURRENCY].systemSum,
        currency: data[FormModalCardConsumptionValues.SUM_WITH_CURRENCY].currency,
        currencySum: Number(data[FormModalCardConsumptionValues.SUM_WITH_CURRENCY].currencySum),
      };

      const response = await writeTrafficCostsCard(body);

      if ('data' in response) {
        props.onClose();
      }
    },
    [commandId, props, writeTrafficCostsCard],
  );

  return (
    <Modal {...props} title={t('cards:addConsumption')}>
      <Form onSubmit={handleSubmit} contextMethods={methods}>
        <FormElement
          label={t('common:tableLabels.sum')}
          component='sumWithCurrency'
          rules={FormRuler.validateSumWithCurrency}
          dateOfOperation={dateOfOperation}
          name={FormModalCardConsumptionValues.SUM_WITH_CURRENCY}
        />

        <FormElement
          label={t('common:tableLabels.date')}
          component='datepicker'
          rules={FormRuler.required}
          name={FormModalCardConsumptionValues.DATE}
          maxDate={new Date().toISOString()}
        />

        {pipesInfo?.length ? (
          <StyledAccordion summary={t('cards:currentPipes')} details={pipesInfo} />
        ) : (
          <Typography sx={{ mt: 4 }} color='primary.main'>
            {t('cards:noLinkedPipes')}
          </Typography>
        )}

        <DialogActions>
          <Button isLoading={isLoading} type='submit'>
            {t('common:buttonActions.save')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiCard>(ModalCardConsumption);
