import { PossibleStatisticCategories } from '@/resources/constantsStatistic';

import { ApiFilterParams, ApiHighLevelUserParams } from '@/types/api';
import { ApiUser } from '@/services/users/users.types';
import { ApiCommand } from '@/services/commands/commands.types';

export enum ApiOfferDashboardCategories {
  PROFIT = 'profit',
  EXPECTED_ROI = 'expectedRoi',
  EXPECTED_ROMI = 'expectedRomi',
  CONFIRMED_ROI = 'confirmedRoi',
  CONFIRMED_ROMI = 'confirmedRomi',
  EXPECTED_INCOME = 'expectedIncome',
  CONFIRMED_INCOME = 'confirmedIncome',
}

export interface ApiDashboardGetQuery extends ApiHighLevelUserParams, ApiFilterParams {
  userId?: string;
}

export type PossibleStatisticCategoriesDto = Record<PossibleStatisticCategories, number>;

export interface ApiDashboardData extends ApiDashboardCommonStatistics {
  user?: ApiUser;
  command: ApiCommand;
}

export interface ApiDashboardStatisticMember extends PossibleStatisticCategoriesDto {
  user: Omit<ApiUser, 'command'>;
}

export interface ApiDashboardCommonStatistics extends PossibleStatisticCategoriesDto {
  date: string;
  isEmpty: boolean;
  members: ApiDashboardStatisticMember[];
}

export interface ApiDashboardStatistic {
  statistics: ApiDashboardCommonStatistics[];
  globalStatistics: PossibleStatisticCategoriesDto;

  user?: ApiUser;
  command: ApiCommand;
}

export type ApiDashboardGetResponse = ApiDashboardStatistic;
