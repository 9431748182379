import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import Select from '@/components/Select';

import useUserInfo from '@/hooks/useUserInfo';
import useLocalizeUserRole from '@/hooks/useLocalizeUserRole';

import { ApiUser } from '@/services/users/users.types';
import { useChangeUserRoleMutation } from '@/services/users/users';
import { ApiUserRole } from '@/services/auth/auth.types';

interface UserRoleProps {
  user: ApiUser;
}

const UserRole = ({ user }: UserRoleProps) => {
  const { t } = useTranslation(['common']);

  const { isOwner } = useUserInfo();
  const { localizeUserRole } = useLocalizeUserRole();

  const [changeUserRole] = useChangeUserRoleMutation();
  const isPossibleChangeUserRole = [
    ApiUserRole.LEAD_USER,
    ApiUserRole.COMMAND_FINANCIER,
    ApiUserRole.USER,
  ].includes(user.role);

  const allRoleOptions = useMemo(
    () => [
      {
        value: ApiUserRole.COMMAND_FINANCIER,
        label: localizeUserRole(ApiUserRole.COMMAND_FINANCIER),
      },
      { value: ApiUserRole.USER, label: localizeUserRole(ApiUserRole.USER) },
      { value: ApiUserRole.LEAD_USER, label: localizeUserRole(ApiUserRole.LEAD_USER) },
    ],
    [localizeUserRole],
  );

  const handleChangeUserRole = useCallback(
    (value: string) => {
      changeUserRole({ id: user.id, role: value as ApiUserRole });
    },
    [changeUserRole, user.id],
  );

  if (isOwner && isPossibleChangeUserRole) {
    return (
      <Select
        fullWidth
        size='small'
        value={user.role}
        options={allRoleOptions}
        label={t('common:role')}
        onSelect={handleChangeUserRole}
      />
    );
  }

  return (
    <Select
      fullWidth
      disabled
      size='small'
      value={user.role}
      options={allRoleOptions}
      label={t('common:role')}
      onSelect={handleChangeUserRole}
    />
  );
};

export default UserRole;
