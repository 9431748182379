import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Grid } from '@mui/material';

import StatisticParameters from '@/components/StatisticParameters';
import { StatisticProps } from '@/components/Statistic/StatisticTypes';
import {
  StyledStatistic,
  StyledOptionsWrapper,
  StyledWrapper,
} from '@/components/Statistic/StatisticStyled';
import LineChart from '@/components/LineChart';
import ChartParametersSelector from '@/components/ChartParametersSelector';

import { PossibleStatisticCategories } from '@/resources/constantsStatistic';

import { getParametersCount } from '@/utils/dashboard/getParametersCount';

import useUserInfo from '@/hooks/useUserInfo';
import useQueryParam from '@/hooks/useQueryParam';
import useLocalizeStatisticCategory from '@/hooks/useLocalizeStatisticCategory';
import useGetParameterColor from '@/hooks/useGetParameterColor';

import FilterUserCommand from '@/filters/FilterUserCommand';
import FilterMaster from '@/filters/FilterMaster';

const Statistic = ({
  data,
  masterId,
  commandId,
  filterQueryKey,
  isLoading = false,
  isSecondGraphic = false,
  isEqualGraphicCommands = false,
  selectedMasters = [],
  parameters = [],
}: StatisticProps) => {
  const { t } = useTranslation('dashboard');

  const { getParameterColor } = useGetParameterColor();
  const { localizeStatisticCategory } = useLocalizeStatisticCategory();

  const { isMaster, isLeader } = useUserInfo();

  const [, setQuery] = useQueryParam('parameters', '');

  const isEqualCommands = isEqualGraphicCommands && Boolean(commandId);

  const statisticState = useMemo(() => {
    if (isMaster) {
      return {
        message: t('selectParameters'),
        condition: !parameters.length,
      };
    }

    if (isLeader || isEqualCommands) {
      return {
        message: masterId ? t('selectParameters') : t('selectUser'),
        condition: !masterId || !parameters.length,
      };
    }

    return {
      message: commandId ? t('selectParameters') : t('selectCommand'),
      condition: !commandId || !parameters.length,
    };
  }, [commandId, isEqualCommands, isLeader, isMaster, masterId, parameters.length, t]);

  const formattedParameters = useMemo(() => {
    if (!data?.statistics.length) return [];

    const parametersWithCount = getParametersCount(data.statistics);

    return parameters.map((a) => {
      const category = a as PossibleStatisticCategories;

      return {
        count: parametersWithCount[category] || 0,
        name: localizeStatisticCategory(category),
        color: getParameterColor(category, isSecondGraphic),
      };
    });
  }, [data?.statistics, getParameterColor, isSecondGraphic, localizeStatisticCategory, parameters]);

  const ParametersInfo = useMemo(() => {
    return (
      <StyledOptionsWrapper $isJustifyCenter={statisticState.condition} $hasPadding>
        <StatisticParameters
          parameters={formattedParameters}
          emptyMessage={statisticState.message}
          isShowEmptyMessage={statisticState.condition}
        />
      </StyledOptionsWrapper>
    );
  }, [formattedParameters, statisticState.condition, statisticState.message]);

  const ParametersSelector = useMemo(() => {
    return (
      <ChartParametersSelector
        setQuery={setQuery}
        parameters={parameters}
        isSecondGraphic={isSecondGraphic}
      />
    );
  }, [isSecondGraphic, parameters, setQuery]);

  const configForMaster = useMemo(() => {
    return (
      <Grid container direction='row' spacing={2}>
        <Grid item xs={6}>
          <StyledWrapper>{ParametersSelector}</StyledWrapper>
        </Grid>

        <Grid item xs={6}>
          {ParametersInfo}
        </Grid>
      </Grid>
    );
  }, [ParametersSelector, ParametersInfo]);

  const configForLeader = useMemo(() => {
    return (
      <Grid container direction='row' spacing={2}>
        <Grid item xs={6}>
          <FilterMaster
            fullWidth
            isClearable
            maxWidth='auto'
            disableAutofill
            queryKey={filterQueryKey}
            filteredValues={selectedMasters}
          />
        </Grid>
        <Grid item sx={{ width: '100%' }} xs={6}>
          {ParametersSelector}
        </Grid>
      </Grid>
    );
  }, [ParametersSelector, filterQueryKey, selectedMasters]);

  const commonConfig = useMemo(() => {
    return (
      <Grid container direction='column' spacing={2}>
        <Grid item sx={{ width: '100%' }}>
          <StyledWrapper>
            {isLeader ? (
              configForLeader
            ) : (
              <Grid item container direction='column' spacing={2}>
                <Grid item container direction='row' spacing={2}>
                  <Grid item xs={6}>
                    <FilterUserCommand
                      fullWidth
                      isClearable
                      maxWidth='auto'
                      disableAutofill
                      queryKey={filterQueryKey}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FilterMaster
                      fullWidth
                      isClearable
                      maxWidth='auto'
                      disableAutofill
                      disabled={!commandId}
                      commandId={commandId}
                      filteredValues={selectedMasters}
                      queryKey={filterQueryKey}
                    />
                  </Grid>
                </Grid>
                <Grid item sx={{ width: '100%' }}>
                  {ParametersSelector}
                </Grid>
              </Grid>
            )}
          </StyledWrapper>
        </Grid>

        <Grid item sx={{ width: '100%' }}>
          {ParametersInfo}
        </Grid>
      </Grid>
    );
  }, [
    isLeader,
    configForLeader,
    filterQueryKey,
    commandId,
    selectedMasters,
    ParametersSelector,
    ParametersInfo,
  ]);

  return (
    <StyledStatistic $isFullWidth={isMaster}>
      {isMaster ? configForMaster : commonConfig}

      <LineChart
        isLoading={isLoading}
        parameters={parameters}
        dates={data?.statistics}
        isSecondGraphic={isSecondGraphic}
      />
    </StyledStatistic>
  );
};

export default Statistic;
