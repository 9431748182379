import { useTranslation } from 'react-i18next';
import { useMemo, useCallback, useState } from 'react';
import { Grid, Typography } from '@mui/material';

import Modal from '@/components/Modal';
import Loader from '@/components/Loader';
import Button from '@/components/Button';

import Alerter, { AlerterTypes } from '@/utils/Alerter/Alerter';

import useUserCommandId from '@/hooks/useUserCommandId';

import { useGetOffersFromTrackerQuery } from '@/services/tracker/tracker';
import { ApiOffer } from '@/services/offers/offers.types';
import {
  useAddAllOffersFromTrackerMutation,
  useAddOfferByTrackerIdMutation,
} from '@/services/offers/offers';
import withStaticModal, { StaticModalWrappedComponent, Entity } from '@/modals/withStaticModal';
import {
  StyledDialogActions,
  StyledStack,
  StyledWrapper,
} from '@/modals/ModalOfferFromTracker/ModalOfferFromTrackerStyled';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';

const ModalOfferFromTracker: StaticModalWrappedComponent<Entity> = (props) => {
  const { t } = useTranslation(['common', 'offer']);

  const commandId = useUserCommandId();
  const [loadingTarget, setLoadingTarget] = useState<Record<string, string>>({});

  const { data, isLoading: isGetLoading, isError } = useGetOffersFromTrackerQuery({ commandId });
  const [addOfferByTrackerId, { isLoading: isAddLoading }] = useAddOfferByTrackerIdMutation();
  const [addAllOffersFromTracker, { isLoading: isAddAllLoading }] =
    useAddAllOffersFromTrackerMutation();

  const handleAddOffer = useCallback(
    (offer: ApiOffer) => () => {
      ModalConfirm.show({
        maxWidth: 'xs',
        title: t('common:notifications.confirmActionOnPage'),
        subTitle: t('offer:confirmAddOffer', { name: offer.name }),
        onContinue: () => {
          setLoadingTarget((prevState) => {
            const newState = { ...prevState };
            newState[offer.id] = offer.id;
            return newState;
          });

          addOfferByTrackerId({ trackerOfferId: offer.id, commandId }).finally(() =>
            setLoadingTarget((prevState) => {
              const newState = { ...prevState };
              delete newState[offer.id];
              return newState;
            }),
          );
        },
      });
    },
    [addOfferByTrackerId, commandId, t],
  );

  const handleAddAllOffers = useCallback(() => {
    ModalConfirm.show({
      maxWidth: 'xs',
      title: t('common:notifications.confirmActionOnPage'),
      subTitle: t('offer:confirmAddAllOffers'),
      onContinue: async () => {
        const response = await addAllOffersFromTracker({ commandId: commandId });

        if ('data' in response) {
          Alerter.show(response.data.message, AlerterTypes.success);
          props.onClose();
        }
      },
    });
  }, [addAllOffersFromTracker, commandId, props, t]);

  const config = useMemo(
    () =>
      data?.list.map((a) => (
        <Grid container key={a.id} alignItems={'center'}>
          <Grid item xs={10}>
            <Typography variant='body1' sx={{ mr: 4 }}>
              {a.name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={a.isAlreadyAdded}
              onClick={handleAddOffer(a)}
              isLoading={isAddLoading && a.id in loadingTarget}
            >
              {a.isAlreadyAdded
                ? t('common:buttonActions.alreadyAdded')
                : t('common:buttonActions.add')}
            </Button>
          </Grid>
        </Grid>
      )),
    [data?.list, handleAddOffer, isAddLoading, loadingTarget, t],
  );

  const emptyMessage = isGetLoading ? t('offer:offersLoaded') : t('offer:failedToGetData');

  const isAllOffersAdded = data?.list.every((a) => a.isAlreadyAdded);
  const showEmptyMessage = isError || !data?.list.length || isGetLoading;
  const showOffers = !isGetLoading && config?.length;

  return (
    <Modal {...props} title={t('offer:addOfferFromTracker')} hasEmptyPadding maxHeight='460px'>
      <StyledWrapper>
        {isGetLoading && <Loader size={40} isStatic isCenter color='primary' />}

        {showOffers && (
          <StyledStack direction='column' spacing={4}>
            {config}
          </StyledStack>
        )}

        {showOffers && (
          <StyledDialogActions>
            <Button
              type='submit'
              isLoading={isAddAllLoading}
              variant='outlined'
              onClick={handleAddAllOffers}
              disabled={isAllOffersAdded}
            >
              {t('common:buttonActions.addAll')}
            </Button>
          </StyledDialogActions>
        )}

        {showEmptyMessage && (
          <div>
            <Typography textAlign='center' color='text.secondary'>
              {emptyMessage}
            </Typography>
          </div>
        )}
      </StyledWrapper>
    </Modal>
  );
};

export default withStaticModal<Entity>(ModalOfferFromTracker);
