import { useCallback } from 'react';

import { PossibleStatisticCategories } from '@/resources/constantsStatistic';
import { CHART_COLORS_LEFT, CHART_COLORS_RIGHT } from '@/resources/constants';

import useStatisticCategoriesOptions from '@/hooks/useStatisticCategoriesOptions';

const useGetParameterColor = () => {
  const { statisticCategoriesOptions } = useStatisticCategoriesOptions();

  const getParameterColor = useCallback(
    (name: PossibleStatisticCategories, isSecondGraphic: boolean) => {
      const colors = isSecondGraphic ? CHART_COLORS_RIGHT : CHART_COLORS_LEFT;
      const index = statisticCategoriesOptions.findIndex((a) => a.value === name);

      return colors[index] || 'black';
    },
    [statisticCategoriesOptions],
  );

  return { getParameterColor };
};

export default useGetParameterColor;
