import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import Select from '@/components/Select';

import useLeadStatuses from '@/hooks/useLeadStatuses';
import useFilterProps from '@/hooks/useFilterProps';
import { SelectValue } from '@/hooks/useControlSelectValue';

import { NewType } from '@/types/filters';
import useStatusFilter from '@/filters/useStatusFilter';

const FilterLeadStatus = <Multiple extends boolean = false>(props: NewType<Multiple>) => {
  const { t } = useTranslation(['common']);

  const { leadStatuses } = useLeadStatuses();

  const filterProps = useFilterProps(props);

  const { status, setStatus } = useStatusFilter();

  const handleValueSelect = useCallback(
    <Multiple extends boolean>(value: SelectValue<Multiple>) =>
      setStatus(value as SelectValue<false>, 'replaceIn', true),
    [setStatus],
  );

  return (
    <Select
      {...filterProps}
      isClearable
      isControlFromProp
      label={t('common:tableLabels.status')}
      options={leadStatuses}
      onSelect={handleValueSelect}
      value={status}
    />
  );
};

export default FilterLeadStatus;
