import { EntityId } from '@reduxjs/toolkit';

import {
  providesList,
  providesListItem,
  invalidatesTags,
  invalidatesListItem,
} from '@/services/utils';
import {
  ApiUserByIdGetQuery,
  ApiUserByIdGetResponse,
  ApiUserByIdPatchBody,
  ApiUserByIdPatchResponse,
  ApiUserPostBody,
  ApiUserPostResponse,
  ApiUsersGetQuery,
  ApiUsersGetResponse,
  ApiUserBunByIdPatchBody,
  ApiUserChangeStatusPatchBody,
  ApiSetAllowedCommandsBody,
  ApiSetAllowedCommandsResponse,
} from '@/services/users/users.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/users';
const serviceTag = servicesTags.users;

const usersApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<ApiUsersGetResponse, ApiUsersGetQuery>({
      // TODO: params || undefined
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    getUserById: build.query<ApiUserByIdGetResponse, ApiUserByIdGetQuery>({
      query: ({ id }) => `${baseUrl}/${id}`,
      providesTags: providesListItem(serviceTag),
    }),
    createUser: build.mutation<ApiUserPostResponse, ApiUserPostBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    updateUserById: build.mutation<ApiUserByIdPatchResponse, ApiUserByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.commands]),
    }),
    deleteUserById: build.mutation<void, EntityId>({
      query: (id) => ({ url: `${baseUrl}/${id}`, method: 'DELETE' }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
    banUserById: build.mutation<ApiUserPostResponse, ApiUserBunByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}/ban`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
    unBanUserById: build.mutation<ApiUserPostResponse, ApiUserBunByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}/unban`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
    changeUserRole: build.mutation<ApiUserPostResponse, ApiUserChangeStatusPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}/change_role`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag, [servicesTags.commands]),
    }),
    setAllowedCommands: build.mutation<ApiSetAllowedCommandsResponse, ApiSetAllowedCommandsBody>({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}/set_allowed_commands`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.commands]),
    }),
  }),
});

export const {
  useLazyGetUsersQuery,
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserByIdMutation,
  useDeleteUserByIdMutation,
  useBanUserByIdMutation,
  useUnBanUserByIdMutation,
  useChangeUserRoleMutation,
  useSetAllowedCommandsMutation,
} = usersApi;

export default usersApi;
