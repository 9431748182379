import { useEffect, useMemo } from 'react';

import filterBoolean from '@/utils/filterBoolean';

import useUserInfo from '@/hooks/useUserInfo';
import useSelectOptions from '@/hooks/useSelectOptions';

import { useLazyGetCommandsQuery } from '@/services/commands/commands';

// TODO: нейминг понять бы, но в голову локаничнее текущего пока не приходит
const useHighLevelUserCommands = () => {
  const { isHighLevelUser, isMultipleAccessToCommandUser, userInfo } = useUserInfo();
  const [getCommands, { data }] = useLazyGetCommandsQuery();

  useEffect(() => {
    if (!isHighLevelUser) return;
    getCommands();
  }, [getCommands, isHighLevelUser]);

  const commands = useMemo(() => {
    if (isHighLevelUser) {
      return data?.list || [];
    }

    if (isMultipleAccessToCommandUser) {
      return [...(userInfo?.allowedCommands || []), userInfo?.command].filter(filterBoolean);
    }

    return [];
  }, [
    data?.list,
    isHighLevelUser,
    isMultipleAccessToCommandUser,
    userInfo?.allowedCommands,
    userInfo?.command,
  ]);

  const commandsOptions = useSelectOptions(commands, 'name', 'id');

  return { commands: commandsOptions };
};

export default useHighLevelUserCommands;
