import { TableWithSettings } from '@/types/table';
import { AppThunk, LanguagesCode } from '@/types';
import userSlice from '@/redux/user';

const { setLanguage, setCommandId, setSidebarState, setTableSettings } = userSlice.actions;

export const setInterfaceLanguageAction =
  (iso: LanguagesCode): AppThunk =>
  (dispatch) => {
    dispatch(setLanguage(iso));
  };

export const setCommandIdAction =
  (id: string): AppThunk =>
  (dispatch) => {
    dispatch(setCommandId(id));
  };

export const setSidebarStateAction =
  (state: boolean): AppThunk =>
  (dispatch) => {
    dispatch(setSidebarState(state));
  };

export const setTableSettingsAction =
  (table: TableWithSettings, value: string[], storageKey: string): AppThunk =>
  (dispatch) => {
    dispatch(setTableSettings({ value, storageKey, table }));
  };
