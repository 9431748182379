import {
  ApiHighLevelUserParams,
  ApiPaginationParams,
  ApiResponse,
  ApiResponseWithPagination,
  Void,
} from '@/types/api';
import { ApiUser } from '@/services/users/users.types';
import { ApiOffer } from '@/services/offers/offers.types';
import { ApiConsumable } from '@/services/consumables/consumables.types';
import { ApiCard } from '@/services/cards/cards.types';
import { ArchiveEnum } from '@/filters/useArchivedFilter';

export enum ApiPipeStatus {
  CREATED = 'created',
  REVIEW = 'review',
  ACTIVE = 'active',
  OFF = 'off',
}

interface SelectConsumableDto {
  consumableId: string;
  amount: number;
}

export interface ApiPipeConsumable extends ApiConsumable {
  date: string;
  amountOfUsed: number;
  pipeConsumableId: string;
}

export interface ApiPipe {
  id: string;
  name: string;
  isArchived: boolean;

  costs: number;
  income: number;
  comment?: string;

  user: ApiUser;
  offer: ApiOffer;
  status: ApiPipeStatus;

  cards: ApiCard[];
  consumables: ApiPipeConsumable[];
}

// === UPDATE STATUS ↴

export type ApiChangePipeStatusPatchBody = ApiHighLevelUserParams & {
  id: string;
  status: ApiPipeStatus;
};
export type ApiChangePipeStatusResponse = ApiResponse<ApiPipe>;

// === READ ↴

export type ApiPipesGetQuery = Void<
  ApiPaginationParams & ApiHighLevelUserParams & { status?: string; isArchived?: ArchiveEnum }
>;
export type ApiPipesGetResponse = ApiResponseWithPagination<ApiPipe>;

// === CREATE ↴

export type ApiPipePostBody = Pick<ApiPipe, 'name' | 'comment'> &
  ApiHighLevelUserParams & {
    date: string;
    offerId: string;

    userId?: string;
    cardIds?: string[];
    consumables?: SelectConsumableDto[];
  };
export type ApiPipePostResponse = ApiResponse<ApiPipe>;

// === UPDATE ↴

export type ApiPipeByIdPatchBody = ApiPipePostBody & { id: string };
export type ApiPipeByIdPatchResponse = ApiPipePostResponse;

// === CREATE PIPE CONSUMABLE↴

export type ApiPipeConsumablePostBody = ApiHighLevelUserParams &
  SelectConsumableDto & { pipeId: string; date: string; id: string };
export type ApiPipeConsumablePostResponse = ApiResponse<ApiPipe>;

// === UPDATE PIPE CONSUMABLE ↴

export type ApiPipeConsumableByIdPatchAmountBody = Omit<ApiPipeConsumablePostBody, 'date'>;
export type ApiPipeConsumableByIdPatchAmountResponse = ApiPipeConsumablePostResponse;

// === ARCHIVE PIPE CONSUMABLE ↴

export type ApiPipeConsumableByIdPatchArchiveBody = Pick<
  ApiPipeConsumablePostBody,
  'pipeId' | 'id'
> &
  ApiHighLevelUserParams;
export type ApiPipeConsumableByIdPatchArchiveResponse = void;
