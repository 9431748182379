import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { DialogActions, IconButton, Typography, Grid } from '@mui/material';

import Modal from '@/components/Modal';
import EmptyMessage from '@/components/EmptyMessage';
import Button from '@/components/Button';

import IconEdit from '@/icons/IconEdit';
import IconDelete from '@/icons/IconDelete';

import Formatter from '@/utils/Formatter';

import useUserCommandId from '@/hooks/useUserCommandId';

import { ApiPipe, ApiPipeConsumable } from '@/services/pipes/pipes.types';
import { useDeletePipeConsumableMutation } from '@/services/pipes/pipes';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import { StyledWrapperLeft } from '@/modals/ModalPipeConsumablesList/ModalPipeConsumablesListStyled';
import ModalPipeConsumable from '@/modals/ModalPipeConsumable/ModalPipeConsumable';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';

const ModalPipeConsumablesList: StaticModalWrappedComponent<ApiPipe> = (props) => {
  const { t } = useTranslation(['common', 'pipes']);

  const pipe = props.data;
  const commandId = useUserCommandId();
  const [archivePipeConsumable] = useDeletePipeConsumableMutation();

  const openModalPipeAddConsumable = () => {
    ModalPipeConsumable.show({ pipe: props.data });
  };

  const openModalPipeEditConsumable = useCallback(
    (pipeConsumable: ApiPipeConsumable) => () => {
      ModalPipeConsumable.show({ pipe: props.data, pipeConsumable });
    },
    [props.data],
  );

  const onPipeConsumableDeleteClick = useCallback(
    (pipeConsumable: ApiPipeConsumable) => () => {
      const pipe = props.data;

      if (!pipe) return;

      const name = pipeConsumable.name;
      const date = Formatter.formatDate(pipeConsumable.date);
      const description = t('pipes:confirmDeleteConsumable', { name, date });

      ModalConfirm.show({
        maxWidth: 'xs',
        onlyContinue: true,
        withAwaiting: true,
        subTitle: String(description),
        onContinue: async () => {
          await archivePipeConsumable({
            commandId,
            pipeId: pipe.id,
            id: pipeConsumable.pipeConsumableId,
          });

          props.onClose();
        },
      });
    },
    [props, t, archivePipeConsumable, commandId],
  );

  const config = useMemo(() => {
    const list = props.data?.consumables || [];

    const result = list.map((a) => (
      <Grid key={`${a.date}-${a.id}`} container sx={{ ml: 2, mb: 4 }}>
        <Grid item xs={5}>
          <StyledWrapperLeft>
            <Typography sx={{ paddingBottom: 1 }} variant='body1'>
              {a.name}
            </Typography>
            <Typography color='text.secondary' variant='body1'>
              {a.category.name}
            </Typography>
          </StyledWrapperLeft>
        </Grid>

        <Grid item xs={5}>
          <StyledWrapperLeft>
            <Typography sx={{ paddingBottom: 1 }} variant='body1'>
              {a.amountOfUsed} {t('common:pieces')}
            </Typography>

            <Typography color='text.secondary' variant='body1'>
              {Formatter.formatDate(a.date)}
            </Typography>
          </StyledWrapperLeft>
        </Grid>

        {!pipe?.isArchived ? (
          <Grid item xs={2}>
            <div>
              <IconButton onClick={openModalPipeEditConsumable(a)}>
                <IconEdit />
              </IconButton>

              <IconButton onClick={onPipeConsumableDeleteClick(a)}>
                <IconDelete />
              </IconButton>
            </div>
          </Grid>
        ) : null}
      </Grid>
    ));

    return result;
  }, [
    pipe?.isArchived,
    onPipeConsumableDeleteClick,
    openModalPipeEditConsumable,
    props.data?.consumables,
    t,
  ]);

  return (
    <Modal {...props} title={t('pipes:pipeConsumables', { name: props.data?.name })}>
      {config}

      {!config.length && <EmptyMessage style={{ padding: '16px 0' }} />}

      <DialogActions>
        <Button
          disabled={pipe?.isArchived}
          label={t('common:buttonActions.addConsumable')}
          onClick={openModalPipeAddConsumable}
        />
      </DialogActions>
    </Modal>
  );
};

export default withStaticModal<ApiPipe>(ModalPipeConsumablesList);
