import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { DialogActions } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import FormRuler from '@/utils/FormRuler';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommandId from '@/hooks/useUserCommandId';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { SelectItem } from '@/types/form';
import {
  ApiOperation,
  ApiOperationCorrectPatchBody,
  ApiOperationCorrectType,
} from '@/services/operations/operations.types';
import { useCorrectOperationMutation } from '@/services/operations/operations';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalCorrectOperationData,
  FormModalCorrectOperationValues,
} from '@/modals/ModalCorrectOperation/ModalCorrectOperationTypes';

const ModalCorrectOperation: StaticModalWrappedComponent<ApiOperation> = (props) => {
  const { t } = useTranslation();

  const defaultValues = {
    [FormModalCorrectOperationValues.TYPE]: ApiOperationCorrectType.buy,
    [FormModalCorrectOperationValues.SUM_WITH_CURRENCY]: {
      currency: props.data?.currency?.title,
    },
  };

  const methods = useForm<FormModalCorrectOperationData>({ defaultValues });

  const commandId = useUserCommandId();
  const dateOfOperation = methods.watch(FormModalCorrectOperationValues.DATE);

  const { isMaster } = useUserInfo();
  const [correctOperation, { isLoading }] = useCorrectOperationMutation();

  const [handleSubmit] = useAwaitCallback(
    async (data: FormModalCorrectOperationData) => {
      if (!props.data) return;

      const body: ApiOperationCorrectPatchBody = {
        commandId,
        id: props.data.id,
        type: data[FormModalCorrectOperationValues.TYPE],
        date: data[FormModalCorrectOperationValues.DATE],
        reason: data[FormModalCorrectOperationValues.REASON],
        sum: data[FormModalCorrectOperationValues.SUM_WITH_CURRENCY].systemSum,
        currency: data[FormModalCorrectOperationValues.SUM_WITH_CURRENCY].currency,
        currencySum: Number(data[FormModalCorrectOperationValues.SUM_WITH_CURRENCY].currencySum),
      };

      const response = await correctOperation(body);

      if ('data' in response) {
        props.onClose();
      }
    },
    [props, commandId, correctOperation],
  );

  const radioGroupValues: SelectItem[] = useMemo(() => {
    if (isMaster) {
      return [
        {
          label: t('formLabels.buy'),
          value: ApiOperationCorrectType.buy,
        },
      ];
    }

    return [
      {
        label: t('formLabels.buy'),
        value: ApiOperationCorrectType.buy,
      },
      {
        label: t('formLabels.refund'),
        value: ApiOperationCorrectType.refund,
      },
    ];
  }, [isMaster, t]);

  return (
    <Modal {...props} title={t('buttonActions.correction')} hasEmptyPadding>
      <Form onSubmit={handleSubmit} contextMethods={methods}>
        <FormElement
          component='radioGroup'
          values={radioGroupValues}
          name={FormModalCorrectOperationValues.TYPE}
        />
        <FormElement
          isDecimalNumber
          isDisabledSelect={Boolean(
            defaultValues[FormModalCorrectOperationValues.SUM_WITH_CURRENCY].currency,
          )}
          label={t('tableLabels.sum')}
          component='sumWithCurrency'
          dateOfOperation={dateOfOperation}
          rules={FormRuler.validateSumWithCurrency}
          name={FormModalCorrectOperationValues.SUM_WITH_CURRENCY}
        />
        <FormElement
          label={t('tableLabels.date')}
          component='datepicker'
          maxDate={new Date().toISOString()}
          minDate={props.data?.date}
          name={FormModalCorrectOperationValues.DATE}
          rules={{
            ...FormRuler.required,
            ...FormRuler.validateDate(props.data?.date, false, 'day'),
          }}
        />
        <FormElement
          label={t('formLabels.reason')}
          component='input'
          rules={FormRuler.requiredInput}
          name={FormModalCorrectOperationValues.REASON}
        />

        <DialogActions>
          <Button type='submit' isLoading={isLoading} variant='contained'>
            {t('buttonActions.save')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiOperation>(ModalCorrectOperation);
