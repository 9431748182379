import { styled } from '@mui/material';

import Typography from '@/components/Typography';

import transientProps from '@/utils/transientProps';

export const Circle = styled('div', transientProps)<{ $color: string }>`
  width: 8px;
  height: 8px;

  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const StyledTypography = styled(Typography)`
  white-space: nowrap;
`;
