import { ApiArchieveParams, ApiResponse } from '@/types/api';
import { invalidatesTags, invalidatesListItem, providesList } from '@/services/utils';
import {
  ApiServicesGetResponse,
  ApiServicesGetQuery,
  ApiServicePostResponse,
  ApiServicePostBody,
  ApiServiceByIdPatchResponse,
  ApiServiceByIdPatchBody,
  ApiServiceByIdReplenishmentPatchBody,
  ApiServiceByIdReplenishmentPatchResponse,
  ApiServiceSyncByIdBody,
} from '@/services/services/services.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/services';
const serviceTag = servicesTags.services;

const servicesApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getServices: build.query<ApiServicesGetResponse, ApiServicesGetQuery>({
      // TODO: params || undefined
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),

    createService: build.mutation<ApiServicePostResponse, ApiServicePostBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    updateServiceById: build.mutation<ApiServiceByIdPatchResponse, ApiServiceByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}/`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
    syncServiceById: build.mutation<ApiResponse<null>, ApiServiceSyncByIdBody>({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}/sync-integrated`,
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.operations, servicesTags.cards]),
    }),

    replenishmentServiceById: build.mutation<
      ApiServiceByIdReplenishmentPatchResponse,
      ApiServiceByIdReplenishmentPatchBody
    >({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}/replenishment`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: invalidatesListItem(serviceTag, [servicesTags.operations]),
    }),
    changeServiceArchiveStatus: build.mutation<ApiResponse<null>, ApiArchieveParams>({
      query: ({ id, ...params }) => ({
        url: `${baseUrl}/${id}/change-archive-status`,
        method: 'PATCH',
        params,
      }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.services]),
    }),
  }),
});

export const {
  useReplenishmentServiceByIdMutation,
  useGetServicesQuery,
  useLazyGetServicesQuery,
  useCreateServiceMutation,
  useUpdateServiceByIdMutation,
  useChangeServiceArchiveStatusMutation,
  useSyncServiceByIdMutation,
} = servicesApi;

export default servicesApi;
