import { PropsWithChildren } from 'react';

import { StatisticBlockProps } from '@/components/StatisticBlock/StatisticBlockTypes';
import { StyledStatisticBlock } from '@/components/StatisticBlock/StatisticBlockStyled';
import Loader from '@/components/Loader';

const StatisticBlock = ({
  isLoading,
  hasData = false,
  children,
}: PropsWithChildren<StatisticBlockProps>) => {
  return (
    <StyledStatisticBlock
      spacing={6}
      direction='row'
      justifyContent={isLoading || !hasData ? 'center' : undefined}
    >
      {isLoading ? <Loader isStatic size={20} /> : children}
    </StyledStatisticBlock>
  );
};

export default StatisticBlock;
