import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { List, Typography } from '@mui/material';

import SidebarItem from '@/components/SidebarItem';
import {
  StyledSidebar,
  StyledIconArrow,
  StyledSelect,
  StyledWrapper,
  StyledSidebarItem,
  StyledUserInfoBlock,
} from '@/components/Sidebar/SidebarStyled';

import routes from '@/resources/routes';

import useUserInfo from '@/hooks/useUserInfo';
import useSidebarRouting from '@/hooks/useSidebarRouting';
import useLocalizeUserRole from '@/hooks/useLocalizeUserRole';
import useHighLevelUserCommands from '@/hooks/useHighLevelUserCommands';
import { SelectValue } from '@/hooks/useControlSelectValue';
import useBoolState from '@/hooks/useBoolState';

// TODO: убрать импорт package.json
import { version } from '@/resources/../../package.json';
import { selectCommandId, selectSidebarState } from '@/redux/user/userGetters';
import { setCommandIdAction, setSidebarStateAction } from '@/redux/user/userActions';

const Sidebar = () => {
  const { t } = useTranslation();

  const { localizeUserRole } = useLocalizeUserRole();

  const sidebarState = useSelector(selectSidebarState);
  const { state: isOpen, toggle: toggleOpen } = useBoolState(sidebarState);

  const dispatch = useDispatch();
  const commandId = useSelector(selectCommandId);

  const { isOwner, userInfo, isMultipleAccessToCommandUser } = useUserInfo();
  const { commands } = useHighLevelUserCommands();

  const routerConfig = useSidebarRouting();
  const ownerUIRenderCondition =
    (isMultipleAccessToCommandUser && commandId) || !isMultipleAccessToCommandUser;

  const items = useMemo(() => {
    return routerConfig.map((a) => <SidebarItem key={a.name} {...a} />);
  }, [routerConfig]);

  const handleSelectCommand = useCallback(
    (value: string | string[]) => {
      dispatch(setCommandIdAction(value as SelectValue));
    },
    [dispatch],
  );

  const handleChangeSidbarState = () => {
    dispatch(setSidebarStateAction(!isOpen));
    toggleOpen();
  };

  return (
    <StyledSidebar variant='permanent' open={isOpen}>
      <List>
        <StyledSidebarItem
          style={{ marginBottom: 20 }}
          path={undefined}
          onClick={handleChangeSidbarState}
          name={isOpen ? t('sidebar.hide') : ''}
          icon={<StyledIconArrow $isOpen={isOpen} />}
        />

        {userInfo && isOpen && (
          <StyledUserInfoBlock>
            <Typography color='text.secondary' variant='body2'>
              {/* @ts-ignore */}
              {t('sidebar.version', { version })}
            </Typography>

            <Typography color='text.secondary' variant='body2'>
              {t('role')}: {localizeUserRole(userInfo.role)}
            </Typography>

            <Typography color='text.secondary' variant='body2'>
              {t('sidebar.email')}: {userInfo.email}
            </Typography>
          </StyledUserInfoBlock>
        )}

        {isOpen && isMultipleAccessToCommandUser && (
          <>
            <StyledWrapper>
              <StyledSelect
                size='small'
                isClearable
                withCanAwait
                value={commandId}
                options={commands}
                label={t(commandId ? 'roles.team' : 'selectCommand')}
                onSelect={handleSelectCommand}
              />
            </StyledWrapper>

            {isOwner && (
              <SidebarItem
                key={t('sidebar.commands')}
                name={t('sidebar.commands')}
                path={routes.commands.link}
              />
            )}
          </>
        )}

        {ownerUIRenderCondition && isOpen && items}
      </List>
    </StyledSidebar>
  );
};

export default Sidebar;
