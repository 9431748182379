import { useDispatch } from 'react-redux';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { ChangeEvent, useRef, useState, useCallback } from 'react';
import { Chip } from '@mui/material';

import { StyledWrapper } from '@/components/FormElementFileUpload/FormElementFileUploadStyled';
import Button from '@/components/Button';

import useUserCommandId from '@/hooks/useUserCommandId';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { FormElementFileUploadProps } from '@/types/form';
import { uploadFileAction } from '@/redux/files/filesActions';
import { ApiFile } from '@/redux/files/files.types';

const FormElementFileUpload = ({ label, name, rules, accept }: FormElementFileUploadProps) => {
  const dispatch = useDispatch();
  const commandId = useUserCommandId();
  const { control, setValue, resetField } = useFormContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<ApiFile | undefined>(undefined);

  const resetInputValue = useCallback(() => {
    if (!inputRef.current) return;

    inputRef.current.type = 'text';
    inputRef.current.type = 'file';
  }, []);

  const clearState = useCallback(() => {
    setFile(undefined);

    resetField(name);
    resetInputValue();
  }, [name, resetField, resetInputValue]);

  const handleClickButton = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const [onFileChange, isLoading] = useAwaitCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;

      const file = e.target.files[0];
      const formData = new FormData();

      formData.append('file', file, window.encodeURIComponent(file.name));
      if (commandId) formData.append('commandId', commandId);

      const response = await dispatch(uploadFileAction(formData));

      if (response) {
        setValue(name, response.id);
        setFile(response);
      } else {
        clearState();
      }
    },
    [clearState, commandId, dispatch, name, setValue],
  );

  const fileName = file?.originalName;
  const isDisabled = Boolean(fileName);

  const render: ControllerProps['render'] = ({ field }) => {
    return (
      <StyledWrapper>
        <input
          {...field}
          hidden
          type='file'
          ref={inputRef}
          accept={accept}
          value={undefined}
          disabled={isDisabled}
          onChange={onFileChange}
        />

        <Button isLoading={isLoading} disabled={isDisabled} onClick={handleClickButton}>
          {label}
        </Button>

        {fileName && <Chip label={fileName} variant='outlined' onDelete={clearState} />}
      </StyledWrapper>
    );
  };

  return <Controller name={name} rules={rules} control={control} render={render} />;
};

export default FormElementFileUpload;
