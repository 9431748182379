import { useTranslation } from 'react-i18next';

import { StyledStatisticWrapper } from '@/pages/DashboardPage/DashboardPageStyled';

import Table from '@/components/Table';
import Statistic from '@/components/Statistic';
import PageTitle from '@/components/PageTitle';
import PageFilters from '@/components/PageFilters';

import useUserInfo from '@/hooks/useUserInfo';
import useStatisticData from '@/hooks/useStatisticData';
import useDashboardConfig from '@/hooks/useDashboardConfig';

import FilterFromTo from '@/filters/FilterFromTo';

const DashboardTable = ({ isLoading }: { isLoading: boolean }) => {
  const { config, toggleExpandedColumnsState } = useDashboardConfig();

  return (
    <Table
      config={config}
      isLoading={isLoading}
      isCustomChildrenConfig
      onExpandRow={toggleExpandedColumnsState}
    />
  );
};

const DashboardPage = () => {
  const { t } = useTranslation();

  const { isMaster } = useUserInfo();

  const data1 = useStatisticData();
  const data2 = useStatisticData(true);

  const isLoading = data1.isLoading || data2.isLoading;

  return (
    <>
      <PageTitle>{t('sidebar.dashboard')}</PageTitle>

      <PageFilters>
        <FilterFromTo isClearable />
      </PageFilters>

      <StyledStatisticWrapper direction='row' spacing={4}>
        <Statistic {...data1} />
        {!isMaster && <Statistic {...data2} isSecondGraphic />}
      </StyledStatisticWrapper>

      <DashboardTable isLoading={isLoading} />
    </>
  );
};

export default DashboardPage;
