import { ApiCompanyTracker } from '@/services/companies/companies.types';

export enum FormLoginValues {
  EMAIL = 'email',
  COMPANY = 'company',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'passwordConfirm',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  SERVICE = 'service',
  API_URL = 'apiUrl',
  API_KEY = 'apiKey',
}

export interface FormLoginData {
  [FormLoginValues.EMAIL]: string;
  [FormLoginValues.COMPANY]: string;
  [FormLoginValues.PASSWORD]: string;
  [FormLoginValues.FIRST_NAME]: string;
  [FormLoginValues.LAST_NAME]: string;
  [FormLoginValues.SERVICE]: ApiCompanyTracker;
  [FormLoginValues.API_URL]: string;
  [FormLoginValues.API_KEY]: string;
}

export interface FormLoginProps {
  isForgotPassword?: boolean;
  setForgotPasswordState: (value: boolean) => void;
}
