import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { DialogActions } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import FormRuler from '@/utils/FormRuler';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommandId from '@/hooks/useUserCommandId';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { SelectItem } from '@/types/form';
import { ApiReport } from '@/services/reports/reports.types';
import { useUpdateOfferReportHoldMutation } from '@/services/reports/reports';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalOfferUpdateHoldValues,
  FormModalOfferUpdateHoldData,
  ApiReportType,
} from '@/modals/ModalOfferReportUpdateHold/ModalOfferReportUpdateHoldTypes';

const defaultValues: FormModalOfferUpdateHoldData = {
  [FormModalOfferUpdateHoldValues.HOLD]: '0',
  [FormModalOfferUpdateHoldValues.DATE]: '',
  [FormModalOfferUpdateHoldValues.REASON]: '',
};

const ModalOfferReportUpdateHold: StaticModalWrappedComponent<ApiReport> = (props) => {
  const { t } = useTranslation(['common', 'offer']);

  const reasonOptions: SelectItem[] = [
    {
      label: t('offer:reasonOptions.payout'),
      value: ApiReportType.PAYOUT,
    },
    {
      label: t('offer:reasonOptions.rejected'),
      value: ApiReportType.REJECTED,
    },
  ];

  const { userInfo } = useUserInfo();
  const commandId = useUserCommandId();

  const methods = useForm<FormModalOfferUpdateHoldData>({
    defaultValues,
  });

  const reasonField = methods.watch('reason');

  const [updateOfferHold, { isLoading }] = useUpdateOfferReportHoldMutation();

  const [handleSubmit] = useAwaitCallback(async (data: FormModalOfferUpdateHoldData) => {
    if (!props.data) {
      return;
    }

    const body = {
      commandId,
      id: props.data.id,
      userId: props.data.user.id,
      companyId: userInfo?.company.id,
      [FormModalOfferUpdateHoldValues.DATE]: data.date,
      [FormModalOfferUpdateHoldValues.REASON]: data.reason,
      [FormModalOfferUpdateHoldValues.HOLD]: Number(data.hold),
    };

    const response = await updateOfferHold(body);

    if ('data' in response) {
      props.onClose();
    }
  }, []);

  return (
    <Modal {...props} title={t('offer:correctionReport')}>
      <Form contextMethods={methods} onSubmit={handleSubmit}>
        <FormElement
          label={t('common:formLabels.reason')}
          component='select'
          values={reasonOptions}
          name={FormModalOfferUpdateHoldValues.REASON}
          rules={FormRuler.required}
        />

        <FormElement
          isDecimalNumber
          component='input'
          label={t('common:formLabels.newHoldValue')}
          name={FormModalOfferUpdateHoldValues.HOLD}
          rules={{ ...FormRuler.required, ...FormRuler.checkNumber(props.data!.hold) }}
        />

        {reasonField === reasonOptions[0].value && (
          <FormElement
            label={t('common:tableLabels.date')}
            component='datepicker'
            name={FormModalOfferUpdateHoldValues.DATE}
            minDate={props.data?.date}
            rules={{
              ...FormRuler.required,
              ...FormRuler.validateDate(props.data?.date, false, 'day'),
            }}
          />
        )}

        <DialogActions>
          <Button type='submit' isLoading={isLoading}>
            {t('common:buttonActions.add')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiReport>(ModalOfferReportUpdateHold);
