import useAuthorization from '@/hooks/useAuthorization';

import { ApiUser } from '@/services/users/users.types';
import { ApiUserRole } from '@/services/auth/auth.types';

const getUserBooleanRoles = (data?: Pick<ApiUser, 'role' | 'allowedCommands'>) => {
  const { role, allowedCommands } = data || {};

  const isOwner = role === ApiUserRole.OWNER;
  const isMaster = role === ApiUserRole.USER;
  const isLeader = role === ApiUserRole.LEAD_USER;
  const isSuperAdmin = role === ApiUserRole.SUPER_ADMIN;
  const isCommandFinancier = role === ApiUserRole.COMMAND_FINANCIER;
  const isCompanyFinancier = role === ApiUserRole.COMPANY_FINANCIER;

  const isHighLevelUser = isOwner || isCompanyFinancier || isSuperAdmin;

  // TODO: не уверен в этом свойсте, нужно пересмотреть ценность. Может заменить на ~ isHighLevelLeader
  const isHighLevelCommandUser = isHighLevelUser || isCommandFinancier || isLeader;

  // Multiple access to commands
  const userAllowedCommands = allowedCommands?.length || 0;
  const isMultipleAccessToCommandUser = isHighLevelUser || userAllowedCommands > 0;

  return {
    isOwner: isOwner || isCompanyFinancier,
    isLeader: isLeader || isCommandFinancier,
    isMaster,
    isSuperAdmin,
    isHighLevelUser,
    isCommandFinancier,
    isCompanyFinancier,
    isHighLevelCommandUser,
    isMultipleAccessToCommandUser,
  };
};

export const useTargetInfo = (target?: ApiUser) => {
  const { isCommandFinancier, isCompanyFinancier } = getUserBooleanRoles(target);

  return {
    isTargetCommandFinancier: isCommandFinancier,
    isTargetCompanyFinancier: isCompanyFinancier,
  };
};

const useUserInfo = () => {
  const { userInfo } = useAuthorization();

  return {
    userInfo,
    isCompanyWithTracker: Boolean(userInfo?.company.tracker),
    isCompanyWithSelfhostApi: Boolean(userInfo?.company.selfhost),

    ...getUserBooleanRoles(userInfo),
  };
};

export default useUserInfo;
