import { matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo, memo, Suspense } from 'react';

import PaymentErrorPage from '@/pages/PaymentErrorPage';
import OwnerContentPage from '@/pages/OwnerContentPage';
import LoginPage from '@/pages/LoginPage';
import ErrorPage from '@/pages/ErrorPage';

import Sidebar from '@/components/Sidebar';
import { StyledRouterContent, StyledRouterContentWrapper } from '@/components/Router/RouterStyled';
import Loader from '@/components/Loader';
import Header from '@/components/Header';

import routes from '@/resources/routes';
import { ROUTER_CONTENT_ID } from '@/resources/constants';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCompanyInfo from '@/hooks/useUserCompanyInfo';
import useAuthorization from '@/hooks/useAuthorization';

import { useGetMeQuery } from '@/services/auth/auth';
import { selectCommandId } from '@/redux/user/userGetters';

const FilledRouter = memo(() => {
  const location = useLocation();

  const { userInfo, isOwner } = useUserInfo();
  const commandId = useSelector(selectCommandId);
  const { selfhost } = useUserCompanyInfo();

  const isFoundRoute = useMemo(() => {
    const keys = Object.keys(routes);

    for (let i = 0; i < keys.length; i++) {
      const route = routes[keys[i]];
      const match = matchPath(location.pathname, route);

      if (match) return true;
    }
  }, [location.pathname]);

  const items = useMemo(() => {
    return Object.entries(routes).map(([key, route]) => {
      const isUnavailableRoute = route.closeAccess.some((role) => userInfo?.role === role);
      const isClosedLeadsPage = key === 'leads' && !selfhost?.leadsUrl;

      const render = () => {
        if (isUnavailableRoute || isClosedLeadsPage) {
          return <Redirect to={routes.index.link} />;
        }

        const Component = route.component;

        if (
          (!isOwner && !userInfo?.allowedCommands?.length) ||
          route.path === '/commands' ||
          route.path.includes('/commands/:id')
        ) {
          return <Component />;
        }

        return commandId ? <Component /> : <OwnerContentPage />;
      };

      return <Route key={key} path={route.path} exact={route.exact} render={render} />;
    });
  }, [commandId, isOwner, selfhost?.leadsUrl, userInfo?.role, userInfo?.allowedCommands]);

  if (!isFoundRoute) {
    return <ErrorPage />;
  }

  return (
    <>
      <Sidebar />

      <StyledRouterContentWrapper id={ROUTER_CONTENT_ID}>
        <Header />

        <StyledRouterContent $hasPadding>
          <StyledRouterContent>
            <Suspense fallback={<Loader />}>
              <Switch>{items}</Switch>
            </Suspense>
          </StyledRouterContent>
        </StyledRouterContent>
      </StyledRouterContentWrapper>
    </>
  );
});

const Router = () => {
  const { error } = useGetMeQuery();
  const { isAuthorized } = useAuthorization();

  const isPaymentError = error && 'status' in error && error.status === 402;

  if (isPaymentError) {
    return <PaymentErrorPage />;
  }

  if (!isAuthorized) {
    return <Route component={LoginPage} />;
  }

  return <FilledRouter />;
};

export default Router;
