import { i18nextPlugin } from 'translation-check';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import i18next from 'i18next';

import getLanguage from '@/resources/i18next/getLanguage';
import { DEFAULT_LANGUAGE } from '@/resources/constants';

// TODO: убрать импорт package.json
import { version } from '@/resources/../../package.json';

const language = getLanguage(DEFAULT_LANGUAGE);
const queryStringParams = version ? { v: version } : undefined;

i18next
  .use(i18nextPlugin)
  .use(Backend)
  .use(initReactI18next)
  .init({
    ns: 'common',
    defaultNS: 'common',

    lng: language,
    fallbackLng: language,

    debug: false,
    interpolation: { escapeValue: false },

    backend: {
      queryStringParams,
    },
  });

export default i18next;
