import { styled, css } from '@mui/material';

import transientProps from '@/utils/transientProps';

export const StyledStatistic = styled('div', transientProps)<{ $isFullWidth?: boolean }>`
  ${({ $isFullWidth }) =>
    $isFullWidth
      ? css`
          width: 100%;
        `
      : css`
          width: 50%;
        `};
`;

export const StyledWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(3)};

  border: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};
  border-radius: ${({ theme }) => `${theme.shape.borderRadiusSecond}px`};
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledOptionsWrapper = styled('div', transientProps)<{
  $hasPadding?: boolean;
  $isJustifyCenter?: boolean;
}>`
  display: flex;
  align-items: center;

  ${({ $isJustifyCenter }) =>
    $isJustifyCenter &&
    css`
      justify-content: center;
    `};

  ${({ $hasPadding }) =>
    $hasPadding &&
    css`
      padding: 8px 12px;
      overflow-x: auto;
    `};

  height: 100%;

  border: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};
  border-radius: ${({ theme }) => `${theme.shape.borderRadiusSecond}px`};
  background-color: ${({ theme }) => theme.palette.common.white};
`;
