import { useForm, FormProvider } from 'react-hook-form';

import Loader from '@/components/Loader';
import { FormWithContextProps, FormForContextProps, FormProps } from '@/components/Form/FormTypes';
import { StyledForm } from '@/components/Form/FormStyled';

// NOTE
// https://react-hook-form.com/advanced-usage#SmartFormComponent

const FormWithContext = <TFormValues,>({
  onSubmit,
  children,
  isFlexible,
  defaultValues,
  ...restProps
}: FormWithContextProps<TFormValues>) => {
  const methods = useForm({ defaultValues });

  return (
    // @ts-ignore
    <FormProvider {...methods}>
      <StyledForm {...restProps} $isFlexible={isFlexible} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </StyledForm>
    </FormProvider>
  );
};

const FormForContext = ({
  onSubmit,
  children,
  contextMethods,
  isFlexible,
  ...restProps
}: FormForContextProps) => {
  const methods = contextMethods;

  return (
    // @ts-ignore
    <FormProvider {...methods}>
      <StyledForm {...restProps} $isFlexible={isFlexible} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </StyledForm>
    </FormProvider>
  );
};

const Form = <TFieldValues,>({ children, isLoading, ...restProps }: FormProps<TFieldValues>) => {
  const markup = (
    <>
      {isLoading && <Loader />}
      {children}
    </>
  );

  if ('contextMethods' in restProps && restProps.contextMethods) {
    // @ts-ignore
    return <FormForContext {...restProps}>{markup}</FormForContext>;
  }

  return <FormWithContext {...restProps}>{markup}</FormWithContext>;
};

export default Form;
