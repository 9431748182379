import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';

import UserRole from '@/pages/CommandPage/UserRole';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';

import routes from '@/resources/routes';

import { getUserNickname } from '@/utils/getUserNickname';
import filterBoolean from '@/utils/filterBoolean';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCompanyInfo from '@/hooks/useUserCompanyInfo';
import useUserCommandId from '@/hooks/useUserCommandId';
import usePageParams from '@/hooks/usePageParams';

import { ApiUser } from '@/services/users/users.types';
import { useBanUserByIdMutation, useUnBanUserByIdMutation } from '@/services/users/users';
import { useLazyGetCommandByIdQuery } from '@/services/commands/commands';
import ModalUser from '@/modals/ModalUser/ModalUser';
import ModalInvite from '@/modals/ModalInvite/ModalInvite';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';

const CommandPage = () => {
  const { t } = useTranslation(['common', 'command']);

  const history = useHistory();
  const { id } = usePageParams();
  const userCommandId = useUserCommandId();
  const { tracker, selfhost } = useUserCompanyInfo();
  const { isHighLevelUser, isLeader } = useUserInfo();

  const commandId = isHighLevelUser ? id : userCommandId;
  const [getCommandById, { data, isLoading }] = useLazyGetCommandByIdQuery();

  const [unBanUserById, { status: userUnBanStatus }] = useUnBanUserByIdMutation();
  const [banUserById, { status: userBanStatus }] = useBanUserByIdMutation();

  useEffect(() => {
    if (!commandId) return;
    getCommandById({ id: commandId });
  }, [commandId, getCommandById, userUnBanStatus, userBanStatus]);

  const openInviteModal = () => {
    ModalInvite.show(data);
  };

  const onGoBack = () => {
    history.push(routes.commands.link);
  };

  const handleUserArchive = useCallback(
    (user: ApiUser & { nickname: string }) => () => {
      ModalConfirm.show({
        continueLabel: t('common:buttonActions.archive'),
        title: t('common:notifications.confirmActionOnPage'),
        subTitle: t('command:confirmArchive', { nickname: user.nickname }),
        onlyContinue: true,
        onContinue: () =>
          banUserById({
            id: user.id,
            commandId: user.command.id,
          }),
      });
    },
    [banUserById, t],
  );

  const handleUserUnArchive = useCallback(
    (user: ApiUser) => () => {
      unBanUserById({
        id: user.id,
        commandId: user.command.id,
      });
    },
    [unBanUserById],
  );

  const openEditUserModal = useCallback(
    (userInfo: ApiUser) => () => {
      ModalUser.show({ ...userInfo, commandId: userInfo.command.id });
    },
    [],
  );

  const config: TableConfig = useMemo(() => {
    const list: ApiUser[] = [...(data?.users || []), ...(data?.allowedUsers || [])];
    return list.map((a, i) => {
      const userNickname = getUserNickname(a);

      const onArchiveClick = a.isEnabled
        ? handleUserArchive({ ...a, nickname: userNickname })
        : handleUserUnArchive({ ...a });
      const buttonsConfig: TableRowActionsProps['buttonsConfig'] = [];
      const actionProps: TableRowActionsProps = {
        isIconEdit: true,
        onEdit: openEditUserModal(a),
        buttonsConfig,
      };
      if (a.isEnabled) {
        actionProps.onArchive = onArchiveClick;
      } else {
        actionProps.onUnarchive = onArchiveClick;
      }
      return {
        id: a.id,
        data: [
          {
            label: 'ID',
            value: i + 1,
            isHiddenLabel: true,
          },
          {
            label: t('common:roles.employee'),
            value: userNickname,
          },
          {
            label: t('common:sidebar.email'),
            value: a.email,
          },
          {
            label: t('common:role'),
            value: <UserRole user={a} />,
          },

          Boolean(tracker) && {
            label: 'TrackerId',
            value: a.trackerId || '-',
          },

          Boolean(selfhost) && {
            label: 'SelfhostId',
            value: a.leadId || '-',
          },
          {
            label: 'BrocardId',
            value: a.paymentServiceIntegrationId || '-',
          },
          {
            label: '',
            value: <TableRowActions {...actionProps} />,
          },
        ].filter(filterBoolean),
      };
    });
  }, [
    data?.allowedUsers,
    data?.users,
    handleUserArchive,
    handleUserUnArchive,
    openEditUserModal,
    selfhost,
    t,
    tracker,
  ]);

  return (
    <>
      <PageTitle onGoBack={!isLeader && onGoBack}>{t('common:sidebar.employees')}</PageTitle>

      <PageHeaderContent label={t('common:roles.employee')} onClick={openInviteModal} />

      <Table isAutoWidth config={config} isLoading={isLoading} />
    </>
  );
};

export default CommandPage;
