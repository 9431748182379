import { Components, Theme } from '@mui/material';

const ungroupedComponents: Components<Omit<Theme, 'components'>> = {
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0,

        '&.Mui-expanded': {
          margin: theme.spacing(4, 0, 0),
        },

        '&::before': {
          display: 'none',
        },

        border: 'none',
        borderRadius: '6px',
        backgroundColor: theme.palette.background.default,
      }),
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: { borderRadius: '4px !important' },
    },
  },
};

export default ungroupedComponents;
