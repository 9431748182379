import useQueryParam from '@/hooks/useQueryParam';

const useStatusFilter = () => {
  const queryName = 'status';
  const [status, setStatus] = useQueryParam(queryName, '');

  return {
    setStatus,
    status: status || undefined,
  };
};

export default useStatusFilter;
