import { useMemo, useEffect } from 'react';

import { PossibleStatisticCategories } from '@/resources/constantsStatistic';

import filterBoolean from '@/utils/filterBoolean';

import useUserInfo from '@/hooks/useUserInfo';
import useQueryParam from '@/hooks/useQueryParam';

import { useLazyGetDashboardStatisticsQuery } from '@/services/dashboard/dashboard';
import useUserCommandsFilter from '@/filters/useUserCommandsFilter';
import useMasterFilter from '@/filters/useMasterFilter';
import useFromToFilter from '@/filters/useFromToFilter';

const useStatisticData = (isSecondGraphic?: boolean) => {
  const { isMaster, isOwner, isLeader } = useUserInfo();
  const [getStatistics, { data, isLoading }] = useLazyGetDashboardStatisticsQuery();

  const { to, from } = useFromToFilter();
  const [params] = useQueryParam('parameters', '');

  const { commandId } = useUserCommandsFilter(isSecondGraphic ? '2' : '');
  const { masterId, setMasterId } = useMasterFilter(isSecondGraphic ? '2' : '');

  const { masterId: anotherMasterId } = useMasterFilter(isSecondGraphic ? '' : '2');
  const { commandId: anotherCommandId } = useUserCommandsFilter(isSecondGraphic ? '' : '2');

  useEffect(() => {
    if (!isOwner) return;
    if (commandId) return;
    if (!masterId) return;

    setMasterId('');
  }, [commandId, isOwner, masterId, setMasterId]);

  const isEqualGraphicCommands = commandId === anotherCommandId;

  const isDisabledOwner = isOwner && !commandId;
  const isDisabledLeader = isLeader && !masterId;
  const isDisabledMaster = isMaster && isSecondGraphic;
  const isDisabledDuplicate = isOwner && isEqualGraphicCommands && !masterId;

  const isDisabled = isDisabledOwner || isDisabledLeader || isDisabledMaster || isDisabledDuplicate;

  useEffect(() => {
    if (isDisabled) return;

    getStatistics({
      to,
      from,
      commandId,
      userId: masterId,
    });
  }, [commandId, from, getStatistics, isDisabled, masterId, to]);

  const parameters = useMemo(() => {
    return params.split(',').filter(filterBoolean) as unknown as PossibleStatisticCategories[];
  }, [params]);

  return {
    masterId,
    commandId,
    parameters,

    isLoading,
    isEqualGraphicCommands,

    data: isDisabled ? undefined : data,
    filterQueryKey: isSecondGraphic ? '2' : '',
    selectedMasters: anotherMasterId ? [{ value: anotherMasterId }] : undefined,
  };
};

export type UseStatisticDataResult = ReturnType<typeof useStatisticData>;

export default useStatisticData;
