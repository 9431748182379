import { useTranslation } from 'react-i18next';
import { DialogActions } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import FormRuler from '@/utils/FormRuler';
import Alerter, { AlerterTypes } from '@/utils/Alerter/Alerter';

import useUserInfo from '@/hooks/useUserInfo';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { ApiInvitePostBody } from '@/services/invites/invites.types';
import { useCreateInviteMutation } from '@/services/invites/invites';
import { ApiUserRole } from '@/services/auth/auth.types';
import withStaticModal, { Entity, StaticModalWrappedComponent } from '@/modals/withStaticModal';

const ModalInviteFinancier: StaticModalWrappedComponent<Entity> = (props) => {
  const { userInfo } = useUserInfo();

  const { t } = useTranslation(['common', 'command']);

  const [createInvite, { isLoading }] = useCreateInviteMutation();

  const [handleSubmit] = useAwaitCallback(
    async ({ email }: { email: string }) => {
      const url = window.location.origin;

      const body: ApiInvitePostBody = {
        url,
        email,
        companyId: userInfo?.company.id,
        role: ApiUserRole.COMPANY_FINANCIER,
      };

      const response = await createInvite(body);

      if ('data' in response) {
        Alerter.show(t('command:invitationHasBeenSent'), AlerterTypes.success);
        props.onClose();
      }
    },
    [createInvite, props],
  );

  return (
    <Modal {...props} title={t('command:inviteFinancier')}>
      <Form onSubmit={handleSubmit}>
        <FormElement
          component='input'
          label='E-mail'
          name='email'
          rules={FormRuler.requiredInput}
        />

        <DialogActions>
          <Button isLoading={isLoading} type='submit'>
            {t('common:buttonActions.add')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<Entity>(ModalInviteFinancier);
