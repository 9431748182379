import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMemo, useEffect } from 'react';
import { DialogActions } from '@mui/material';

import Typography from '@/components/Typography';
import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import { maxLength } from '@/resources/constants';

import { getUserNickname } from '@/utils/getUserNickname';
import FormRuler from '@/utils/FormRuler';

import useUserCommandId from '@/hooks/useUserCommandId';
import useBoolState from '@/hooks/useBoolState';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { useCreateOfferReportMutation } from '@/services/reports/reports';
import { ApiOffer } from '@/services/offers/offers.types';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalOfferReportValues,
  FormModalOfferReportData,
} from '@/modals/ModalOfferReport/ModalOfferReportTypes';

const validateFields = ['deposits', 'registrations', 'installations'];

const ModalOfferReport: StaticModalWrappedComponent<ApiOffer> = (props) => {
  const { t } = useTranslation(['common', 'offer']);

  const commandId = useUserCommandId();

  const { state, setTrue, setFalse } = useBoolState(false);

  const options = useMemo(
    () =>
      props.data?.users.map((a) => ({
        label: getUserNickname(a),
        value: a.id,
      })) || [],
    [props.data?.users],
  );

  const defaultValues: FormModalOfferReportData = {
    [FormModalOfferReportValues.HOLD]: '0',
    [FormModalOfferReportValues.DEPOSITS]: '0',
    [FormModalOfferReportValues.REJECTED]: '0',
    [FormModalOfferReportValues.REGISTRATIONS]: '0',
    [FormModalOfferReportValues.INSTALLATIONS]: '0',
    [FormModalOfferReportValues.DATE]: '',
    [FormModalOfferReportValues.USER_ID]: options[0]?.value || '',
  };

  const methods = useForm<FormModalOfferReportData>({
    defaultValues,
  });

  const depositsField = methods.watch('deposits');
  const installationsField = methods.watch('installations');
  const registrationsField = methods.watch('registrations');

  useEffect(() => {
    setFalse();
    // @ts-ignore
    methods.clearErrors(validateFields);
  }, [depositsField, installationsField, methods, registrationsField, setFalse]);

  const [createOfferReport, { isLoading }] = useCreateOfferReportMutation();

  const [handleSubmit] = useAwaitCallback(async (data: FormModalOfferReportData) => {
    if (data.deposits === '0' && data.installations === '0' && data.registrations === '0') {
      validateFields.forEach((name) => {
        // @ts-ignore
        methods.setError(name);
      });
      setTrue();
      return;
    }

    const body = {
      commandId,
      id: props.data!.id,
      [FormModalOfferReportValues.DATE]: data.date,
      [FormModalOfferReportValues.HOLD]: Number(data.hold),
      [FormModalOfferReportValues.USER_ID]: data.userId,
      [FormModalOfferReportValues.DEPOSITS]: Number(data.deposits),
      [FormModalOfferReportValues.REJECTED]: Number(data.rejected),
      [FormModalOfferReportValues.REGISTRATIONS]: Number(data.registrations),
      [FormModalOfferReportValues.INSTALLATIONS]: Number(data.installations),
    };

    const response = await createOfferReport(body);

    if ('data' in response) {
      props.onClose();
    }
  }, []);

  // TODO: Решить, что делать с этими полями, скорее всего нужно будет скрывать при подтвержденном отчете
  const isHideField = Boolean(props.data?.trackerId) || true;

  return (
    <Modal {...props} title={t('offer:addReport')}>
      <Form contextMethods={methods} onSubmit={handleSubmit}>
        <FormElement
          label={t('common:tableLabels.date')}
          component='datepicker'
          name={FormModalOfferReportValues.DATE}
          rules={FormRuler.required}
        />

        <FormElement
          label={t('common:tableLabels.user')}
          component='select'
          values={options}
          name={FormModalOfferReportValues.USER_ID}
          rules={FormRuler.required}
        />

        <FormElement
          isNumber
          label={t('common:tableLabels.deposits')}
          component='input'
          name={FormModalOfferReportValues.DEPOSITS}
          hideHelperText={state}
          rules={FormRuler.required}
        />

        {!isHideField && (
          <>
            <FormElement
              isNumber
              label={t('common:tableLabels.registrations')}
              disabled={isHideField}
              component='input'
              name={FormModalOfferReportValues.REGISTRATIONS}
              hideHelperText={state}
              rules={FormRuler.required}
            />
            <FormElement
              isNumber
              label={t('common:tableLabels.installations')}
              disabled={isHideField}
              component='input'
              name={FormModalOfferReportValues.INSTALLATIONS}
              hideHelperText={state}
              rules={FormRuler.required}
            />
          </>
        )}

        <FormElement
          isDecimalNumber
          label={`${t('common:tableLabels.hold')} %`}
          component='input'
          name={FormModalOfferReportValues.HOLD}
          rules={{ ...FormRuler.required, ...FormRuler.checkNumber(maxLength.report) }}
        />

        <FormElement
          isDecimalNumber
          label={`${t('common:tableLabels.rejected')} %`}
          component='input'
          name={FormModalOfferReportValues.REJECTED}
          rules={{ ...FormRuler.required, ...FormRuler.checkNumber(maxLength.report) }}
        />

        {state && (
          <Typography sx={{ paddingTop: 3 }} color='error'>
            {t('offer:enterOneConversion')}
          </Typography>
        )}

        <DialogActions>
          <Button type='submit' isLoading={isLoading}>
            {t('common:buttonActions.add')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiOffer>(ModalOfferReport);
