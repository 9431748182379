import { useHistory } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import QueryString from 'query-string';

export type UpdateType = 'push' | 'pushIn' | 'replace' | 'replaceIn';

export type SetQueryFn = (
  value: string | ((prevValue: string) => string),
  updateType?: UpdateType,
  withClearPage?: boolean, // в будущем можно превратить в сбрасываемые поля
) => Promise<void>;

export const useQueryParam = <T extends string>(name: string, initialValue: T): [T, SetQueryFn] => {
  const history = useHistory();
  const { replace, push } = history;

  const query = QueryString.parse(history.location.search);

  const queryValue = query[name] as T;
  const [value, setValue] = useState(queryValue ?? initialValue);

  useEffect(() => {
    setValue(queryValue || initialValue);
  }, [queryValue, initialValue]);

  const setQuery = useCallback<SetQueryFn>(
    async (newValue, updateType = 'replaceIn', withClearPage?: boolean) => {
      const method = ['push', 'pushIn'].includes(updateType) ? push : replace;

      const prevQuery = ['pushIn', 'replaceIn'].includes(updateType) ? query : {};
      const nextValue = typeof newValue === 'function' ? newValue(value) : newValue;

      const newUrl: QueryString.UrlObject = {
        url: history.location.pathname,
        query: {
          ...prevQuery,
          [name]: nextValue as string,
        },
      };

      if (nextValue === '') {
        delete newUrl.query?.[name];
      }

      if (withClearPage) {
        delete newUrl.query?.page;
      }

      await method(QueryString.stringifyUrl(newUrl), undefined);
    },
    [name, query, value, push, replace, history.location.pathname],
  );

  return [value, setQuery];
};

export default useQueryParam;
