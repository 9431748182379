import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import PageFilters from '@/components/PageFilters';

import { buttonWidth } from '@/resources/constants';

import { getUserNickname } from '@/utils/getUserNickname';
import { formatNumber } from '@/utils/formatNumber';
import filterBoolean from '@/utils/filterBoolean';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommandId from '@/hooks/useUserCommandId';
import usePagination from '@/hooks/usePagination';
import useLocalizeCardStatus from '@/hooks/useLocalizeCardStatus';
import useArchiveHelpers from '@/hooks/useArchiveHelper';

import { ApiCard, ApiCardStatus } from '@/services/cards/cards.types';
import {
  useChangeCardArchiveStatusMutation,
  useGetCardsQuery,
  useOpenCardByIdMutation,
} from '@/services/cards/cards';
import ModalCardReplenishment from '@/modals/ModalCardReplenishment/ModalCardReplenishment';
import ModalCardConsumption from '@/modals/ModalCardConsumption/ModalCardConsumption';
import ModalCardClose from '@/modals/ModalCardClose/ModalCardClose';
import ModalCard from '@/modals/ModalCard/ModalCard';
import useMasterFilter from '@/filters/useMasterFilter';
import useCardStatusFilter from '@/filters/useCardStatusFilter';
import FilterMaster from '@/filters/FilterMaster';
import FilterArchive from '@/filters/FilterArchive';

const CardsPage = () => {
  const { t } = useTranslation(['common', 'cards']);

  const { localizeCardStatus } = useLocalizeCardStatus();

  const { status, CardStatusFilter } = useCardStatusFilter();
  const { masterId } = useMasterFilter();

  const [openCard] = useOpenCardByIdMutation();

  const { isMaster } = useUserInfo();

  const [changeArchiveStatus] = useChangeCardArchiveStatusMutation();
  const { prepareArchiveColumns, isArchived } = useArchiveHelpers(changeArchiveStatus, (name) =>
    t('cards:confirmArchive', { name }),
  );

  const commandId = useUserCommandId();

  const { cards, isLoading, setPage, setLimit, getPagination } = usePagination({
    cacheKey: 'cards',
    useQuery: useGetCardsQuery,
    queryArg: {
      isArchived,
      commandId,
      status,
      userId: masterId,
    },
  });

  const openCreateCardModal = () => {
    ModalCard.show();
  };

  const openEditCardModal = (card: ApiCard) => () => {
    ModalCard.show(card);
  };

  const openReplenishmentCardModal = (card: ApiCard) => () => {
    ModalCardReplenishment.show(card);
  };

  const openCardCloseModal = useCallback(
    (card: ApiCard) => () => {
      ModalCardClose.show(card);
    },
    [],
  );

  const handleCardOpen = useCallback(
    ({ id }: ApiCard) =>
      () => {
        openCard(id);
      },
    [openCard],
  );

  const handleCardConsumption = useCallback(
    (card: ApiCard) => () => {
      ModalCardConsumption.show(card);
    },
    [],
  );

  const config: TableConfig = useMemo(() => {
    const list: ApiCard[] = cards?.list || [];

    return list.map((item) => {
      const isClosed = item.status === ApiCardStatus.CLOSED;
      const isOpen = item.status === ApiCardStatus.OPEN;
      const isIntegrated = Boolean(item.service.apiIntegrationKey);
      const hasPipes = item.pipes.length !== 0;

      const actionProps: TableRowActionsProps = {
        isIconEdit: true,
        onEdit: !isIntegrated && item.status !== ApiCardStatus.CLOSED && openEditCardModal(item),
        buttonsConfig: [
          {
            textForButton: t('buttonActions.replenish'),
            onClick: !isIntegrated && isOpen && openReplenishmentCardModal(item),
          },
          {
            textForButton: t('buttonActions.consumption'),
            onClick: !isIntegrated && isOpen && hasPipes && handleCardConsumption(item),
          },
          {
            textForButton: isClosed ? t('buttonActions.open') : t('buttonActions.close'),
            onClick: !isIntegrated && (isClosed ? handleCardOpen(item) : openCardCloseModal(item)),
            style: { width: buttonWidth.close },
            color: isClosed ? 'primary' : 'error',
            variant: isClosed ? 'contained' : 'text',
          },
        ],
      };

      prepareArchiveColumns(item, actionProps);
      if (isIntegrated) {
        if ('onArchive' in actionProps) {
          actionProps.onArchive = undefined;
        }
        if ('onUnarchive' in actionProps) {
          actionProps.onUnarchive = undefined;
        }
      }
      const isIntegrationNotFound = Boolean(item.service.apiIntegrationKey) && !item.externalId;
      const hasNoMaster = !item.user;
      const warnings = [
        !hasPipes && t('cards:noPipesOnCard'),
        isIntegrationNotFound && t('cards:cardIntegrationNotFound'),
        hasNoMaster && t('cards:hasNoMaster'),
      ]
        .filter(filterBoolean)
        .map((str) => `- ${str}`);
      return {
        id: item.id,
        data: [
          {
            label: 'ActionsInfo',
            isHiddenLabel: true,
            value: (
              <TableRowActions
                callout={warnings.length > 0 && warnings.join('\n')}
                calloutType='warn'
              />
            ),
          },
          {
            label: t('tableLabels.name'),
            value: item.name,
          },
          {
            label: t('tableLabels.card'),
            value: <div key={item.card}>**{item.card.slice(-4)}</div>,
          },
          {
            label: t('tableLabels.cardBalance'),
            value: formatNumber(item.balance),
          },
          {
            label: t('tableLabels.serviceReplenishment'),
            value: item.service.name,
          },
          !isMaster && {
            label: t('roles.user'),
            value: getUserNickname(item.user),
          },
          {
            label: t('tableLabels.status'),
            value: localizeCardStatus(item.status),
          },
          {
            label: 'Actions',
            isHiddenLabel: true,
            value: <TableRowActions {...actionProps} />,
            width: '400px',
          },
        ].filter(filterBoolean),
      };
    });
  }, [
    cards?.list,
    t,
    handleCardConsumption,
    handleCardOpen,
    openCardCloseModal,
    prepareArchiveColumns,
    isMaster,
    localizeCardStatus,
  ]);

  return (
    <>
      <PageTitle>{t('sidebar.cards')}</PageTitle>

      <PageHeaderContent label={t('tableLabels.card')} onClick={openCreateCardModal} />

      <PageFilters>
        <CardStatusFilter disableAutofill />
        {!isMaster && <FilterMaster disableAutofill />}
        <FilterArchive />
      </PageFilters>

      <Table
        config={config}
        isLoading={isLoading}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        pagination={getPagination(cards?.meta)}
      />
    </>
  );
};

export default CardsPage;
