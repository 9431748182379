import {
  invalidatesTags,
  invalidatesListItem,
  providesList,
  providesListItem,
} from '@/services/utils';
import commonApi from '@/services/common/common';
import {
  ApiCommandByIdGetQuery,
  ApiCommandByIdGetResponse,
  ApiCommandByIdPatchBody,
  ApiCommandByIdPatchResponse,
  ApiCommandPostBody,
  ApiCommandPostResponse,
  ApiCommandsGetQuery,
  ApiCommandsGetResponse,
} from '@/services/commands/commands.types';
import { servicesTags } from '@/services';

type EntityId = string | number;

const baseUrl = '/commands';
const serviceTag = servicesTags.commands;

const commandsApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getCommands: build.query<ApiCommandsGetResponse, ApiCommandsGetQuery>({
      // TODO: params || undefined
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    getCommandById: build.query<ApiCommandByIdGetResponse, ApiCommandByIdGetQuery>({
      query: ({ id }) => `${baseUrl}/${id}`,
      providesTags: providesListItem(serviceTag),
    }),

    createCommand: build.mutation<ApiCommandPostResponse, ApiCommandPostBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    updateCommandById: build.mutation<ApiCommandByIdPatchResponse, ApiCommandByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
    deleteCommandById: build.mutation<void, EntityId>({
      query: (id) => ({ url: `${baseUrl}/${id}`, method: 'DELETE' }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
  }),
});

export const {
  useGetCommandsQuery,
  useLazyGetCommandsQuery,
  useGetCommandByIdQuery,
  useLazyGetCommandByIdQuery,
  useCreateCommandMutation,
  useUpdateCommandByIdMutation,
  useDeleteCommandByIdMutation,
} = commandsApi;

export default commandsApi;
