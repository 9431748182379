import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { DialogActions } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import { isNotAvailableCount } from '@/utils/isNotAvailableCount';
import FormRuler from '@/utils/FormRuler';

import useUserConsumablesByCategory from '@/hooks/useUserConsumablesByCategory';
import useUserConsumableCategories from '@/hooks/useUserConsumableCategories';
import useUserCommandId from '@/hooks/useUserCommandId';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import {
  ApiPipe,
  ApiPipeConsumable,
  ApiPipeConsumableByIdPatchAmountBody,
} from '@/services/pipes/pipes.types';
import {
  useCreatePipeConsumableMutation,
  useUpdatePipeConsumableMutation,
} from '@/services/pipes/pipes';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import ModalPipeConsumablesList from '@/modals/ModalPipeConsumablesList/ModalPipeConsumablesList';
import {
  FormModalPipeConsumableData,
  FormModalPipeConsumableValues,
} from '@/modals/ModalPipeConsumable/ModalPipeConsumableTypes';

interface ModalPipeConsumableProps {
  pipe?: ApiPipe;
  pipeConsumable?: ApiPipeConsumable;
}

const ModalPipeConsumable: StaticModalWrappedComponent<ModalPipeConsumableProps> = (props) => {
  const { t } = useTranslation(['common', 'pipes']);

  const isEdit = Boolean(props.data?.pipeConsumable?.id);

  const methods = useForm<FormModalPipeConsumableData>({
    defaultValues: {
      [FormModalPipeConsumableValues.DATE]: props.data?.pipeConsumable?.date || '',
      [FormModalPipeConsumableValues.CONSUMABLE_ID]: props.data?.pipeConsumable?.id || '',
      [FormModalPipeConsumableValues.CATEGORY]: props.data?.pipeConsumable?.category.id || '',
      [FormModalPipeConsumableValues.PIPE_CONSUMABLE_ID]:
        props.data?.pipeConsumable?.pipeConsumableId || '',
      [FormModalPipeConsumableValues.AMOUNT]: String(
        props.data?.pipeConsumable?.amountOfUsed || '',
      ),
    },
  });

  const categoryId = methods.watch(FormModalPipeConsumableValues.CATEGORY);
  const consumableId = methods.watch(FormModalPipeConsumableValues.CONSUMABLE_ID);

  const userId = props.data?.pipe?.user.id;
  const commandId = useUserCommandId();

  const { categories } = useUserConsumableCategories();
  const { consumables, originalConsumables } = useUserConsumablesByCategory(
    categoryId,
    userId,
    'available',
    props.data?.pipeConsumable?.amountOfUsed,
  );

  const [createPipeConsumable] = useCreatePipeConsumableMutation();
  const [updatePipeConsumable] = useUpdatePipeConsumableMutation();

  const consumable = useMemo(() => {
    if (!originalConsumables || !originalConsumables.length) return undefined;
    return originalConsumables.find(({ id }) => id === consumableId);
  }, [consumableId, originalConsumables]);

  const [handleSubmit, isLoading] = useAwaitCallback(
    async (data: FormModalPipeConsumableData) => {
      if (!props.data?.pipe) return;
      if (isNotAvailableCount(data, consumables, methods, t)) return;

      const updateBody: ApiPipeConsumableByIdPatchAmountBody = {
        commandId,
        pipeId: props.data.pipe.id,
        id: data[FormModalPipeConsumableValues.PIPE_CONSUMABLE_ID],
        consumableId: data[FormModalPipeConsumableValues.CONSUMABLE_ID],

        amount: Number(data[FormModalPipeConsumableValues.AMOUNT]),
      };

      const response = isEdit
        ? await updatePipeConsumable(updateBody)
        : await createPipeConsumable({
            ...updateBody,
            date: data[FormModalPipeConsumableValues.DATE],
          });

      if ('data' in response) {
        ModalPipeConsumablesList.hide();
        props.onClose();
      }
    },
    [createPipeConsumable, updatePipeConsumable, isNotAvailableCount, props],
  );

  const title = isEdit ? t('pipes:editUsageConsumables') : t('pipes:addUseConsumable');

  return (
    <Modal {...props} title={title}>
      <Form contextMethods={methods} onSubmit={handleSubmit}>
        {!isEdit && (
          <>
            <FormElement
              size='small'
              label={t('common:tableLabels.category')}
              component='select'
              values={categories}
              rules={FormRuler.required}
              name={FormModalPipeConsumableValues.CATEGORY}
            />

            <FormElement
              size='small'
              label={t('common:tableLabels.consumables')}
              component='select'
              values={consumables}
              rules={FormRuler.required}
              name={FormModalPipeConsumableValues.CONSUMABLE_ID}
            />
          </>
        )}

        <FormElement
          isNumber
          label={t('common:tableLabels.quantity')}
          component='input'
          rules={FormRuler.requiredNumberInput}
          name={FormModalPipeConsumableValues.AMOUNT}
        />

        {!isEdit && consumable && (
          <FormElement
            component='datepicker'
            label={t('common:formLabels.dateAdded')}
            minDate={consumable.date}
            name={FormModalPipeConsumableValues.DATE}
            rules={{
              ...FormRuler.required,
              ...FormRuler.validateDate(consumable.date, false, 'day'),
            }}
          />
        )}

        <DialogActions>
          <Button type='submit' isLoading={isLoading}>
            {t('common:buttonActions.add')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ModalPipeConsumableProps>(ModalPipeConsumable);
