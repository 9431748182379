import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import Typography from '@/components/Typography';
import StatisticBlockItem from '@/components/StatisticBlockItem';
import StatisticBlock from '@/components/StatisticBlock';

import { getUserNickname } from '@/utils/getUserNickname';

import useUserCommandId from '@/hooks/useUserCommandId';

import { useGetLeadsStatisticsQuery } from '@/services/leads/leads';
import useMasterFilter from '@/filters/useMasterFilter';
import useFromToFilter from '@/filters/useFromToFilter';

const LeadsInfo = () => {
  const { t } = useTranslation(['common']);

  const commandId = useUserCommandId();
  const { to, from } = useFromToFilter();
  const { masterId } = useMasterFilter(undefined);

  const { data, isLoading } = useGetLeadsStatisticsQuery({
    to,
    from,
    commandId,
    userId: masterId,
  });

  const config = useMemo(() => {
    if (!data) {
      return (
        <Typography variant='body2' color='secondary'>
          {t('common:noDataAvailable')}
        </Typography>
      );
    }

    let totalCount = 0;

    const result = data.statistics.map((a) => {
      totalCount += Number(a.count);
      return <StatisticBlockItem key={a.user.id} label={getUserNickname(a.user)} value={a.count} />;
    });

    return [
      <StatisticBlockItem
        key={'totalCount'}
        label={t('common:tableLabels.total')}
        value={totalCount}
      />,
      ...result,
    ];
  }, [data, t]);

  return (
    <StatisticBlock isLoading={isLoading} hasData={Boolean(data?.statistics)}>
      {config}
    </StatisticBlock>
  );
};

export default LeadsInfo;
