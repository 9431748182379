import { useTranslation } from 'react-i18next';

import useLocalizeOfferStatus from '@/hooks/useLocalizeOfferStatus';

import { ApiOfferStatus } from '@/services/offers/offers.types';
import usePageFilter, { HookComponentProps } from '@/filters/usePageFilter';

export const getOfferStatusOptions = (localizeOfferStatus: {
  (status: ApiOfferStatus): string;
}) => [
  {
    label: localizeOfferStatus(ApiOfferStatus.CREATED),
    value: ApiOfferStatus.CREATED,
  },
  {
    label: localizeOfferStatus(ApiOfferStatus.IN_WORK),
    value: ApiOfferStatus.IN_WORK,
  },
  {
    label: localizeOfferStatus(ApiOfferStatus.SUSPENDED),
    value: ApiOfferStatus.SUSPENDED,
  },
  {
    label: localizeOfferStatus(ApiOfferStatus.PROBLEM),
    value: ApiOfferStatus.PROBLEM,
  },
  {
    label: localizeOfferStatus(ApiOfferStatus.CLOSED),
    value: ApiOfferStatus.CLOSED,
  },
];

const useOfferStatusFilter = () => {
  const { t } = useTranslation();

  const { Component, ...rest } = usePageFilter('status', '');

  const { localizeOfferStatus } = useLocalizeOfferStatus();

  const offerStatusOptions = getOfferStatusOptions(localizeOfferStatus);

  return {
    ...rest,
    OfferStatusFilter: <Multiple extends boolean>(props: HookComponentProps<Multiple>) => (
      <Component {...props} label={t('tableLabels.status')} options={offerStatusOptions} />
    ),
  };
};

export default useOfferStatusFilter;
