import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import { Grid, Toolbar, Box } from '@mui/material';

import Menu from '@/components/Menu/Menu';
import Localization from '@/components/Localization/Localization';
import { StyledAdditionalContent, StyledBackButton } from '@/components/Header/HeaderStyled';

import IconLogout from '@/icons/IconLogout';

import { HEADER_ADDITIONAL_CONTENT_ID, HEADER_TITLE_ID } from '@/resources/constants';
import { HEADER_BACK_BUTTON_ID } from '@/resources/constants';

import useLogoutUser from '@/hooks/useLogoutUser';

const Header = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<HTMLElement | null>(null);

  const logoutUser = useLogoutUser();

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar position='sticky' color='inherit'>
        <Toolbar disableGutters>
          <Box alignItems='center' flex={1} display='flex'>
            <StyledBackButton id={HEADER_BACK_BUTTON_ID} />
            <div id={HEADER_TITLE_ID} />
            <StyledAdditionalContent id={HEADER_ADDITIONAL_CONTENT_ID} />
          </Box>

          <Localization />

          <Grid width='auto' alignItems='center' container sx={{ ml: 4 }}>
            <IconButton size='large' onClick={logoutUser}>
              <IconLogout />
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={anchorElUser}
        anchorElUser={anchorElUser}
        handleCloseUserMenu={handleCloseUserMenu}
      />
    </>
  );
};
export default Header;
