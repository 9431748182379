import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommandId from '@/hooks/useUserCommandId';
import usePagination from '@/hooks/usePagination';
import useLocalizeConsumableCategory from '@/hooks/useLocalizeConsumableCategory';

import {
  ApiConsumableCategory,
  ConsumableCategoryStatus,
} from '@/services/consumableCategories/consumableCategories.types';
import {
  useArchiveConsumableCategoryByIdMutation,
  useGetConsumableCategoriesQuery,
  useUnArchiveConsumableCategoryByIdMutation,
} from '@/services/consumableCategories/consumableCategories';
import ModalConsumableCategory from '@/modals/ModalConsumableCategory/ModalConsumableCategory';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';

const ConsumableCategoriesPage = () => {
  const { t } = useTranslation(['common', 'consumables']);

  const { localizeConsumableCategory } = useLocalizeConsumableCategory();

  const { isMaster } = useUserInfo();

  const [archiveConsumableCategory, { isLoading: isArchiving }] =
    useArchiveConsumableCategoryByIdMutation();

  const [unArchiveConsumableCategory, { isLoading: isUnArchiving }] =
    useUnArchiveConsumableCategoryByIdMutation();

  const commandId = useUserCommandId();

  const {
    consumableCategories: categories,
    isLoading,
    setPage,
    setLimit,
    getPagination,
  } = usePagination({
    cacheKey: 'consumableCategories',
    useQuery: useGetConsumableCategoriesQuery,
    queryArg: { commandId },
  });

  const openCreateCategoryModal = () => {
    ModalConsumableCategory.show();
  };

  const openEditCategoryModal = (category: ApiConsumableCategory) => () => {
    ModalConsumableCategory.show(category);
  };

  const handleCategoryArchive = useCallback(
    ({ id, name }: ApiConsumableCategory) =>
      () => {
        ModalConfirm.show({
          onlyContinue: true,
          continueLabel: t('common:buttonActions.archive'),
          title: t('common:notifications.confirmActionOnPage'),
          subTitle: t('consumables:confirmArchiveCategory', { name }),
          onContinue: () => archiveConsumableCategory({ id, commandId: commandId! }),
        });
      },
    [archiveConsumableCategory, commandId, t],
  );

  const handleCategoryUnArchive = useCallback(
    ({ id }: ApiConsumableCategory) =>
      () => {
        unArchiveConsumableCategory({ id, commandId: commandId! });
      },
    [commandId, unArchiveConsumableCategory],
  );

  const config: TableConfig = useMemo(() => {
    const list: ApiConsumableCategory[] = categories?.list || [];

    return list.map((a, i) => {
      const isArchived = a.status === ConsumableCategoryStatus.ARCHIVED;

      const buttonsConfig: TableRowActionsProps['buttonsConfig'] = [];

      const actionProps: TableRowActionsProps = isMaster
        ? {}
        : {
            isIconEdit: true,
            onEdit: openEditCategoryModal(a),
            buttonsConfig,
          };
      if (isArchived) {
        actionProps.onUnarchive = handleCategoryUnArchive(a);
      } else {
        actionProps.onArchive = handleCategoryArchive(a);
      }
      return {
        id: a.id,
        data: [
          {
            label: 'ID',
            value: i + 1,
            isHiddenLabel: true,
          },
          {
            label: t('common:tableLabels.category'),
            value: a.name,
          },
          {
            label: t('common:tableLabels.status'),
            value: localizeConsumableCategory(a.status),
          },
          {
            label: 'Actions',
            isHiddenLabel: true,
            value: <TableRowActions {...actionProps} />,
          },
        ],
      };
    });
  }, [
    categories?.list,
    handleCategoryArchive,
    handleCategoryUnArchive,
    isMaster,
    localizeConsumableCategory,
    t,
  ]);

  return (
    <>
      <PageTitle>{t('common:sidebar.consumableCategories')}</PageTitle>

      <PageHeaderContent
        label={t('common:tableLabels.category')}
        onClick={openCreateCategoryModal}
      />

      <Table
        isAutoWidth
        config={config}
        isLoading={isLoading || isArchiving || isUnArchiving}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        pagination={getPagination(categories?.meta)}
      />
    </>
  );
};

export default ConsumableCategoriesPage;
