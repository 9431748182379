import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { ListItem, Typography } from '@mui/material';

import {
  StyledSidebarItemSimpleDeep,
  StyledSidebarItemSimpleDeepPopper,
} from '@/components/SidebarItemSimple/SidebarItemSimpleStyled';
import SidebarItemIcon from '@/components/SidebarItemIcon';

import { usePermissionsGranted } from '@/hooks/usePermissionGranted';
import useActiveRoute from '@/hooks/useActiveRoute';

import { SidebarItemWithoutItemsProps } from '@/types/sidebar';

const SidebarItemSimple = ({
  name,
  path,
  icon,
  style,
  isDeep,
  onClick,
  isHidden,
  isDeepPopper,
  ...restProps
}: SidebarItemWithoutItemsProps) => {
  const items = useMemo(() => [{ path }], [path]);
  const linkProps = useMemo(() => {
    if (!path) return {};
    return { component: Link, to: path };
  }, [path]);

  const isActiveRoute = useActiveRoute(items);
  const isAvailable = usePermissionsGranted(restProps.permissions);

  if (!isAvailable || isHidden) return null;

  if (isDeep) {
    if (isDeepPopper) {
      return (
        <StyledSidebarItemSimpleDeepPopper
          $isActive={isActiveRoute}
          onClick={onClick}
          variant='subtitle2'
          {...linkProps}
        >
          {name}
        </StyledSidebarItemSimpleDeepPopper>
      );
    }

    return (
      <StyledSidebarItemSimpleDeep $isActive={isActiveRoute}>
        <Typography variant='subtitle2' {...linkProps}>
          {name}
        </Typography>
      </StyledSidebarItemSimpleDeep>
    );
  }

  return (
    <ListItem
      button
      onClick={onClick}
      style={style}
      {...linkProps}
      className={restProps.className}
      sx={{ marginBottom: '20px' }}
    >
      {icon && <SidebarItemIcon $isActive={isActiveRoute}>{icon}</SidebarItemIcon>}
      <Typography variant='body1' sx={isActiveRoute ? { color: 'primary.main' } : undefined}>
        {name}
      </Typography>
    </ListItem>
  );
};

export default SidebarItemSimple;
