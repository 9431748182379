import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { TableWithSettings } from '@/types/table';

const excludedOfferColumns = ['Income', 'Roi', 'Romi'];

const useLocalizeTableColumns = (tableName: TableWithSettings) => {
  const { t } = useTranslation(['common', 'offer']);

  const localizeTableColumns = useCallback(
    (columnId: string) => {
      if (tableName === TableWithSettings.OFFERS) {
        const isOfferColumnName = excludedOfferColumns.some((a) => columnId.includes(a));
        const namespace = isOfferColumnName
          ? `offer:category.${columnId}`
          : `common:tableLabels.${columnId}`;
        // @ts-ignore
        return t(namespace);
      }
    },
    [t, tableName],
  );

  return { localizeTableColumns };
};

export default useLocalizeTableColumns;
