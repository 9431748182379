import moment from 'moment';
import { ChartData, DefaultDataPoint } from 'chart.js';

import { PossibleStatisticCategories, statisticCategories } from '@/resources/constantsStatistic';
import { dateFormatCommon } from '@/resources/constants';

import useGetParameterColor from '@/hooks/useGetParameterColor';

import { ApiDashboardCommonStatistics } from '@/services/dashboard/dashboard.types';

const useChartJs = (
  isSecondGraphic = false,
  parameters: PossibleStatisticCategories[],
  dates?: ApiDashboardCommonStatistics[],
): ChartData<'line', DefaultDataPoint<'line'>, string> => {
  const { getParameterColor } = useGetParameterColor();

  if (!dates) return { datasets: [] };

  const labels = dates.map((a) => a.date).sort();

  const dataConfiguration = (value: PossibleStatisticCategories) => {
    return labels.map((label) => {
      const currentDate = dates.find((a) => a.date === label);
      return currentDate ? currentDate[value] : 0;
    });
  };

  const datasets = parameters
    .filter((a) => statisticCategories.includes(a))
    .map((a) => {
      const category = a as PossibleStatisticCategories;
      const color = getParameterColor(category, isSecondGraphic);

      return {
        borderColor: color,
        backgroundColor: color,
        data: dataConfiguration(category),
      };
    });

  return {
    labels: labels.map((a) => moment(a).format(dateFormatCommon)),
    datasets: datasets.filter((a) => a.data.some(Boolean)),
  };
};

export default useChartJs;
