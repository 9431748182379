import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import Select from '@/components/Select';

import useHighLevelUserCommands from '@/hooks/useHighLevelUserCommands';
import useFilterProps from '@/hooks/useFilterProps';
import { SelectValue } from '@/hooks/useControlSelectValue';

import { NewType } from '@/types/filters';
import useUserCommandsFilter from '@/filters/useUserCommandsFilter';

const FilterUserCommand = <Multiple extends boolean = false>(
  props: NewType<Multiple> & { queryKey?: string },
) => {
  const { t } = useTranslation();

  const filterProps = useFilterProps(props);

  const { commands } = useHighLevelUserCommands();
  const { commandId, setCommandId } = useUserCommandsFilter(props.queryKey);

  const handleValueSelect = useCallback(
    <Multiple extends boolean>(value: SelectValue<Multiple>) =>
      setCommandId(value as SelectValue<false>, 'replaceIn', true),
    [setCommandId],
  );

  return (
    <Select
      {...filterProps}
      isClearable
      isControlFromProp
      label={t('roles.team')}
      options={commands}
      onSelect={handleValueSelect}
      value={commandId as unknown as SelectValue<Multiple>}
    />
  );
};

export default FilterUserCommand;
