import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import CompanyFinancier from '@/components/CompanyFinancier';
import Button from '@/components/Button';

import IconPlus from '@/icons/IconPlus';

import routes from '@/resources/routes';

import { getUserNickname } from '@/utils/getUserNickname';

import useUserInfo from '@/hooks/useUserInfo';
import usePagination from '@/hooks/usePagination';

import { ApiCommand } from '@/services/commands/commands.types';
import { useGetCommandsQuery } from '@/services/commands/commands';
import { ApiUserRole } from '@/services/auth/auth.types';
import ModalInvite from '@/modals/ModalInvite/ModalInvite';
import ModalCompany from '@/modals/ModalCompany/ModalCompany';
import ModalCommand from '@/modals/ModalCommand/ModalCommand';

const CommandsPage = () => {
  const { t } = useTranslation(['common', 'command']);

  const history = useHistory();

  const { isOwner } = useUserInfo();

  const { commands, isLoading, setPage, setLimit, getPagination } = usePagination({
    cacheKey: 'commands',
    useQuery: useGetCommandsQuery,
  });

  const openCreateCommandModal = () => {
    ModalCommand.show();
  };

  const openInviteModal = (command: ApiCommand) => () => {
    ModalInvite.show(command);
  };

  const openEditCommandModal = (command: ApiCommand) => () => {
    ModalCommand.show(command);
  };

  const openEditCompanyModal = () => {
    ModalCompany.show();
  };

  const config: TableConfig = useMemo(() => {
    const list: ApiCommand[] = commands?.list || [];

    return list.map((a, i) => {
      const actionProps: TableRowActionsProps = {
        isIconEdit: true,
        onEdit: openEditCommandModal(a),
        buttonsConfig: [
          {
            textForButton: t('common:roles.employee'),
            onClick: openInviteModal(a),
            startIcon: <IconPlus />,
          },
        ],
      };

      return {
        id: a.id,
        onRowClick: () => history.push(routes.commandById.link(a.id)),
        data: [
          {
            label: 'ID',
            value: i + 1,
            isHiddenLabel: true,
          },
          {
            label: t('common:formLabels.commandName'),
            value: a.name,
          },
          {
            label: t('common:sidebar.employees'),
            value: a.users.length,
          },
          {
            label: t('common:roles.lead'),
            value:
              a.users
                .filter(({ role }) => role === ApiUserRole.LEAD_USER)
                .map((a) => getUserNickname(a))
                .join(', ') || t('command:absent'),
          },
          {
            label: 'Actions',
            isHiddenLabel: true,
            value: <TableRowActions {...actionProps} />,
          },
        ],
      };
    });
  }, [commands?.list, history, t]);

  return (
    <>
      <PageTitle>{t('common:sidebar.commands')}</PageTitle>

      <PageHeaderContent label={t('common:sidebar.commands')} onClick={openCreateCommandModal}>
        {isOwner && (
          <Button variant='outlined' size='large' onClick={openEditCompanyModal}>
            {t('command:editCompany')}
          </Button>
        )}
      </PageHeaderContent>

      <CompanyFinancier />

      <Table
        isAutoWidth
        config={config}
        isLoading={isLoading}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        pagination={getPagination(commands?.meta)}
      />
    </>
  );
};

export default CommandsPage;
