export interface TrackerFormProps {
  isChecked: boolean;
  onChangeState: () => void;
}

export enum TrackerFormValues {
  SERVICE = 'service',
  API_URL = 'trackerApiUrl', // FormModalCompanyValues.TRACKER_API_URL
  API_KEY = 'trackerApiKey', // FormModalCompanyValues.TRACKER_API_KEY
}
