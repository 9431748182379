import { debounce } from 'throttle-debounce';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { TextFieldProps, IconButton, TextField, styled } from '@mui/material';

import { IconSize } from '@/components/Icon/IconTypes';

import IconClose from '@/icons/IconClose';
import IconCalendar from '@/icons/IconCalendar';

import { dateFormatCommon, dateInputFormat, DATE_REGEXP } from '@/resources/constants';

import { HookComponentProps } from '@/filters/usePageFilter';
import useFromToFilter from '@/filters/useFromToFilter';

type DateKey = 'from' | 'to';

const StyledDatePicker = styled(DatePicker)`
  & .MuiInputBase-adornedEnd {
    padding-right: 0px;
  }
` as typeof DatePicker;

const FilterFromTo = ({ maxWidth, isClearable }: HookComponentProps) => {
  const { t } = useTranslation();

  const { to, from, setTo, setFrom } = useFromToFilter();

  const handleValueClear = useCallback(
    (key: DateKey) => () => {
      const method = key === 'from' ? setFrom : setTo;
      method('', 'replaceIn', true);
    },
    [setFrom, setTo],
  );

  const shouldDisableDate = useCallback(
    (key: DateKey) => (date: Date | null) => {
      if (!from && key === 'to') return false;
      if (!to && key === 'from') return false;

      return key === 'to'
        ? moment(date).isBefore(moment(from).subtract(1, 'day'))
        : moment(to).isBefore(moment(date));
    },
    [from, to],
  );

  const handleValueSelect = useCallback(
    (key: DateKey) =>
      debounce(500, (result: Date | null) => {
        const newValue = result === null ? '' : moment(result).format(dateFormatCommon);

        const isInvalidDate = Boolean(newValue.replace(DATE_REGEXP, ''));
        const isDisabledDate = shouldDisableDate(key)(result);

        if (isInvalidDate || isDisabledDate) return;

        const method = key === 'from' ? setFrom : setTo;
        method(newValue, 'replaceIn', true);
      }),
    [shouldDisableDate, setFrom, setTo],
  );

  const getInputProps = useCallback(
    (
      params: any,
      isClearable = false,
      key: 'from' | 'to',
      value: string | null,
    ): TextFieldProps['InputProps'] => {
      return {
        ...params.InputProps,
        endAdornment: (
          <>
            {isClearable && value && (
              <IconButton onClick={handleValueClear(key)}>
                <IconClose size={IconSize.s} />
              </IconButton>
            )}
            {params?.InputProps?.endAdornment}
          </>
        ),
      };
    },
    [handleValueClear],
  );

  const renderPicker = useCallback(
    (key: 'from' | 'to') => {
      const val = key === 'to' ? to : from;
      const value = val || (null as any);

      const label = key === 'to' ? t('formLabels.to') : t('formLabels.from');
      const components = { OpenPickerIcon: IconCalendar };

      return (
        <StyledDatePicker
          label={label}
          value={value}
          inputFormat={dateInputFormat}
          onChange={handleValueSelect(key)}
          components={components}
          shouldDisableDate={shouldDisableDate(key)}
          renderInput={({ error, ...params }) => (
            <TextField
              {...params}
              size='small'
              sx={{ maxWidth: maxWidth || '240px' }}
              InputProps={getInputProps(params, isClearable, key, value)}
            />
          )}
        />
      );
    },
    [to, from, t, handleValueSelect, shouldDisableDate, maxWidth, getInputProps, isClearable],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {renderPicker('from')}
      {renderPicker('to')}
    </LocalizationProvider>
  );
};

export default FilterFromTo;
