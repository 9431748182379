import useQueryParam from '@/hooks/useQueryParam';

const useUserCommandsFilter = (key = '') => {
  const queryName = `commandId${key}`;
  const [commandId, setCommandId] = useQueryParam(queryName, '');

  return {
    setCommandId,
    commandId: commandId || undefined,
  };
};

export default useUserCommandsFilter;
