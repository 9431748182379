import { useTranslation } from 'react-i18next';
import { DialogActions, Stack } from '@mui/material';

import Typography from '@/components/Typography';
import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import { getUserNickname } from '@/utils/getUserNickname';
import FormRuler from '@/utils/FormRuler';

import useUserCommandId from '@/hooks/useUserCommandId';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { useConsumableRefundMutation } from '@/services/consumables/consumables';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalConsumableReturnData,
  FormModalConsumableReturnValues,
  ModalConsumableReturnProps,
} from '@/modals/ModalConsumableReturn/ModalConsumableReturnTypes';

const ModalConsumableReturn: StaticModalWrappedComponent<ModalConsumableReturnProps> = (props) => {
  const { t } = useTranslation();

  const commandId = useUserCommandId();
  const [consumableRefund, { isLoading }] = useConsumableRefundMutation();

  const [handleSubmit] = useAwaitCallback(
    async (data: FormModalConsumableReturnData) => {
      if (!props.data) return;

      const response = await consumableRefund({
        commandId,
        date: data.date,
        amount: Number(data.amount),
        id: props.data.consumableId,
        userId: props.data.distribution.user.id,
      });

      if ('data' in response) {
        props.onClose();
      }
    },
    [commandId, consumableRefund, props],
  );

  if (!props.data) {
    return null;
  }

  const { amount, amountOfUsed, amountOfAvailable, user, lastDistributeDate } =
    props.data.distribution;

  const userNickname = getUserNickname(user || {});

  return (
    <Modal {...props} title={t('formLabels.refund')}>
      <Form onSubmit={handleSubmit}>
        <Stack direction='column' spacing={4}>
          <Stack direction='row' justifyContent='space-between'>
            <Stack direction='column' spacing={2}>
              <Typography color='text.secondary'>{userNickname}</Typography>
              <Typography color='primary.main'>
                {t('tableLabels.amountOfAvailable')}: {amount - amountOfUsed}
              </Typography>
            </Stack>
            <Typography color='text.secondary'>{props.data.category.name}</Typography>
          </Stack>

          <FormElement
            isNumber
            component='input'
            label={t('formLabels.quantity')}
            name={FormModalConsumableReturnValues.AMOUNT}
            rules={{
              ...FormRuler.required,
              max: amountOfAvailable,
            }}
          />

          <FormElement
            label={t('tableLabels.date')}
            component='datepicker'
            minDate={lastDistributeDate}
            name={FormModalConsumableReturnValues.DATE}
            rules={{
              ...FormRuler.required,
              ...FormRuler.validateDate(lastDistributeDate, false, 'day'),
            }}
          />
        </Stack>
        <DialogActions>
          <Button type='submit' variant='contained' isLoading={isLoading}>
            {t('buttonActions.save')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ModalConsumableReturnProps>(ModalConsumableReturn);
