import { useTranslation } from 'react-i18next';
import { MouseEvent, useMemo, useCallback, PropsWithChildren } from 'react';
import { FormControlLabel, Portal, Stack, Checkbox } from '@mui/material';

import Button from '@/components/Button';

import IconPlus from '@/icons/IconPlus';

import { HEADER_ADDITIONAL_CONTENT_ID } from '@/resources/constants';

import useUserInfo from '@/hooks/useUserInfo';
import useQueryParam from '@/hooks/useQueryParam';

interface PageHeaderContentProps {
  label?: string;
  onClick?: () => void;
  haveCheckbox?: boolean;
}

const PageHeaderContent = (props: PropsWithChildren<PageHeaderContentProps>) => {
  const { t } = useTranslation();

  const { label = t('buttonActions.add'), onClick, haveCheckbox = false, children } = props;

  const { isMaster } = useUserInfo();
  const selector = document.getElementById(HEADER_ADDITIONAL_CONTENT_ID);
  const [hideUsed, setQuery] = useQueryParam<string>('hideUsed', '');

  const handleChangeQuery = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      // @ts-ignore
      setQuery(String(event.target.checked));
    },
    [setQuery],
  );

  const config = useMemo(() => {
    const commonConfig = (
      <Stack direction='row' spacing={3}>
        {children}

        {onClick && (
          <Button
            size='large'
            variant='outlined'
            startIcon={<IconPlus color='#2374EE' />}
            onClick={onClick}
          >
            {label}
          </Button>
        )}
      </Stack>
    );

    return haveCheckbox ? (
      <Stack direction='row' width='100%' justifyContent='space-between'>
        <FormControlLabel
          control={
            <Checkbox
              onClick={handleChangeQuery}
              checked={hideUsed === 'true'}
              style={{ cursor: 'pointer' }}
            />
          }
          labelPlacement='end'
          label={isMaster ? t('hideUsed') : t('hideDistributed')}
        />

        {commonConfig}
      </Stack>
    ) : (
      commonConfig
    );
  }, [children, handleChangeQuery, haveCheckbox, hideUsed, isMaster, label, onClick, t]);

  if (!selector) return null;

  return <Portal container={selector}>{config}</Portal>;
};

export default PageHeaderContent;
