import { useTranslation } from 'react-i18next';
import React from 'react';

import Select from '@/components/Select';
import { ChartParametersSelectorProps } from '@/components/ChartParametersSelector/ChartParametersSelectorTypes';

import { CHART_COLORS_RIGHT, CHART_COLORS_LEFT } from '@/resources/constants';

import useStatisticCategoriesOptions from '@/hooks/useStatisticCategoriesOptions';
import { SelectValue } from '@/hooks/useControlSelectValue';

const ChartParametersSelector = React.memo((props: ChartParametersSelectorProps) => {
  const { t } = useTranslation('dashboard');

  const { statisticCategoriesOptions } = useStatisticCategoriesOptions();

  const setParameters = (value: SelectValue<true>) => {
    const newValue = value.join(',');
    props.setQuery(newValue, 'replaceIn', true);
  };

  return (
    <Select<true>
      fullWidth
      isMultiple
      size='small'
      disableAutofill
      label={t('parameters')}
      value={props.parameters}
      onSelect={setParameters}
      options={statisticCategoriesOptions}
      checkboxColors={props.isSecondGraphic ? CHART_COLORS_RIGHT : CHART_COLORS_LEFT}
    />
  );
});

export default ChartParametersSelector;
