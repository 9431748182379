import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { css, DialogActions, styled } from '@mui/material';

import Modal from '@/components/Modal';
import { ElementObject } from '@/components/FormElementConsumablePicker/FormElementConsumablePickerTypes';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import { FileUploadTypes } from '@/resources/constants';

import transientProps from '@/utils/transientProps';
import FormRuler from '@/utils/FormRuler';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommandId from '@/hooks/useUserCommandId';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { ApiOperationPostBody, OperationMemberType } from '@/services/operations/operations.types';
import { useCreateOperationMutation } from '@/services/operations/operations';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalExpenseOperationData,
  FormModalExpenseOperationValues,
  MembersModelCustom,
} from '@/modals/ModalExpenseOperation/ModalExpenseOperationTypes';

const StyledFormElement = styled(FormElement, transientProps)<{ $haveMargin?: boolean }>`
  margin-top: 24px !important;
  width: fit-content;
  ${({ $haveMargin }) =>
    $haveMargin &&
    css`
      margin-bottom: 24px !important;
    `}
`;

const ModalExpenseOperation: StaticModalWrappedComponent<ApiOperationPostBody> = (props) => {
  const { t } = useTranslation(['common', 'operations']);

  const { isMaster, isOwner, isMultipleAccessToCommandUser } = useUserInfo();
  const commandId = useUserCommandId();

  const methods = useForm<FormModalExpenseOperationData>();
  const dateOfOperation = methods.watch(FormModalExpenseOperationValues.DATE);

  const [createOperations, { isLoading }] = useCreateOperationMutation();

  const convertMembers = (members: ElementObject<MembersModelCustom>[]) => {
    const data = members
      .filter((a) => Number(a.value?.percent) > 0)
      .map((a) => ({
        type: a.value!.type,
        percent: a.value!.percent,
        memberId: a.value!.memberId,
      }));

    const calculatedPercent = data.length
      ? data.reduce((prev, current) => prev + Number(current.percent), 0)
      : 100;

    if (calculatedPercent !== 100) {
      data.push({
        memberId: commandId!,
        type: OperationMemberType.COMMAND,
        percent: String(100 - calculatedPercent),
      });
    }

    return data.map((a) => ({ id: a.memberId, percent: Number(a.percent), type: a.type }));
  };

  const [handleSubmit] = useAwaitCallback(
    async ({ members, ...data }: FormModalExpenseOperationData) => {
      const newMembers = !isMaster ? convertMembers(members) : [];

      const body: ApiOperationPostBody = {
        commandId: isMultipleAccessToCommandUser ? commandId : undefined,
        name: data[FormModalExpenseOperationValues.NAME],
        date: data[FormModalExpenseOperationValues.DATE],
        sum: data[FormModalExpenseOperationValues.SUM_WITH_CURRENCY].systemSum,
        comment: data[FormModalExpenseOperationValues.COMMENT],
        receiptId: data[FormModalExpenseOperationValues.RECEIPT_ID],
        currency: data[FormModalExpenseOperationValues.SUM_WITH_CURRENCY].currency,
        currencySum: Number(data[FormModalExpenseOperationValues.SUM_WITH_CURRENCY].currencySum),
      };

      const response = isMaster
        ? await createOperations(body)
        : await createOperations({ ...body, members: newMembers });

      if ('data' in response) {
        props.onClose();
      }
    },
    [commandId, convertMembers, createOperations, isOwner, props],
  );

  return (
    <Modal {...props} title={t('operations:addOperation')}>
      <Form onSubmit={handleSubmit} contextMethods={methods}>
        <FormElement
          component='input'
          label={t('common:tableLabels.name')}
          name={FormModalExpenseOperationValues.NAME}
          rules={FormRuler.requiredInput}
        />
        <FormElement
          isDecimalNumber
          dateOfOperation={dateOfOperation}
          label={t('common:tableLabels.sum')}
          component='sumWithCurrency'
          name={FormModalExpenseOperationValues.SUM_WITH_CURRENCY}
          rules={FormRuler.validateSumWithCurrency}
        />
        <FormElement
          component='datepicker'
          label={t('common:tableLabels.date')}
          name={FormModalExpenseOperationValues.DATE}
          maxDate={new Date().toISOString()}
          rules={FormRuler.required}
        />
        <FormElement
          component='input'
          label={t('common:tableLabels.comment')}
          name={FormModalExpenseOperationValues.COMMENT}
        />
        <StyledFormElement
          label={t('common:buttonActions.addReceipt')}
          component='fileUpload'
          $haveMargin={!isMaster}
          accept={FileUploadTypes.textOrImage}
          name={FormModalExpenseOperationValues.RECEIPT_ID}
        />
        {!isMaster && (
          <FormElement
            type='operation'
            component='pickerWithSelect'
            name={FormModalExpenseOperationValues.MEMBERS}
          />
        )}
        <DialogActions>
          <Button type='submit' variant='contained' isLoading={isLoading}>
            {t('common:buttonActions.create')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiOperationPostBody>(ModalExpenseOperation);
