import { useEffect } from 'react';
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  QueryDefinition,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommandId from '@/hooks/useUserCommandId';

import { ValueOf } from '@/types';
import { servicesTags } from '@/services';

type TestQuery = {
  userId?: string;
  commandId?: string;
};

type Test<T> = UseLazyQuery<
  QueryDefinition<
    TestQuery | void,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, any, FetchBaseQueryMeta>,
    ValueOf<typeof servicesTags>,
    T
  >
>;

const useUserData = <T>(hook: Test<T>, id?: string) => {
  const { userInfo, isMaster } = useUserInfo();
  const [getData, { data }] = hook();

  const userCommandId = useUserCommandId();

  const userId = isMaster ? userInfo?.id : id;
  const commandId = isMaster ? userInfo?.command.id : userCommandId;

  useEffect(() => {
    if (!userId) return;
    getData({ userId, commandId });
  }, [commandId, getData, userId]);

  return data;
};

export default useUserData;
