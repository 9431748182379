import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { Grid, Stack } from '@mui/material';

import Typography from '@/components/Typography';
import Loader from '@/components/Loader';
import {
  ContentWrapper,
  InfoWrapper,
  StyledButton,
  StyledText,
} from '@/components/CompanyFinancier/CompanyFinancierStyled';
import Button from '@/components/Button';

import IconPlus from '@/icons/IconPlus';

import { getUserNickname } from '@/utils/getUserNickname';

import useUserInfo from '@/hooks/useUserInfo';

import { ApiUser } from '@/services/users/users.types';
import {
  useGetNonCommandUsersQuery,
  useUpdateNonCommandUserByIdMutation,
} from '@/services/nonCommandsUsers/nonCommandsUsers';
import { ApiUserRole } from '@/services/auth/auth.types';
import ModalUser from '@/modals/ModalUser/ModalUser';
import ModalInviteFinancier from '@/modals/ModalInviteFinancier/ModalInviteFinancier';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';

const CompanyFinancier = () => {
  const { t } = useTranslation(['common', 'command']);

  const { userInfo } = useUserInfo();
  const { data: nonCommandUsers, isLoading } = useGetNonCommandUsersQuery({
    companyId: userInfo?.company.id,
  });

  const [archivingUsers, setArchivingUsers] = useState<string[]>([]);
  const [updateUser, { isLoading: isUpdating }] = useUpdateNonCommandUserByIdMutation();

  const { activeFinancier, archivedFinanciers } = useMemo(() => {
    let activeFinancier;
    const archivedFinanciers = [];

    if (nonCommandUsers?.list.length) {
      for (let i = 0; i < nonCommandUsers?.list.length; i++) {
        const user = nonCommandUsers?.list[i];

        if (user.role === ApiUserRole.COMPANY_FINANCIER) {
          if (user.isEnabled) {
            activeFinancier = user;
          } else {
            archivedFinanciers.push(user);
          }
        }
      }
    }

    return {
      activeFinancier,
      archivedFinanciers,
    };
  }, [nonCommandUsers?.list]);

  const openInviteFinancierModal = () => {
    ModalInviteFinancier.show();
  };

  // TODO Требуется рефакторинг
  const toggleArchiveUser = useCallback(
    async (user: ApiUser) => {
      if (!user) return;

      const removeFromArchivingArray = () => {
        setArchivingUsers((prevState) => {
          const newState = [...prevState];
          const index = newState.findIndex((a) => a === user.id);

          if (index !== -1) {
            newState.splice(index, 1);
          }

          return newState;
        });
      };

      setArchivingUsers((prevState) => {
        if (prevState.includes(user.id)) {
          return prevState;
        }

        const newState = [...prevState];
        newState.push(user.id);
        return newState;
      });

      if (!user.isEnabled) {
        await updateUser({ id: user.id, isEnabled: true });
        removeFromArchivingArray();
        return;
      }

      ModalConfirm.show({
        onlyContinue: true,
        continueLabel: t('common:buttonActions.archive'),
        title: t('common:notifications.confirmActionOnPage'),
        subTitle: t('command:confirmArchive', { nickname: getUserNickname(user) }),
        onContinue: async () => {
          await updateUser({ id: user.id, isEnabled: false });
          removeFromArchivingArray();
        },
      });
    },
    [t, updateUser],
  );

  const openEditUserModal = useCallback(() => {
    if (!activeFinancier) return;
    ModalUser.show({ ...activeFinancier, commandId: '' });
  }, [activeFinancier]);

  const textForButton = activeFinancier?.isEnabled
    ? t('common:buttonActions.archive')
    : t('common:buttonActions.unarchive');

  const bannedList = archivedFinanciers.length > 0 && (
    <>
      <div>
        <Typography variant='body1' color='text.secondary'>
          {t('command:companyFinancier.archivedList')}
        </Typography>

        {archivedFinanciers.map((a) => (
          <Grid columnSpacing={2} alignItems='center' container key={a.id}>
            <Grid item>
              <Typography color='text.secondary'>
                {a.email} ({getUserNickname(a)})
              </Typography>
            </Grid>

            <Grid item>
              <StyledButton
                variant='text'
                isLoading={isUpdating && archivingUsers.includes(a.id)}
                onClick={() => toggleArchiveUser(a)}
                color={a.isEnabled ? 'error' : 'primary'}
              >
                {t('common:buttonActions.unarchive')}
              </StyledButton>
            </Grid>
          </Grid>
        ))}
      </div>
    </>
  );

  const activeContent = activeFinancier ? (
    <div>
      <Typography variant='body1' color='text.secondary' sx={{ mb: 2 }}>
        {t('command:companyFinancier.activeUser')}
      </Typography>

      <InfoWrapper>
        <Typography color='text.secondary'>
          <StyledText>{t('common:formLabels.fio')}:</StyledText> {getUserNickname(activeFinancier)}
        </Typography>
        <Typography color='text.secondary'>
          <StyledText>{t('common:sidebar.email')}:</StyledText> {activeFinancier.email}
        </Typography>
      </InfoWrapper>

      <Stack direction='row' spacing={3}>
        <StyledButton onClick={openEditUserModal}>{t('common:buttonActions.edit')}</StyledButton>

        <StyledButton
          isLoading={isUpdating && archivingUsers.includes(activeFinancier.id)}
          onClick={() => toggleArchiveUser(activeFinancier)}
          color={activeFinancier.isEnabled ? 'error' : 'primary'}
        >
          {textForButton}
        </StyledButton>
      </Stack>
    </div>
  ) : (
    <div>
      <Button
        size='large'
        variant='outlined'
        startIcon={<IconPlus color='#2374EE' />}
        onClick={openInviteFinancierModal}
      >
        {t('common:buttonActions.invite')}
      </Button>
    </div>
  );

  return (
    <ContentWrapper>
      <Typography variant='h6' sx={{ fontSize: 18 }}>
        {t('common:roles.companyFinancier')}
      </Typography>

      {isLoading ? (
        <>
          <Loader isStatic size={24} isCenter />
        </>
      ) : (
        <>
          {bannedList}
          {activeContent}
        </>
      )}
    </ContentWrapper>
  );
};

export default CompanyFinancier;
