import React, { useMemo } from 'react';
import LibraryTabs from '@mui/material/Tabs';

import { TabsProps } from '@/components/Tabs/TabsTypes';
import { StyledTabs, StyledTabsTab } from '@/components/Tabs/TabsStyled';

const Tabs = ({ value, setValue, options, className }: TabsProps) => {
  const handleChange = (_event: React.SyntheticEvent, value: number) => {
    setValue(value);
  };

  const renderOptions = useMemo(() => {
    return options.map((item, index) => <StyledTabsTab key={index} label={item} />);
  }, [options]);

  return (
    <StyledTabs sx={{ width: '100%' }} className={className}>
      <LibraryTabs onChange={handleChange} value={value} selectionFollowsFocus>
        {renderOptions}
      </LibraryTabs>
    </StyledTabs>
  );
};

export default Tabs;
