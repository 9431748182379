import { useSelector } from 'react-redux';

import useUserInfo from '@/hooks/useUserInfo';

import { selectCommandId } from '@/redux/user/userGetters';

// NOTE
// Получение commandId пользователя

const useUserCommandId = (): string | undefined => {
  const commandId = useSelector(selectCommandId);
  const { isMultipleAccessToCommandUser, userInfo } = useUserInfo();

  return (isMultipleAccessToCommandUser ? commandId : userInfo?.command?.id) || undefined;
};

export default useUserCommandId;
