import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommandId from '@/hooks/useUserCommandId';

import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import useArchivedFilter from '@/filters/useArchivedFilter';

interface Base {
  id: string;
  name: string;
  isArchived: boolean;
}

type Params = { id: Base['id']; commandId: string };

function useArchiveHelpers<T extends Base>(
  onChangeArchiveStatus: (params: Params) => void,
  getArchiveConfirmationDescription: (name: string) => string,
) {
  const { isHighLevelCommandUser } = useUserInfo();
  const { t } = useTranslation();

  const commandId = useUserCommandId();
  const { archived: isArchived } = useArchivedFilter();

  const getQueryParams = useCallback(
    (item: T) => {
      return { id: item.id, commandId: commandId! };
    },
    [commandId],
  );
  const openArchiveConfirmation = useCallback(
    (item: T) => {
      ModalConfirm.show({
        onlyContinue: true,
        continueLabel: t('buttonActions.archive'),
        title: t('notifications.confirmActionOnPage'),
        subTitle: getArchiveConfirmationDescription(item.name),
        onContinue: () => onChangeArchiveStatus(getQueryParams(item)),
      });
    },
    [getQueryParams, getArchiveConfirmationDescription, onChangeArchiveStatus, t],
  );
  const prepareArchiveColumns = useCallback(
    (item: T, actionProps: TableRowActionsProps) => {
      if (isHighLevelCommandUser) {
        if (item.isArchived) {
          actionProps.onUnarchive = () => onChangeArchiveStatus(getQueryParams(item));
          if ('onEdit' in actionProps) {
            actionProps.onEdit = undefined;
          }
          if ('onRefresh' in actionProps) {
            actionProps.onRefresh = undefined;
          }
          actionProps.buttonsConfig?.map((i) => (i.onClick = false));
        } else {
          actionProps.onArchive = () => openArchiveConfirmation(item);
        }
      }
    },
    [getQueryParams, isHighLevelCommandUser, onChangeArchiveStatus, openArchiveConfirmation],
  );
  return { isArchived, prepareArchiveColumns };
}

export default useArchiveHelpers;
