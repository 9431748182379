/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconArrow = ({ size = IconSize.l, color = '#CCCDD3', ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 24 24'>
      <path
        d='M3 12L8.58537 6M3 12L8.58537 18M3 12H21'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};

export default IconArrow;
