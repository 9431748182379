import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import PageFilters from '@/components/PageFilters';
import LeadsInfo from '@/components/LeadsInfo';
import Button from '@/components/Button';

import IconRefresh from '@/icons/IconRefresh';

import { getUserNickname } from '@/utils/getUserNickname';
import filterBoolean from '@/utils/filterBoolean';
import Formatter from '@/utils/Formatter';
import Alerter, { AlerterTypes } from '@/utils/Alerter/Alerter';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCompanyInfo from '@/hooks/useUserCompanyInfo';
import useUserCommandId from '@/hooks/useUserCommandId';
import usePagination from '@/hooks/usePagination';

import { ApiLead } from '@/services/leads/leads.types';
import {
  useDeleteLeadByIdMutation,
  useGetLeadsQuery,
  useSynchronizationLeadsMutation,
} from '@/services/leads/leads';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import useStatusFilter from '@/filters/useStatusFilter';
import useMasterFilter from '@/filters/useMasterFilter';
import useFromToFilter from '@/filters/useFromToFilter';
import FilterMaster from '@/filters/FilterMaster';
import FilterLeadStatus from '@/filters/FilterLeadStatus';
import FilterFromTo from '@/filters/FilterFromTo';

const LeadsPage = () => {
  const { t } = useTranslation(['common', 'leads']);

  const { masterId } = useMasterFilter(undefined, true);
  const { to, from } = useFromToFilter();
  const { status } = useStatusFilter();

  const commandId = useUserCommandId();
  const { selfhost } = useUserCompanyInfo();
  const { isOwner, isMaster } = useUserInfo();

  const {
    leads: data,
    isLoading,
    setPage,
    setLimit,
    getPagination,
  } = usePagination({
    cacheKey: 'leads',
    useQuery: useGetLeadsQuery,
    queryArg: {
      to,
      from,
      commandId,
      'statuses[]': status,
      'userIds[]': masterId || undefined,
    },
  });

  const [synchronization, { isLoading: isSynchronization }] = useSynchronizationLeadsMutation();
  const [deleteLeadById, { isLoading: isDeleting }] = useDeleteLeadByIdMutation();

  const onLeadsSynchronization = async () => {
    const response = await synchronization();

    if ('data' in response) {
      Alerter.show(t('common:notifications.synchronized'), AlerterTypes.success);
    }
  };

  const onLeadsDelete = useCallback(
    (id: string) => async () => {
      ModalConfirm.show({
        onlyContinue: true,
        onContinue: async () => {
          const response = await deleteLeadById({ id });

          if ('data' in response) {
            Alerter.show(t('common:notifications.deleted'), AlerterTypes.success);
          }
        },
      });
    },
    [deleteLeadById, t],
  );

  const config: TableConfig = useMemo(() => {
    const list: ApiLead[] = data?.list || [];

    const { page, limit } = getPagination(data?.meta);
    const skip = page * limit - limit;

    return list.map((a, i) => {
      const actionsProps: TableRowActionsProps = isOwner
        ? { onDelete: onLeadsDelete(a.id), isIconDelete: true, isIconLoading: isDeleting }
        : {};

      return {
        id: a.id,
        data: [
          {
            label: '',
            value: skip + i + 1,
            isHiddenLabel: true,
          },
          {
            label: t('leads:table.date'),
            value: Formatter.formatDate(a.date),
          },
          {
            label: t('leads:table.lastName'),
            value: a.clientLastName,
          },
          {
            label: t('leads:table.firstName'),
            value: a.clientFirstName,
          },
          {
            label: t('leads:table.phone'),
            value: a.phone,
          },
          {
            label: t('leads:table.master'),
            value: getUserNickname(a.user),
          },
          {
            label: t('leads:table.offer'),
            value: a.offer,
          },
          {
            label: t('leads:table.status'),
            value: a.status,
          },
          {
            label: '',
            value: <TableRowActions {...actionsProps} />,
          },
        ].filter(filterBoolean),
      };
    });
  }, [data?.list, data?.meta, getPagination, isOwner, onLeadsDelete, isDeleting, t]);

  return (
    <>
      <PageTitle>{t('common:sidebar.leads')}</PageTitle>

      {!isMaster && selfhost && (
        <PageHeaderContent>
          <Button
            size='large'
            variant='outlined'
            isLoading={isSynchronization}
            onClick={onLeadsSynchronization}
            startIcon={<IconRefresh color='#2374EE' />}
          >
            {t('common:buttonActions.synchronize')}
          </Button>
        </PageHeaderContent>
      )}

      <PageFilters>
        <FilterLeadStatus disableAutofill />
        {!isMaster && <FilterMaster disableAutofill />}
        <FilterFromTo isClearable />
      </PageFilters>

      {Boolean(config.length) && !isMaster && <LeadsInfo />}

      <Table
        isAutoWidth
        config={config}
        isLoading={isLoading}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        pagination={getPagination(data?.meta)}
      />
    </>
  );
};

export default LeadsPage;
