import { useTranslation } from 'react-i18next';
import { useMemo, useState, useCallback } from 'react';

import { TableConfig, TableRowConfig } from '@/components/Table/TableTypes';

import { PossibleStatisticCategories, statisticCategories } from '@/resources/constantsStatistic';
import { sortByDate } from '@/resources/constants';

import { getUserNickname } from '@/utils/getUserNickname';
import filterUnique from '@/utils/filterUnique';
import filterBoolean from '@/utils/filterBoolean';
import Formatter from '@/utils/Formatter';
import CorrectMath from '@/utils/CorrectMath';

import useUserInfo from '@/hooks/useUserInfo';
import useStatisticData from '@/hooks/useStatisticData';
import useLocalizeStatisticCategory from '@/hooks/useLocalizeStatisticCategory';
import useGetFormattedStatisticValue from '@/hooks/useGetFormattedStatisticValue';

import { ApiUser } from '@/services/users/users.types';
import {
  ApiDashboardCommonStatistics,
  ApiDashboardData,
  ApiDashboardStatistic,
  ApiDashboardStatisticMember,
  PossibleStatisticCategoriesDto,
} from '@/services/dashboard/dashboard.types';
import { ApiCommand } from '@/services/commands/commands.types';

interface ConfigDataValues {
  date?: string;
  command?: string;
  master?: string | false;
  statistic?: Partial<PossibleStatisticCategoriesDto>;
}

type ExcludedApiDashboardKeys = Exclude<keyof ApiDashboardData, PossibleStatisticCategories>;
const excludedKeys: ExcludedApiDashboardKeys[] = ['command', 'date', 'isEmpty', 'members', 'user'];

const isPossibleStatisticCategory = (value: string): value is PossibleStatisticCategories => {
  return (excludedKeys as string[]).indexOf(value) === -1;
};

const useDashboardConfig = () => {
  const { t } = useTranslation();

  const { isMaster, isOwner } = useUserInfo();
  const [hasExpandedColumns, toggleExpandedColumnsState] = useState(false);

  const { localizeStatisticCategory } = useLocalizeStatisticCategory();
  const { getFormattedStatisticValue } = useGetFormattedStatisticValue();

  const data1 = useStatisticData();
  const data2 = useStatisticData(true);

  const isShowCommandColumn = Boolean(isOwner && !data1.isEqualGraphicCommands);

  const isShowMasterColumn =
    !isMaster && Boolean(data1.masterId || data2.masterId || hasExpandedColumns);

  const displayedColumns = useMemo(
    () => data1.parameters.filter((a) => statisticCategories.includes(a)),
    [data1.parameters],
  );

  const isDisplayStatistics = useCallback(
    (statistic: Partial<PossibleStatisticCategoriesDto & { isEmpty: boolean }>) => {
      if (statistic.isEmpty) return false;
      return displayedColumns.some((a) => statistic[a] !== 0);
    },
    [displayedColumns],
  );

  const generateRowId = useCallback(
    (
      base: 'row' | 'expanded-user' | 'expanded-command' | 'total',
      date: string,
      command: ApiCommand,
      user?: Omit<ApiUser, 'command'>,
    ) => {
      let result = `${base}-${date}`;
      if (user) result += `-${user?.id}`;
      if (command) result += `-${command?.id}`;

      return result;
    },
    [],
  );

  const getPreparedGroupedData = useCallback((data: ApiDashboardData[]) => {
    const uniqueDays = data.map((a) => a.date).filter(filterUnique);

    return uniqueDays.map((date) => {
      const days = data.filter((a) => a.date === date);

      if (days.length === 1) {
        return days[0];
      }

      return days.reduce((result, current) => {
        for (const key in result) {
          if (isPossibleStatisticCategory(key)) {
            result[key] = CorrectMath.addition(result[key], current[key]);
          }

          if (key === 'members') {
            result.members = [...result.members, ...current.members];
          }
        }

        return result;
      });
    });
  }, []);

  const getPreparedStatistics = useCallback(
    (data?: ApiDashboardStatistic) => {
      if (!data || !data.statistics.length) return [];

      const { user, command } = data;

      return data.statistics.filter(isDisplayStatistics).map((a) => ({ ...a, user, command }));
    },
    [isDisplayStatistics],
  );

  const getColumnsByParameters = useCallback(
    (data: Partial<PossibleStatisticCategoriesDto>) => {
      return displayedColumns.map((column) => ({
        minWidth: '160px',
        label: localizeStatisticCategory(column),
        value: getFormattedStatisticValue(column, data[column] || 0),
      }));
    },
    [displayedColumns, getFormattedStatisticValue, localizeStatisticCategory],
  );

  const getTableRow = useCallback(
    (data: ConfigDataValues) => {
      const result = [
        {
          label: t('tableLabels.date'),
          value: data.date,
        },
        isShowCommandColumn && {
          label: t('roles.team'),
          value: data.command,
        },
        isShowMasterColumn && {
          label: t('roles.user'),
          minWidth: '160px',
          value: data.master,
        },
      ].filter(filterBoolean);

      if (data.statistic) {
        const columns = getColumnsByParameters(data.statistic);
        result.push(...columns);
      }

      return result;
    },
    [getColumnsByParameters, isShowCommandColumn, isShowMasterColumn, t],
  );

  const getGlobalConfig = useCallback(
    (statistic: typeof data1): TableRowConfig | undefined => {
      if (!statistic.data) return;

      const { globalStatistics, user, command } = statistic.data;

      const id = generateRowId('total', 'all', command, user);
      const data = getTableRow({
        date: t('tableLabels.total'),
        command: command.name,
        statistic: globalStatistics,
        master: user && getUserNickname(user),
      });

      return { data, id };
    },
    [generateRowId, getTableRow, t],
  );

  const getCommandStatistic = useCallback(
    (statistic: ApiDashboardCommonStatistics, members: ApiDashboardStatisticMember[]) => {
      const result: Partial<PossibleStatisticCategoriesDto> = {};

      for (let i = 0; i < displayedColumns.length; i++) {
        const column = displayedColumns[i];

        const membersValue = members.reduce(
          (result, current) => result + (current[column] || 0),
          0,
        );

        result[column] = CorrectMath.subtraction(statistic[column], membersValue);
      }

      return result;
    },
    [displayedColumns],
  );

  const getChildrenConfig = useCallback(
    (statistic: ApiDashboardCommonStatistics, command: ApiCommand): TableConfig => {
      const filteredMembers = statistic.members.filter(isDisplayStatistics);
      const commandStatistic = getCommandStatistic(statistic, filteredMembers);

      const result = filteredMembers.map((userStatistic) => {
        const id = generateRowId('expanded-user', statistic.date, command, userStatistic.user);

        const data = getTableRow({
          master: getUserNickname(userStatistic.user),
          statistic: userStatistic,
        });

        return { data, id };
      });

      if (isDisplayStatistics(commandStatistic)) {
        const id = generateRowId('expanded-command', statistic.date, command);

        const data = getTableRow({
          master: t('tableLabels.onCommand'),
          statistic: commandStatistic,
        });

        result.push({ data, id });
      }

      return result;
    },
    [isDisplayStatistics, getCommandStatistic, generateRowId, getTableRow, t],
  );

  const globalRows = useMemo((): TableConfig => {
    const result = [];

    if (data1.data?.globalStatistics) {
      result.push(getGlobalConfig(data1));
    }

    if (!isMaster && data2.data?.globalStatistics) {
      result.push(getGlobalConfig(data2));
    }

    return result.filter(filterBoolean);
  }, [data1, data2, getGlobalConfig, isMaster]);

  const preparedData = useMemo(() => {
    if (!data1.data?.statistics.length && !data2.data?.statistics.length) return [];

    const result: ApiDashboardData[] = [];
    const preparedData1 = getPreparedStatistics(data1.data);
    const preparedData2 = getPreparedStatistics(data2.data);

    const hasPreparedData1 = Boolean(preparedData1.length);
    const hasPreparedData2 = Boolean(preparedData2.length);

    if (hasPreparedData1) result.push(...preparedData1);
    if (hasPreparedData2) result.push(...preparedData2);

    result.sort(sortByDate('DESC'));

    if (data1.isEqualGraphicCommands) {
      if (hasPreparedData1 && hasPreparedData2) {
        return getPreparedGroupedData(result);
      }
    }

    return result;
  }, [
    data1.data,
    data1.isEqualGraphicCommands,
    data2.data,
    getPreparedStatistics,
    getPreparedGroupedData,
  ]);

  const config: TableConfig = useMemo(() => {
    if (!preparedData.length) return [];

    const result: TableConfig = preparedData.map((statistic) => {
      const { date, command, members, user } = statistic;

      // TODO: Логически не совсем верно, вероятно стоит завязаться на data1 и data2
      const isStatisticByMaster = Boolean(members.length === 1 && user?.id);

      const canExpandTable = !isStatisticByMaster && !isMaster;
      const childrenConfig = canExpandTable ? getChildrenConfig(statistic, command) : undefined;

      const id = generateRowId('row', date, command, user);
      const data = getTableRow({
        statistic,
        command: command.name,
        date: Formatter.formatDate(date),
        master: isStatisticByMaster && getUserNickname(user),
      });

      return {
        id,
        data,
        childrenConfig,
      };
    });

    if (globalRows.length) {
      result.push(...globalRows);
    }

    return result;
  }, [generateRowId, getChildrenConfig, getTableRow, globalRows, isMaster, preparedData]);

  return { config, toggleExpandedColumnsState };
};

export default useDashboardConfig;
