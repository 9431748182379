import { PropsWithChildren } from 'react';
import { Portal, Stack } from '@mui/material';

import Typography from '@/components/Typography';

import IconArrow from '@/icons/IconArrow';

import { HEADER_TITLE_ID } from '@/resources/constants';

type PageTitleProps = PropsWithChildren<{
  onGoBack?: (() => void) | false;
}>;

const PageTitle = ({ children, onGoBack }: PageTitleProps) => {
  const selector = document.getElementById(HEADER_TITLE_ID);

  if (!selector) return null;

  return (
    <Portal container={selector}>
      <Stack direction='row' spacing={6} alignItems='center'>
        {onGoBack && <IconArrow onClick={onGoBack} />}

        <Typography variant='h6' color='text.primary'>
          {children}
        </Typography>
      </Stack>
    </Portal>
  );
};

export default PageTitle;
